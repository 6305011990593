import { useQuery, useMutation } from "react-query";
import http from "services/httpService";

// -------------------------------------- Query Block
// -------------------------------------- Query Block

async function getDashboardData({ queryKey }) {
  const { fromDate, toDate } = queryKey[1];
  const { data } = await http.get(`/dashboard-data/${fromDate}/${toDate}`);
  return data;
}

function useGetDashboardData(filters) {
  return useQuery(["dashboard-data", filters], getDashboardData);
}

// async function updateInventoryAll({ item_id, data }) {
//   const { data: updateInventoryAllData } = await http.put(
//     `/prep-area/inventory-update-all/${item_id}`,
//     data
//   );
//   return updateInventoryAllData;
// }

// function useMutationUpdateInventoryAll() {
//   return useMutation(updateInventoryAll);
// }

// -------------------------------------- Mutation Block
// -------------------------------------- Mutation Block
export { useGetDashboardData };
