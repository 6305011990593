import React, { useState, useEffect } from "react";

import { Typography, Space, Drawer, Table, Input, message, Spin } from "antd";

import { SearchOutlined } from "@ant-design/icons";

import { useMutationStoreBranchInventory } from "services/hooks/api/branchInventoryAPI";
import customStyles from "helpers/customStyles";

const { Text } = Typography;
const ItemSelection = ({
  items,
  setItems,
  visibility,
  setVisibility,
  branchID,
  setInventoryIDs,
  setInventory,
  setInventoryTemp,
  selectedBranch,
}) => {
  const mutationStoreBranchInventory = useMutationStoreBranchInventory();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  // const [item, setItem] = useState(null);

  useEffect(() => {
    // console.log(items);
    setDataSource(items);
  }, [items]);

  const handleClose = () => {
    setVisibility(false);
    setDataSource(items);
  };

  const handleSearchOnChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    setDataSource(items.filter((obj) => obj.item_name.toLowerCase().includes(value.toLowerCase())));
  };

  const handleOnSelect = (e, r) => {
    // console.log(r);
    const storeData = {
      item_id: r.item_id,
      qty: 0,
      min_stock_qty: r.min_stock_qty,
      branch_id: branchID,
      status: "active",
    };
    // console.log(storeData);
    // console.log(selectedBranch);
    setIsSubmitting(true);
    mutationStoreBranchInventory.mutate(
      { data: { item: storeData, branch: selectedBranch } },
      {
        onError: (error) => {
          message.error("Something went wrong :/");
          // An error happened!
          setIsSubmitting(false);
          console.log(`Error:  ${error.message}`);
        },
        onSuccess: (data) => {
          // Boom baby!
          // console.log(data);
          message.success(data.message);
          setIsSubmitting(false);

          setItems((prevData) => prevData.filter((a) => a.item_id != r.item_id));
          setInventoryIDs((prevData) => [...prevData, r.item_id]);
          const randomId = Math.floor(Math.random() * 9999999);
          setInventory((prevData) => [
            ...prevData,
            { ...r, rec: randomId, ...storeData, stock_level: "depleted" },
          ]);
          setInventoryTemp((prevData) => [
            ...prevData,
            { ...r, rec: randomId, ...storeData, stock_level: "depleted" },
          ]);
        },
      }
    );
  };

  const columns = [
    {
      title: "Item",
      dataIndex: "item_name",
      render: (_, record) => <Text style={{ color: "#002766" }}>{record.item_name}</Text>,
    },
    {
      title: "Group",
      dataIndex: "group_name",
      width: 120,
      render: (_, record) => <Text italic>{record.group_name}</Text>,
    },
  ];

  return (
    <Drawer
      title={<div style={customStyles.spaceBetween}>Item Search</div>}
      width={320}
      placement="right"
      closable={true}
      visible={visibility}
      bodyStyle={{ padding: "4px 12px" }}
      onClose={handleClose}
    >
      <Spin spinning={isSubmitting} tip="Storing inventory, pls wait....">
        <Input
          placeholder="Type item name..."
          size="large"
          allowClear
          style={{ width: "100%" }}
          // onPressEnter={handlesOnPressEnter}
          onChange={handleSearchOnChange}
          value={searchValue}
          prefix={
            <Space>
              <SearchOutlined style={{ marginRight: 20 }} />
            </Space>
          }
        />

        <Table
          rowKey="item_id"
          dataSource={dataSource}
          columns={columns}
          size="small"
          pagination={false}
          onRow={(record) => {
            return {
              onClick: (event) => {
                handleOnSelect(event, record);
              },
            };
          }}
        />
      </Spin>
    </Drawer>
  );
};

export default ItemSelection;
