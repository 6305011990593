import { useQuery, useMutation } from "react-query";
import http from "services/httpService";

async function getUsers({ queryKey }) {
  const { searchValue } = queryKey[1];
  const { data: usersData } = await http.get(`/user/all/${searchValue}`);
  return usersData;
}
function useGetUsers(searchValue) {
  return useQuery(["users", searchValue], getUsers);
}

async function getUser({ queryKey }) {
  const { user_id } = queryKey[1];
  const { data: userData } = await http.get(`/user/${user_id}`);
  return userData;
}
function useGetUser(data) {
  return useQuery(["user", data], getUser);
}

// -------------------------------------- Mutation

async function storeUser({ data }) {
  const { data: storedUserData } = await http.post(`/user/store`, data);
  return storedUserData;
}
function useMutationStoreUser() {
  return useMutation(storeUser);
}

async function updateUser(data) {
  const { data: updateuserData } = await http.put(`/user/update/${data.user_id}`, data.data);
  return updateuserData;
}

function useMutationUpdateUser() {
  return useMutation(updateUser);
}

async function updateUserPassword(data) {
  console.log(data);
  const { data: updateUserPassword } = await http.put(`/user/update-password/${data.user_id}`, {
    password: data.password,
  });
  return updateUserPassword;
}

function useMutationUpdateUserPassword() {
  return useMutation(updateUserPassword);
}

export {
  useGetUsers,
  useGetUser,
  useMutationStoreUser,
  useMutationUpdateUser,
  useMutationUpdateUserPassword,
};
