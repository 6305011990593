import React, { useState, useEffect } from "react";

import {
  Drawer,
  Space,
  Typography,
  Button,
  Row,
  Col,
  Select,
  Menu,
  message,
  Spin,
  Modal,
  Divider,
} from "antd";
import {
  PlusCircleOutlined,
  StarOutlined,
  MinusCircleOutlined,
  SnippetsOutlined,
  CarryOutOutlined,
  StarFilled,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { titleCase, upperCase } from "helpers/textTransformer";
import customStyles from "helpers/customStyles";
import { useMutationUpdateInventoryAll } from "services/hooks/api/prepAreaAPI";

import OSKInline from "common/OSKInline";

import InventoryItem from "../components/InventoryItem";
import InventoryLedger from "../components/InventoryLedger";
import { formatNumber } from "helpers/numberFormatter";

const { Title, Text } = Typography;
const { Option } = Select;

const qtyLimit = { out: 25, add: 25, spoil: 5, return: 5 };

const InventoryEntry = ({
  params,
  setParams,
  onFav,
  refetchTables,
  branches,
  branchType,
  isBalance,
}) => {
  const [itemQty, setItemQty] = useState(0);
  const [tableTransactionNumber, setTableTransactionNumber] = useState(null);
  const [branchID, setBranchID] = useState(null);

  // {
  //   label: <Text style={{ fontSize: 20, paddingLeft: 15 }}> ADD </Text>,
  //   key: "0",
  //   icon: <PlusCircleOutlined />,
  //   symbol: <span style={{ color: "#1d39c4", marginLeft: 6 }}>(+)</span>,
  //   process_type_id: 1,
  //   process_mode: "increase",
  //   process_type: "add",
  // },
  const items = [
    {
      label: <Text style={{ fontSize: 20, paddingLeft: 15 }}> OUT </Text>,
      key: "0",
      icon: <MinusCircleOutlined style={{ color: "red" }} />,
      symbol: <span style={{ color: "#f5222d", marginLeft: 6 }}>(-)</span>,
      process_type_id: 2,
      process_mode: "decrease",
      process_type: "out",
    },
    {
      label: <Text style={{ fontSize: 20, paddingLeft: 15 }}> RETURN </Text>,
      key: "1",
      icon: <PlusCircleOutlined />,
      symbol: <span style={{ color: "#1d39c4", marginLeft: 6 }}>(+)</span>,
      process_type_id: 3,
      process_mode: "increase",
      process_type: "return",
    },
    {
      label: <Text style={{ fontSize: 20, paddingLeft: 15 }}> SPOIL </Text>,
      key: "2",
      icon: <MinusCircleOutlined style={{ color: isBalance ? "" : "red" }} />,
      symbol: <span style={{ color: "#f5222d", marginLeft: 6 }}>(-)</span>,
      process_type_id: 4,
      process_mode: "decrease",
      process_type: "spoil",
    },
  ];

  const itemsBalance = [
    // {

    {
      label: <Text style={{ fontSize: 20, paddingLeft: 15 }}> OUT </Text>,
      key: "0",
      icon: <MinusCircleOutlined style={{ color: "red" }} />,
      symbol: <span style={{ color: "#f5222d", marginLeft: 6 }}>(-)</span>,
      process_type_id: 2,
      process_mode: "decrease",
      process_type: "out",
    },
  ];

  // const [items, setItems] = useState([]);

  const [selectedMenu, setSelectedMenu] = useState(isBalance ? itemsBalance[0] : items[0]);
  const mutationUpdateInventoryAll = useMutationUpdateInventoryAll();
  const [isBusy, setIsbusy] = useState(false);

  const [inventoryItemParams, setInventoryItemParams] = useState({
    visible: false,
    item_id: null,
    branch_id: params.branch_id,
    branch_name: params.branch_name,
    username: params.username,
    print_server_id: params.print_server_id,
    inventory_cutoff: params.inventory_cutoff,
  });

  const [inventoryLedgerParams, setInventoryLedgerParams] = useState({
    visible: false,
    selectedItem: null,
    branch_id: params.branch_id,
    branch_name: params.branch_name,
    username: params.username,
    print_server_id: params.print_server_id,
    inventory_cutoff: params.inventory_cutoff,
  });

  // useEffect(() => {
  //   if (params.visible) {
  //     setItems(isBalance ? [{ ...itemsTemp[0] }] : itemsTemp);
  //     console.log(selectedMenu);
  //   }
  // }, [params]);

  const handleOnKeyBoardPress = (v) => {
    setItemQty(v);
  };

  const OnClose = () => {
    setParams((prevState) => ({ ...prevState, visible: false, selectedItem: null }));
    setTableTransactionNumber(null);
    setBranchID(null);
    setItemQty(0);
    setSelectedMenu(isBalance ? itemsBalance[0] : items[0]);
    refetchTables();
  };

  const onSelect = ({ key }) => {
    console.log(items[key]);
    setSelectedMenu(isBalance ? itemsBalance[key] : items[key]);
  };

  const onTableSelect = (value) => {
    setTableTransactionNumber(value);
  };

  const onBranchSelect = (value) => {
    setBranchID(value);
  };

  const preSave = () => {
    const getLimit = qtyLimit[selectedMenu.process_type];
    const qty = Number(itemQty) || 0;
    const { selectedItem: item } = params;
    const { process_type_id, process_type } = selectedMenu;
    if (!qty) {
      message.error("Invalid quantity!");
      return false;
    }

    if (qty > getLimit) {
      message.error(
        <>
          Maximum <b style={{ color: "red", fontSize: 20 }}>{titleCase(process_type)}</b> must be
          less than or equal to{" "}
          <b style={{ color: "red", fontSize: 20 }}>
            {getLimit}
            {item.unit}
          </b>
          .
        </>
      );
      return false;
    }

    if (item.is_salable && process_type_id === 2 && !tableTransactionNumber) {
      message.error("Must select table!");
      return false;
    }

    if (branchType === "kitchen" && process_type_id === 2 && !branchID) {
      message.error("Must select branch!");
      return false;
    }
    Modal.confirm({
      title: "Please check quantity. ",
      icon: <QuestionCircleOutlined style={{ color: "#2f54eb" }} />,
      content: (
        <>
          <p style={{ textAlign: "left", fontSize: 18, paddingBttom: 0, marginBottom: 0 }}>
            Process : <b style={{ color: "green" }}>{upperCase(process_type)}</b> <br></br>Item :{" "}
            <b style={{ color: "green" }}>
              {" "}
              {item.item_name} ({item.unit})
            </b>
          </p>

          <p style={{ textAlign: "left", fontSize: 40, padding: 0, margin: 0 }}>
            Qty: <b style={{ color: "red" }}>{formatNumber(qty, true, 4)} </b>
          </p>
          <span>Are you sure?</span>
        </>
      ),
      okText: "Yes, I'm sure!",
      onOk: OnSave,
    });
  };

  const OnSave = () => {
    const qty = Number(itemQty) || 0;
    const { selectedItem: item } = params;
    const { process_type_id } = selectedMenu;
    setIsbusy(true);
    console.log(process_type_id);
    // if (!qty) {
    //   message.error("Invalid quantity!");
    //   setIsbusy(false);
    //   return false;
    // }

    // if (item.is_salable && process_type_id === 2 && !tableTransactionNumber) {
    //   setIsbusy(false);
    //   message.error("Must select table!");
    //   return false;
    // }

    // if (branchType === "kitchen" && process_type_id === 2 && !branchID) {
    //   setIsbusy(false);
    //   message.error("Must select branch!");
    //   return false;
    // }
    const finalData = {
      item_id: item.item_id,
      data: {
        qty,
        process_type_id,
        transaction_id: tableTransactionNumber,
        branch_id: params.branch_id,
        process_branch_id: branchID,
        user_id: params.user_id,
        inventory_cutoff: params.inventory_cutoff,
      },
    };

    mutationUpdateInventoryAll.mutate(finalData, {
      onError: (error) => {
        message.error("Something went wrong :/");
        // An error happened!
        console.log(`Error:  ${error.message}`);
      },
      onSuccess: (data) => {
        if (data.error) {
          setIsbusy(false);
          if (data.inventoryDailyDetails) {
            const errorType = data.errorType;
            const itemName = params.selectedItem.item_name;
            Modal.error({
              width: 500,
              title: data.message,
              content:
                errorType === "return" ? (
                  <p>
                    <b>Reason:</b> Return Total cannot be greater than Out quanty. <br></br>
                    <b>Item:</b> {itemName} <Divider style={{ margin: 8 }} />
                    <span style={{ color: "red" }}>
                      <b>Out Quantity:</b> {data.inventoryDailyDetails.inventory_out}
                    </span>
                    <Divider style={{ margin: 8 }} />
                    <b>Previous Return Qty:</b> {data.inventoryDailyDetails.inventory_return}
                    <Divider style={{ margin: 10 }} />
                    <b>New Return Qty:</b> {data.inventoryDailyDetails.qty}
                    <Divider style={{ margin: 10 }} />
                    <span style={{ color: "red" }}>
                      <b>Total Return Qty:</b> {data.inventoryDailyDetails.total}
                    </span>
                  </p>
                ) : errorType === "out" ? (
                  <p>
                    <b>Reason:</b> Out Total cannot be greater than Balance quanty. <br></br>
                    <b>Item:</b> {itemName} <Divider style={{ margin: 8 }} />
                    <span style={{ color: "red" }}>
                      <b>Balance Quantity:</b> {data.inventoryDailyDetails.inventory_balance}
                    </span>
                    <Divider style={{ margin: 8 }} />
                    <b>Previous Out Qty:</b> {data.inventoryDailyDetails.inventory_out}
                    <Divider style={{ margin: 8 }} />
                    <b>New Out Qty:</b> {data.inventoryDailyDetails.qty}
                    <Divider style={{ margin: 8 }} />
                    <span style={{ color: "red" }}>
                      <b>Total Out Qty:</b> {data.inventoryDailyDetails.total}
                    </span>
                  </p>
                ) : (
                  <p>
                    <b>Reason:</b> Spoil Total cannot be greater than Balance Quanty. <br></br>
                    <b>Item:</b> {itemName} <Divider style={{ margin: 8 }} />
                    <span style={{ color: "red" }}>
                      <b>Balance Quantity:</b> {data.inventoryDailyDetails.inventory_balance}
                    </span>
                    <Divider style={{ margin: 8 }} />
                    <b>Previous Spoil Qty:</b> {data.inventoryDailyDetails.inventory_spoil}
                    <Divider style={{ margin: 8 }} />
                    <b>New Spoil Qty:</b> {data.inventoryDailyDetails.qty}
                    <Divider style={{ margin: 8 }} />
                    <span style={{ color: "red" }}>
                      <b>Total Spoil Qty:</b> {data.inventoryDailyDetails.total}
                    </span>
                  </p>
                ),
            });
          } else {
            message.error(data.message);
          }
        } else {
          setIsbusy(false);
          message.success(data.message);
          OnClose();
        }
      },
    });
  };

  const handleFav = (value, item, branch_id) => {
    if (onFav) {
      onFav(value, item, branch_id);
    }
  };

  const handlesShowInventoryItem = () => {
    setInventoryItemParams({
      ...inventoryItemParams,
      visible: true,
      item_id: params.selectedItem.item_id,
    });
  };
  const handlesShowInventoryLedger = () => {
    setInventoryLedgerParams({
      ...inventoryLedgerParams,
      visible: true,
      selectedItem: params.selectedItem,
    });
  };

  if (!params.selectedItem) {
    return null;
  } else {
    const { selectedItem: item, dineInTables } = params;
    return (
      <>
        <Drawer
          title={
            <div
              style={{
                ...customStyles.spaceBetween,
                marginTop: 10,
              }}
            >
              <Title level={3}>
                {item.item_name} ({item.unit}){" "}
                {item.is_fav ? <StarFilled style={{ color: "#ffc53d" }} /> : null}
              </Title>

              {!isBalance ? (
                item.is_fav ? (
                  <Button
                    type="secondary"
                    icon={<StarOutlined />}
                    onClick={() => handleFav(0, params.selectedItem, params.branch_id)}
                  >
                    Remove from favorites
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    icon={<StarOutlined />}
                    onClick={() => handleFav(1, params.selectedItem, params.branch_id)}
                  >
                    Add to favorites
                  </Button>
                )
              ) : null}
            </div>
          }
          headerStyle={{
            padding: 0,
            paddingLeft: 20,
            paddingRight: 20,
            margin: 0,
          }}
          closable={true}
          visible={params.visible}
          width={700}
          onClose={OnClose}
          destroyOnClose={true}
        >
          <Spin spinning={isBusy}>
            <Row gutter={15}>
              <Col span={10}>
                <Text>Select Process</Text>
                <Menu
                  items={isBalance ? itemsBalance : items}
                  mode="inline"
                  inlineIndent={10}
                  defaultSelectedKeys={["0"]}
                  onSelect={onSelect}
                  size="large"
                />

                {selectedMenu &&
                item.is_salable &&
                selectedMenu.process_type === "out" &&
                branchType !== "kitchen" ? (
                  <div
                    style={{ ...customStyles.quantityContainer, marginTop: 6, minHeight: "auto" }}
                  >
                    <Space direction="vertical">
                      <div
                        style={{
                          border: "1px solid #d9d9d9",
                          padding: 12,
                        }}
                      >
                        <Text type="secondary">Select table/s</Text>
                        <Select
                          style={{
                            minWidth: 190,
                            fontSize: 18,
                          }}
                          bordered={false}
                          optionLabelProp="label"
                          placeholder="none"
                          onSelect={onTableSelect}
                        >
                          {dineInTables.map((item) => {
                            const tableString = item.tables.replace("[", "").replace("]", "");
                            return (
                              <Option
                                value={item.transaction_id}
                                key={item.transaction_id}
                                label={<span>Table {tableString}</span>}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    fontSize: 20,
                                    height: 35,
                                    alignItems: "center",
                                  }}
                                >
                                  Table {tableString}
                                </div>
                              </Option>
                            );
                          })}
                        </Select>
                      </div>
                    </Space>
                  </div>
                ) : null}

                {selectedMenu && selectedMenu.process_type === "out" && branchType === "kitchen" ? (
                  <div
                    style={{ ...customStyles.quantityContainer, marginTop: 6, minHeight: "auto" }}
                  >
                    <Space direction="vertical">
                      <div
                        style={{
                          border: "1px solid #d9d9d9",
                          padding: 12,
                        }}
                      >
                        <Text type="secondary">Select branch</Text>
                        <Select
                          style={{
                            minWidth: 190,
                            fontSize: 18,
                          }}
                          bordered={false}
                          optionLabelProp="label"
                          placeholder="none"
                          onSelect={onBranchSelect}
                        >
                          {branches.map((branch) => {
                            // if (branch.branch_id !== params.branch_id) {
                            return (
                              <Option
                                value={branch.branch_id}
                                key={branch.branch_id}
                                label={<span>{branch.branch_name}</span>}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    fontSize: 20,
                                    height: 35,
                                    alignItems: "center",
                                  }}
                                >
                                  {branch.branch_name}
                                </div>
                              </Option>
                            );
                            // }
                          })}
                        </Select>
                      </div>
                    </Space>
                  </div>
                ) : null}

                <Space direction="vertical" style={{ width: "100%", marginTop: 12 }} size={12}>
                  <Button
                    block
                    icon={<SnippetsOutlined />}
                    onClick={handlesShowInventoryItem}
                    style={{ height: 50, fontSize: 20 }}
                  >
                    Show Inventory
                  </Button>
                  <Button
                    block
                    icon={<CarryOutOutlined />}
                    onClick={handlesShowInventoryLedger}
                    style={{ height: 50, fontSize: 20 }}
                  >
                    Show Ledger
                  </Button>
                </Space>
              </Col>
              <Col span={14}>
                <Title level={3}>
                  {titleCase(selectedMenu.process_type)}
                  {selectedMenu.symbol}
                </Title>

                <div style={textContainer}>
                  <div
                    style={{
                      position: "absolute",
                      top: 10,
                      left: 20,
                    }}
                  >
                    <Text type="secondary">Input Qty</Text>
                  </div>
                  <Title level={1} style={{ fontSize: 60 }}>
                    {itemQty}
                  </Title>
                </div>
                <OSKInline
                  oskName="oskInventoryEntry"
                  onKeyBoardPress={handleOnKeyBoardPress}
                  maxLength={7}
                  isNumeric={true}
                  noDecimal={false}
                />

                <Row gutter={4} style={{ marginTop: 10 }}>
                  <Col span={12}>
                    <Button type="primary" block size="large" onClick={preSave}>
                      Save
                    </Button>
                  </Col>
                  <Col span={12}>
                    <Button danger ghost block size="large" onClick={OnClose}>
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Spin>
        </Drawer>
        <InventoryItem params={inventoryItemParams} setParams={setInventoryItemParams} />
        <InventoryLedger params={inventoryLedgerParams} setParams={setInventoryLedgerParams} />
      </>
    );
  }
};

export default InventoryEntry;
const textContainer = {
  width: "100%",
  height: 110,
  padding: "20px",
  border: "2px solid #85a5ff",
  // marginTop: 10,
  marginBottom: 10,
  textAlign: "center",
  borderRadius: 10,
  position: "relative",
};
