import React from "react";
import { Empty, Table, Drawer } from "antd";
import { formatNumber } from "helpers/numberFormatter";

const UnliList = ({ visibility, setVisibility, data }) => {
  const itemColumn = [
    {
      title: "Group",
      dataIndex: "group_name",
      ellipsis: true,
      width: 65,
      fixed: "left",
    },
    {
      title: "Item",
      dataIndex: "item_name",
      ellipsis: true,
      width: 110,
      fixed: "left",
    },
    {
      title: "Unit",
      dataIndex: "unit",
      ellipsis: true,
      width: 55,
    },
    {
      title: "Cons",
      dataIndex: "qty_consumed",
      align: "right",
      width: 60,
      render: (text, record) => <span>{formatNumber(record.qty_consumed, true, 3)} </span>,
    },
    {
      title: "UPrice",
      dataIndex: "total",
      align: "right",
      width: 80,
      render: (text, record) => <span>&#8369;{formatNumber(record.unit_price, true, 3)} </span>,
    },

    {
      title: "Total",
      dataIndex: "average_consumed",
      align: "right",
      width: 100,
      render: (text, record) => <span>&#8369;{formatNumber(record.total, true, 3)} </span>,
    },
  ];

  const handleClose = () => {
    setVisibility(false);
  };

  return (
    <Drawer
      title="Unli Items Recap"
      width={350}
      placement="right"
      closable={true}
      visible={visibility}
      bodyStyle={{ padding: "4px 18px" }}
      onClose={handleClose}
    >
      {data ? (
        <Table
          dataSource={data}
          columns={itemColumn}
          rowKey="item_name"
          size="small"
          pagination={false}
          scroll={{ x: 550 }}
        />
      ) : (
        <Empty />
      )}
    </Drawer>
  );
};

export default UnliList;

const style = {
  descriptionText: {
    // fontSize: 13,
    padding: 4,
    paddingLeft: 10,
    paddingRight: 10,

    // textAlign: "center"
  },
};
