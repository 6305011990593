import { useQuery, useMutation } from "react-query";
import http from "services/httpService";

// -------------------------------------- Query Block
// -------------------------------------- Query Block

async function getSalesSummary({ queryKey }) {
  const { branch_id, cutoff } = queryKey[1];
  const { data } = await http.get(`/sales-summary/${cutoff}/${branch_id}`);
  return data;
}

function useGetSalesSummary(filters) {
  return useQuery(["sales-summary", filters], getSalesSummary);
}

async function lockSummary({ summary }) {
  const { data: summaryData } = await http.post(`/sales-summary`, summary);
  return summaryData;
}

function useMutationLockSummary() {
  return useMutation(lockSummary);
}

// -------------------------------------- Mutation Block
// -------------------------------------- Mutation Block
export { useGetSalesSummary, useMutationLockSummary };
