import { useQuery, useMutation } from "react-query";
import http from "services/httpService";

// -------------------------------------- Query Block
// -------------------------------------- Query Block

async function getDailyInventory({ queryKey }) {
  const { branch_id, inventory_cutoff } = queryKey[1];
  const { data } = await http.get(`/daily-inventory/${branch_id}/${inventory_cutoff}`);
  return data;
}

function useGetDailyInventory(filters) {
  return useQuery(["daily-inventory", filters], getDailyInventory);
}

async function updateInventoryCutOff({ branch_id, inventory_cutoff }) {
  const { data: cutOffDatails } = await http.put(
    `/daily-inventory/cutoff/${branch_id}/${inventory_cutoff}`
  );
  return cutOffDatails;
}

function useMutationUpdateInventoryCutOff() {
  return useMutation(updateInventoryCutOff);
}
// async function updateInventoryAll({ item_id, data }) {
//   const { data: updateInventoryAllData } = await http.put(
//     `/prep-area/inventory-update-all/${item_id}`,
//     data
//   );
//   return updateInventoryAllData;
// }

// function useMutationUpdateInventoryAll() {
//   return useMutation(updateInventoryAll);
// }

// -------------------------------------- Mutation Block
// -------------------------------------- Mutation Block
export { useGetDailyInventory, useMutationUpdateInventoryCutOff };
