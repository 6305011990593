import React, { useState, useEffect, useContext } from "react";
import { SocketContext } from "services/contexts/socket";
import { useSelector } from "react-redux";
import {
  Table,
  Card,
  Space,
  Input,
  Tooltip,
  Button,
  Row,
  Col,
  Typography,
  Tag,
  Spin,
  message,
  Drawer,
  Popconfirm,
} from "antd";
import {
  LoadingOutlined,
  SearchOutlined,
  RedoOutlined,
  EditOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { formatNumber } from "helpers/numberFormatter";

import {
  useGetPrepInventoryTransfer,
  useMutationPrepUpdateInventoryTranferItem,
} from "services/hooks/api/prepAreaAPI";

import RetryMessage from "common/RetryMessage";
import OSK from "common/OSK";

// must include in settings database
const lowWeightTolerance = 0.2;
const hightWeightTolerance = 1;

const { Text } = Typography;

const InventoryTransferPrepArea = ({
  inventoryTransferVisibiliy,
  setInventoryTransferVisibiliy,
  refetchPending,
  refetchMasterData,
}) => {
  const socket = useContext(SocketContext);
  const { user_id, branch_name, branch_id, inventory_cutoff } = useSelector((state) => state.User);
  const [searchValue, setSearchValue] = useState("");
  const [transferListBase, setTransferListBase] = useState([]);
  const [transferListTemp, setTransferListTemp] = useState([]);
  const [selectedList, setSelectedList] = useState(null);
  const [valueFromOSK, setValueFromOSK] = useState(0);
  const [isBusy, setIsBusy] = useState(false);
  const { isLoading, data, refetch, error, isError } = useGetPrepInventoryTransfer({
    branch_id,
    inventory_cutoff,
  });
  const mutationPrepUpdateInventoryTranferItem = useMutationPrepUpdateInventoryTranferItem();
  const [oskParams, setOSKParams] = useState({});
  const [oskVisibility, setOSKVisibility] = useState(false);

  useEffect(() => {
    socket.on("updateTransfer", () => {
      refetch();
    });
  }, []);

  useEffect(() => {
    if (!isLoading && data && inventoryTransferVisibiliy) {
      setTransferListBase(data);
      setTransferListTemp(data);
    }
  }, [isLoading, data, inventoryTransferVisibiliy]);

  useEffect(() => {
    if (!oskVisibility && valueFromOSK) {
      updateTranferItemList();
    }
  }, [oskVisibility, valueFromOSK]);

  function updateTranferItemList() {
    if (selectedList) {
      if (selectedList.received_quantity !== valueFromOSK) {
        const transferValue = Number(selectedList.transfer_quantity) || 0;
        const receivedValue = Number(valueFromOSK) || 0;

        //  ------ special note javascript multiple decimal error output
        if (
          receivedValue < transferValue &&
          Number((transferValue - receivedValue).toFixed(3)) > lowWeightTolerance
        ) {
          message.error(
            `Quantity error! allowable short quantity must not be greater than (${lowWeightTolerance}${selectedList.unit})`
          );
        } else if (
          receivedValue > transferValue &&
          Number((receivedValue - transferValue).toFixed(3)) > hightWeightTolerance
        ) {
          message.error(
            `Quantity error! allowable over quantity must not be greater than (${hightWeightTolerance}${selectedList.unit})`
          );
        } else {
          message.success("Received quantity changed!");
          const diff = receivedValue - transferValue;
          setTransferListBase((prevData) =>
            prevData.map((prev) =>
              prev.time_stamp === selectedList.time_stamp
                ? { ...selectedList, received_quantity: receivedValue, descrepancy: diff }
                : prev
            )
          );
          setTransferListTemp((prevData) =>
            prevData.map((prev) =>
              prev.time_stamp === selectedList.time_stamp
                ? { ...selectedList, received_quantity: receivedValue, descrepancy: diff }
                : prev
            )
          );
        }
      } else {
        message.info("No changes!");
      }
      setSelectedList(null);
      setValueFromOSK(0);
    }
  }
  const updateTransferItem = (record) => {
    setIsBusy(true);
    mutationPrepUpdateInventoryTranferItem.mutate(
      {
        ...record,
        status: "accepted",
        received_by: user_id,
      },
      {
        onError: (error) => {
          message.error("Something went wrong :/");
          // An error happened!
          console.log(`Error:  ${error.message}`);
          setIsBusy(false);
        },
        onSuccess: (_) => {
          message.success("Invetory item accepted");
          setTransferListBase((prevData) =>
            prevData.map((prev) =>
              prev.time_stamp === record.time_stamp ? { ...record, status: "accepted" } : prev
            )
          );
          setTransferListTemp((prevData) =>
            prevData.map((prev) =>
              prev.time_stamp === record.time_stamp ? { ...record, status: "accepted" } : prev
            )
          );
          socket.emit("updateTransferAdmin", { room: "gogi" });
          setIsBusy(false);
        },
      }
    );
  };

  const columns = [
    {
      title: "#",
      key: "row_count",
      width: 30,
      fixed: "left",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Item",
      key: "item_name",
      width: 100,
      ellipsis: {
        showTitle: false,
      },
      fixed: "left",
      render: (text, record, index) => record.item_name,
    },
    {
      title: "From",
      key: "branch_name",
      width: 100,
      ellipsis: {
        showTitle: false,
      },
      render: (text, record, index) => record.branch_name,
    },
    {
      title: "Status",
      key: "status",
      width: 120,
      align: "center",
      render: (text, record, index) =>
        record.status === "pending" ? (
          <>
            <Tag color="blue">{record.status}</Tag>{" "}
            <Popconfirm
              title="Accept this Item?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => {
                updateTransferItem(record);
              }}
              // onOpenChange={() => console.log("open change")}
            >
              <Button type="link" icon={<CheckOutlined />} />
            </Popconfirm>
          </>
        ) : (
          <Tag color="green">{record.status}</Tag>
        ),
    },
    {
      title: "Unit",
      key: "unit",
      width: 45,
      render: (text, record, index) => record.unit,
    },
    {
      title: "Trans.",
      key: "transfer_quantity",
      width: 70,
      align: "right",

      render: (text, record, index) => formatNumber(record.transfer_quantity, true, 3),
    },
    {
      title: "Recv.",
      key: "received_quantity",
      width: 100,
      align: "right",
      render: (text, record, index) => (
        <>
          {formatNumber(record.received_quantity, true, 3)}
          {record.status === "pending" && (
            <Button
              type="link"
              icon={<EditOutlined />}
              onClick={() => showOSK(record, `${record.item_name} (${record.unit})`)}
            />
          )}
        </>
      ),
    },
    {
      title: "Diff.",
      key: "descrepancy",
      width: 70,
      align: "right",
      render: (text, record, index) => formatNumber(record.descrepancy, true, 3),
    },
  ];

  const handleSearchOnChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    setTransferListTemp(
      transferListBase.filter((obj) => obj.item_name.toLowerCase().includes(value.toLowerCase()))
    );
  };

  // const handleRowclick = (item) => {
  //   // setSelectedList(item);
  //   // setIsEdit(true);
  //   // setVisibility(true);
  // };

  const OnClose = () => {
    setInventoryTransferVisibiliy(false);
    refetch();
    refetchPending();
    if (refetchMasterData) refetchMasterData();
  };

  const showOSK = (record, label) => {
    setSelectedList(record);
    setOSKParams({
      field: null,
      label,
      fieldValue: record.received_quantity,
      isNumberOnly: true,
      maxNumberAllowed: 100,
    });
    setOSKVisibility(true);
  };

  if (isError) {
    return (
      <Card>
        <RetryMessage message={error.message} onRetry={() => refetch()} />
      </Card>
    );
  }
  const handleRefresh = () => {
    console.log("refetch");
    refetch();
  };

  return (
    <>
      <Drawer
        title="Inventory Transfer List"
        // headerStyle={{
        //   padding: 0,
        //   paddingLeft: 20,
        //   paddingRight: 20,
        //   margin: 0,
        // }}
        closable={true}
        visible={inventoryTransferVisibiliy}
        width={350}
        onClose={OnClose}
        destroyOnClose={true}
        bodyStyle={{ padding: 12 }}
      >
        <Spin spinning={isLoading || isBusy}>
          <Tag style={{ width: "100%", paddingTop: 6, fontSize: 14, marginBottom: 10 }}>
            <Row justify="space-between" style={{ marginBottom: 6 }}>
              <Col>
                <b>Branch:</b> {branch_name}
              </Col>
              <Col>
                <b>Inv. CutOff:</b> {moment(inventory_cutoff).format("MM/DD/YYYY")}
              </Col>
            </Row>
          </Tag>
          <Row justify="space-between">
            <Col>
              <Input
                placeholder="Search item..."
                size="large"
                allowClear
                style={{ paddingLeft: 20, width: 240 }}
                // onPressEnter={handlesOnPressEnter}
                onChange={handleSearchOnChange}
                value={searchValue}
                prefix={
                  <Space>
                    <SearchOutlined style={{ marginRight: 12 }} />
                  </Space>
                }
                suffix={isLoading && <LoadingOutlined />}
              />
            </Col>
            <Col>
              <Space style={{ marginTop: 4 }}>
                {/* <Tooltip title="Add New Card">
                  <Button
                    type="primary"
                    shape="circle"
                    icon={<SnippetsOutlined />}
                    onClick={handleOpenSearch}
                    // disabled={!branchID ? true : false}
                  />
                </Tooltip> */}
                <Tooltip title="Refresh">
                  <Button
                    type="dashed"
                    shape="circle"
                    icon={<RedoOutlined />}
                    onClick={handleRefresh}
                  />
                </Tooltip>
              </Space>
            </Col>
          </Row>

          <Table
            loading={isLoading}
            rowKey="time_stamp"
            dataSource={transferListTemp}
            columns={columns}
            style={{ marginTop: 16 }}
            size="small"
            scroll={{ x: 300 }}
            // onRow={(record, rowIndex) => {
            //   return {
            //     onClick: (e) => {
            //       handleRowclick(record);
            //     }, // click row
            //   };
            // }}
            pagination={false}
            // onChange={handleTableOnChange}
          />

          {/* <ItemSelection
          items={items}
          visibility={visibility}
          setVisibility={setVisibility}
          queryKeys={queryKeys}
          user_id={user_id}
          transferList={transferList}
          setTransferList={setTransferList}
          selectedList={selectedList}
          setSelectedList={setSelectedList}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
        /> */}
        </Spin>
      </Drawer>
      <OSK
        visibility={oskVisibility}
        setVisibility={setOSKVisibility}
        form={setValueFromOSK}
        params={oskParams}
        isInputOnly
        // onCloseOSK={onKeyBoardClose}
      />
    </>
  );
};

export default InventoryTransferPrepArea;
