import React, {
  useState,
  useEffect,
} from "react";
import { Select } from "antd";
import { useSelector } from "react-redux";

const TableSelection = ({
  tableValue,
  onSelection,
  occupiedTables,
  usedTables,
  setUsedTables,
  disabled,
}) => {
  const { table_count } = useSelector(
    (state) => state.User
  );
  const [tableCount, setTableCount] = useState(
    []
  );
  const [localTableValue, setLocalTableValue] =
    useState([]);

  useEffect(() => {
    const tableArray = iterateTable(
      table_count,
      tableValue
    );
    setTableCount(tableArray);
    setLocalTableValue(tableValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableValue, usedTables]);

  const iterateTable = (tc, tv) => {
    const options = [];
    for (let i = 1; i < tc + 1; i++) {
      const value = i; //i.toString(36) + i;
      let disabled = false;
      if (usedTables.includes(i)) disabled = true;
      if (tv.includes(i)) disabled = false;

      options.push({
        label: `Table ${i}`,
        value,
        disabled,
      });
    }
    return options;
  };
  const selectProps = {
    mode: "multiple",
    style: {
      width: "100%",
    },
    value: localTableValue,
    options: tableCount,
    onChange: (newValue) => {
      setLocalTableValue(newValue);

      if (onSelection) {
        onSelection(newValue);
      }
      // setTableValue(newValue);
    },
    onSelect: (v) => {
      setUsedTables([...occupiedTables, v]);
    },
    onDeselect: (v) => {
      setUsedTables(
        occupiedTables.filter(function (item) {
          return item !== v;
        })
      );

      // console.log(v);
      // setTableValue(newValue);
    },

    placeholder: "Select Table/s...",
    // maxTagCount: "responsive"
  };
  return (
    <>
      <Select
        size="large"
        {...selectProps}
        allowClear={false}
        dropdownStyle={{ fontSize: 18 }}
        disabled={disabled}
      />
    </>
  );
};

export default TableSelection;
