import { useQuery, useMutation } from "react-query";
import http from "services/httpService";

// async function getSettings({ queryKey }) {
//     const [_, user_id] = queryKey;

//     const { data: settings } = await http.get(`/settings/${user_id}`);
//     return settings;
// }
// function useGetSettings(user_id) {
//     // console.log(data);
//     return useQuery(["settings", user_id], getSettings);
// }

async function storeIP(data) {
  const { data: ipData } = await http.post(`/settings/store-ip`, data);
  return ipData;
}
function useMutationStoreIP() {
  return useMutation(storeIP);
}

export { useMutationStoreIP };
