import React, { useState, useEffect } from "react";

import {
  Drawer,
  InputNumber,
  Button,
  Divider,
  Form,
  message,
  Checkbox,
  Descriptions,
  Spin,
  Empty,
  Tag,
  Modal,
  Row,
  Col,
} from "antd";
// import { ExpandOutlined } from "@ant-design/icons";
import customStyles from "helpers/customStyles";
import { formatNumber } from "helpers/numberFormatter";
import { QuestionCircleOutlined } from "@ant-design/icons";

import {
  useMutationUpdateBranchInventory,
  useGetItemInventory,
  useMutationCreateItemInventory,
  useMutationUpdateItemInventory,
} from "services/hooks/api/branchInventoryAPI";

const ItemEdit = ({
  params,
  setParams,
  setInventory,
  setInventoryTemp,
  branchID,
  selectedBranch,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [queryKeys, setQuerykEYS] = useState({
    item_id: null,
    branch_id: null,
    inventory_cutoff: null,
  });

  const [form] = Form.useForm();
  const [formInventory] = Form.useForm();
  const { isLoading, data, refetch } = useGetItemInventory(queryKeys);
  const mutationUpdateBranchInventory = useMutationUpdateBranchInventory();
  const mutationCreateItemInventory = useMutationCreateItemInventory();
  const mutationUpdateItemInventory = useMutationUpdateItemInventory();
  const [beginQty, setBeginQty] = useState(0);

  useEffect(() => {
    if (params && params.item && selectedBranch) {
      form.setFieldsValue({
        status: params.item.status === "active" ? true : false,
        min_stock_qty: params.item.min_stock_qty,
      });
      setQuerykEYS({
        item_id: params.item.item_id,
        branch_id: selectedBranch.branch_id,
        inventory_cutoff: selectedBranch.inventory_cutoff,
      });
    }
  }, [params, form, selectedBranch]);

  useEffect(() => {
    if (!isLoading && data) {
      formInventory.setFieldsValue({
        inventory_begin: data.inventory_begin,
        inventory_add: data.inventory_add,
        inventory_day_total: data.inventory_day_total,
        inventory_return: data.inventory_return,
        inventory_spoil: data.inventory_spoil,
        inventory_transfer: data.inventory_transfer,
        inventory_consumed: data.inventory_consumed,
        inventory_out: data.inventory_out,
        inventory_balance: data.inventory_balance,
      });
    }
  }, [data]);

  const handleClose = () => {
    form.resetFields();
    // setIsEdit(false);
    setIsSubmitting(false);
    setParams({ visible: false, item: null });
    setQuerykEYS({
      item_id: null,
      branch_id: null,
      inventory_cutoff: null,
    });
    setBeginQty(0);
  };

  const handleCreateNow = () => {
    setIsSubmitting(true);
    // qty, item_id, branch_id, inventory_cutoff
    mutationCreateItemInventory.mutate(
      {
        qty: params.item.qty,
        item_id: params.item.item_id,
        branch_id: selectedBranch.branch_id,
        inventory_cutoff: selectedBranch.inventory_cutoff,
      },
      {
        onError: (error) => {
          message.error("Something went wrong :/");
          setIsSubmitting(false);
          console.log(`Error:  ${error.message}`);
        },
        onSuccess: (data) => {
          refetch();
          formInventory.setFieldsValue({
            ...data,
          });
          message.success("Item CutOff Inventory created!");
          setIsSubmitting(false);
        },
      }
    );
  };
  const handleSave = () => {
    if (!isSubmitting) {
      setIsSubmitting(true);
      form
        .validateFields()
        .then(async (values) => {
          console.log(values);
          mutationUpdateBranchInventory.mutate(
            {
              item_id: params.item.item_id,
              branch_id: branchID,
              data: {
                status: values.status ? "active" : "inactive",
                min_stock_qty: values.min_stock_qty,
              },
            },
            {
              onError: (error) => {
                message.error("Something went wrong :/");
                // An error happened!
                setIsSubmitting(false);
                console.log(`Error:  ${error.message}`);
              },
              onSuccess: (data) => {
                // Boom baby!
                // console.log(data);
                message.success(data.message);
                let newItemParams = null;
                setInventory((prevState) =>
                  prevState.map((i) => {
                    if (i.item_id === params.item.item_id) {
                      const q = params.item.qty;
                      const m = values.min_stock_qty;
                      const stockLvl =
                        q > 0 && q >= m
                          ? "good"
                          : q > 0 && q < m
                          ? "re-stock"
                          : q <= 0
                          ? "depleted"
                          : "undefine";
                      newItemParams = {
                        ...i,
                        min_stock_qty: m,
                        stock_level: stockLvl,
                        status: values.status ? "active" : "inactive",
                      };
                      return {
                        ...i,
                        min_stock_qty: m,
                        stock_level: stockLvl,
                        status: values.status ? "active" : "inactive",
                      };
                    } else {
                      return i;
                    }
                  })
                );
                setInventoryTemp((prevState) =>
                  prevState.map((i) => {
                    if (i.item_id === params.item.item_id) {
                      const q2 = params.item.qty;
                      const m2 = values.min_stock_qty;
                      const stockLvl2 =
                        q2 > 0 && q2 >= m2
                          ? "good"
                          : q2 > 0 && q2 < m2
                          ? "re-stock"
                          : q2 <= 0
                          ? "depleted"
                          : "undefine";
                      return {
                        ...i,
                        min_stock_qty: m2,
                        stock_level: stockLvl2,
                        status: values.status ? "active" : "inactive",
                      };
                    } else {
                      return i;
                    }
                  })
                );
                setParams((prevState) => ({ ...prevState, item: newItemParams }));
                setIsSubmitting(false);
              },
            }
          );
        })
        .catch((errorInfo) => {
          console.log(errorInfo);
          message.warning("Unable to save, look's like you missed something.");
          setIsSubmitting(false);
        });
    }
  };

  const ButtonGroup = ({ isLoading }) => {
    return (
      <div style={{ textAlign: "right", marginBottom: 12 }}>
        <Button onClick={handleClose} disabled={isLoading}>
          Close
        </Button>
        {/* <Button type="primary" onClick={handleSave} loading={isLoading} style={{ marginLeft: 10 }}>
          Update
        </Button> */}
      </div>
    );
  };

  // const handleStatusChange = (e) => {
  //   const val = e.target.checked;

  //   setIsSubmitting(true);
  //   mutationUpdateBranchInventory.mutate(
  //     {
  //       item_id: params.item.item_id,
  //       branch_id: branchID,
  //       data: { status: val ? "active" : "inactive" },
  //     },
  //     {
  //       onError: (error) => {
  //         message.error("Something went wrong :/");
  //         // An error happened!
  //         setIsSubmitting(false);
  //         console.log(`Error:  ${error.message}`);
  //       },
  //       onSuccess: (data) => {
  //         // Boom baby!
  //         // console.log(data);
  //         message.success(data.message);

  //         setInventory((prevState) =>
  //           prevState.map((i) => {
  //             if (i.item_id === params.item.item_id) {
  //               return { ...i, status: val ? "active" : "inactive" };
  //             } else {
  //               return i;
  //             }
  //           })
  //         );
  //         setIsSubmitting(false);
  //       },
  //     }
  //   );
  // };

  const handleOnFinish = (values) => {
    Object.keys(values).forEach((key) => {
      if (!values[key]) {
        values[key] = 0;
      }
    });
    formInventory.setFieldsValue({
      ...values,
    });
    const decimalPoints = 3;
    const fSize = 16;
    Modal.confirm({
      width: 320,
      title: "Please check quantity. ",
      icon: <QuestionCircleOutlined style={{ color: "#2f54eb" }} />,
      content: (
        <>
          <p style={{ textAlign: "left", fontSize: fSize, padding: 0, margin: 0 }}>
            Begin:{" "}
            <b style={{ color: "red" }}>
              {formatNumber(values.inventory_begin, true, decimalPoints)}{" "}
            </b>
          </p>
          <p style={{ textAlign: "left", fontSize: fSize, padding: 0, margin: 0 }}>
            Add:{" "}
            <b style={{ color: "red" }}>
              {formatNumber(values.inventory_add, true, decimalPoints)}{" "}
            </b>
          </p>
          <p style={{ textAlign: "left", fontSize: fSize, padding: 0, margin: 0 }}>
            Day Total:{" "}
            <b style={{ color: "red" }}>
              {formatNumber(values.inventory_day_total, true, decimalPoints)}{" "}
            </b>
          </p>
          <p style={{ textAlign: "left", fontSize: fSize, padding: 0, margin: 0 }}>
            Transferred:{" "}
            <b style={{ color: "red" }}>
              {formatNumber(values.inventory_transfer, true, decimalPoints)}{" "}
            </b>
          </p>
          <p style={{ textAlign: "left", fontSize: fSize, padding: 0, margin: 0 }}>
            Out Qty:{" "}
            <b style={{ color: "red" }}>
              {formatNumber(values.inventory_out, true, decimalPoints)}{" "}
            </b>
          </p>
          <p style={{ textAlign: "left", fontSize: fSize, padding: 0, margin: 0 }}>
            Consumed Qty:{" "}
            <b style={{ color: "red" }}>
              {formatNumber(values.inventory_consumed, true, decimalPoints)}{" "}
            </b>
          </p>
          <p style={{ textAlign: "left", fontSize: fSize, padding: 0, margin: 0 }}>
            Spoil Qty:{" "}
            <b style={{ color: "red" }}>
              {formatNumber(values.inventory_spoil, true, decimalPoints)}{" "}
            </b>
          </p>
          <p style={{ textAlign: "left", fontSize: fSize, padding: 0, margin: 0 }}>
            Balance Qty:{" "}
            <b style={{ color: "red" }}>
              {formatNumber(values.inventory_balance, true, decimalPoints)}{" "}
            </b>
          </p>
          <Divider style={{ margin: 0, marginTop: 15, marginBottom: 10 }} />
          <span style={{ fontSize: 15, fontWeight: 600 }}>Are you sure to override?</span>
        </>
      ),
      okText: "Yes, I'm sure!",
      cancelText: "No",
      onOk: () => {
        setIsSubmitting(true);
        mutationUpdateItemInventory.mutate(
          {
            item_id: params.item.item_id,
            branch_id: selectedBranch.branch_id,
            inventory_cutoff: selectedBranch.inventory_cutoff,
            data: { ...values },
          },
          {
            onError: (error) => {
              message.error("Something went wrong :/");
              // An error happened!
              setIsSubmitting(false);
              console.log(`Error:  ${error.message}`);
            },
            onSuccess: (data) => {
              // Boom baby!
              // console.log(data);
              message.success(data.message);
              let newItemParams = null;
              setInventory((prevState) =>
                prevState.map((i) => {
                  if (i.item_id === params.item.item_id) {
                    const q = values.inventory_balance;
                    const m = i.min_stock_qty;
                    const stockLvl =
                      q > 0 && q >= m
                        ? "good"
                        : q > 0 && q < m
                        ? "re-stock"
                        : q <= 0
                        ? "depleted"
                        : "undefine";
                    newItemParams = { ...i, qty: values.inventory_balance, stock_level: stockLvl };
                    return { ...i, qty: values.inventory_balance, stock_level: stockLvl };
                  } else {
                    return i;
                  }
                })
              );
              setInventoryTemp((prevState) =>
                prevState.map((i) => {
                  if (i.item_id === params.item.item_id) {
                    const q2 = values.inventory_balance;
                    const m2 = i.min_stock_qty;
                    const stockLvl2 =
                      q2 > 0 && q2 >= m2
                        ? "good"
                        : q2 > 0 && q2 < m2
                        ? "re-stock"
                        : q2 <= 0
                        ? "depleted"
                        : "undefine";
                    return { ...i, qty: values.inventory_balance, stock_level: stockLvl2 };
                  } else {
                    return i;
                  }
                })
              );
              setParams((prevState) => ({ ...prevState, item: newItemParams }));
              setIsSubmitting(false);
            },
          }
        );
      },
    });
  };

  const handleBeginQtyChange = (value) => {
    const newValue = Number(value) || 0;
    setBeginQty(newValue);
  };

  const handleSetBeginQty = () => {
    formInventory.setFieldsValue({
      inventory_begin: beginQty,
      inventory_add: 0,
      inventory_day_total: beginQty,
      inventory_return: 0,
      inventory_spoil: 0,
      inventory_transfer: 0,
      inventory_consumed: 0,
      inventory_out: 0,
      inventory_balance: beginQty,
    });
  };

  const handleSetAllToZero = () => {
    formInventory.setFieldsValue({
      inventory_begin: 0,
      inventory_add: 0,
      inventory_day_total: 0,
      inventory_return: 0,
      inventory_spoil: 0,
      inventory_transfer: 0,
      inventory_consumed: 0,
      inventory_out: 0,
      inventory_balance: 0,
    });
  };

  return (
    <Drawer
      title="Item Branch Inventory Item"
      placement="right"
      width={350}
      closable={false}
      visible={params.visible}
      destroyOnClose={true}
      forceRender
    >
      <Spin spinning={isSubmitting}>
        {params && params.item && (
          <Descriptions bordered size="small" column={1}>
            {selectedBranch && (
              <Descriptions.Item
                label="Branch"
                style={{
                  fontWeight: 600,
                  border: "1px solid #d9d9d9",
                  // borderTop: "1px solid #d9d9d9",
                }}
                contentStyle={{
                  textAlign: "right",
                  fontWeight: 600,
                }}
              >
                {selectedBranch.branch_name}
              </Descriptions.Item>
            )}

            <Descriptions.Item
              label="Item Name"
              style={{
                fontWeight: 600,
                border: "1px solid #d9d9d9",
                // borderTop: "1px solid #d9d9d9",
              }}
              contentStyle={{
                textAlign: "right",
                fontWeight: 600,
              }}
            >
              {params.item.item_name}
            </Descriptions.Item>
            <Descriptions.Item
              label="Unit"
              style={{
                fontWeight: 600,
                border: "1px solid #d9d9d9",
                // borderTop: "1px solid #d9d9d9",
              }}
              contentStyle={{
                textAlign: "right",
                fontWeight: 600,
              }}
            >
              {params.item.unit}
            </Descriptions.Item>

            <Descriptions.Item
              label="Group"
              style={{
                fontWeight: 600,
                border: "1px solid #d9d9d9",
                // borderTop: "1px solid #d9d9d9",
              }}
              contentStyle={{
                textAlign: "right",
                fontWeight: 600,
              }}
            >
              {params.item.group_name}
            </Descriptions.Item>
          </Descriptions>
        )}

        <Form form={form} autoComplete="off">
          <Form.Item
            label="Min Stock Qty"
            name="min_stock_qty"
            rules={[
              {
                required: true,
                message: "This is required!",
              },
            ]}
            style={formItemLayout}
          >
            <InputNumber />
          </Form.Item>

          <Form.Item
            name="status"
            valuePropName="checked"
            style={{ marginTop: 12, marginBottom: 12 }}
          >
            <Checkbox
            // onChange={handleStatusChange}
            >
              Check if Active
            </Checkbox>
          </Form.Item>
        </Form>

        <Button block type="primary" size="large" onClick={handleSave}>
          Save Item Attributes
        </Button>

        <Divider>Override </Divider>
        <Tag
          style={{
            width: "100%",
            fontSize: 14,
            marginBottom: 6,
            paddingTop: 10,
            textAlign: "center",
          }}
        >
          {/* <div style={{ ...customStyles.spaceBetween, marginTop: 10 }}> */}
          {/* <h4>Inv. Override</h4> */}
          {selectedBranch && <h4>Inventory Cutoff: {selectedBranch.inventory_cutoff}</h4>}
          {/* </div> */}
        </Tag>

        {data ? (
          <>
            <Form
              size="small"
              form={formInventory}
              initialValues={{
                inventory_begin: 0,
                inventory_add: 0,
                inventory_day_total: 0,
                inventory_return: 0,
                inventory_spoil: 0,
                inventory_transfer: 0,
                inventory_consumed: 0,
                inventory_balance: 0,
                inventory_out: 0,
              }}
              onFinish={handleOnFinish}
            >
              <Descriptions bordered size="small" column={1}>
                <Descriptions.Item label="Begin">
                  <Form.Item name="inventory_begin" style={formItemLayout}>
                    <InputNumber size="large" />
                  </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label="Add">
                  <Form.Item name="inventory_add" style={formItemLayout}>
                    <InputNumber size="large" />
                  </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label="Day Total">
                  <Form.Item name="inventory_day_total" style={formItemLayout}>
                    <InputNumber size="large" />
                  </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label="Return">
                  <Form.Item name="inventory_return" style={formItemLayout}>
                    <InputNumber size="large" />
                  </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label="Spoil">
                  <Form.Item name="inventory_spoil" style={formItemLayout}>
                    <InputNumber size="large" />
                  </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label="Transfer">
                  <Form.Item name="inventory_transfer" style={formItemLayout}>
                    <InputNumber size="large" />
                  </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label="Out">
                  <Form.Item name="inventory_out" style={formItemLayout}>
                    <InputNumber size="large" />
                  </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label="Consumed">
                  <Form.Item name="inventory_consumed" style={formItemLayout}>
                    <InputNumber size="large" />
                  </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label="Balance">
                  <Form.Item name="inventory_balance" style={formItemLayout}>
                    <InputNumber size="large" />
                  </Form.Item>
                </Descriptions.Item>
              </Descriptions>
              <Form.Item
                wrapperCol={{
                  offset: 0,
                  span: 24,
                }}
              >
                <Button type="primary" htmlType="submit" block size="large">
                  Save Override
                </Button>
              </Form.Item>
            </Form>

            <Divider> Other Actions</Divider>
            <Button block ghost size="large" type="primary" onClick={handleSetAllToZero}>
              {" "}
              Set All To Zero
            </Button>

            <Row gutter={16} style={{ marginTop: 6 }}>
              <Col span={7}>
                <InputNumber size="large" value={beginQty} onChange={handleBeginQtyChange} />
              </Col>
              <Col span={17}>
                {" "}
                <Button block ghost size="large" type="primary" onClick={handleSetBeginQty}>
                  {" "}
                  Set Begin Qty{" "}
                </Button>
              </Col>
            </Row>
          </>
        ) : (
          <Empty
            imageStyle={{
              height: 60,
            }}
            style={{ marginTop: 20 }}
            description={<span>No Cutoff Inventory record for this item.</span>}
          >
            <Button type="primary" onClick={handleCreateNow}>
              Create Now
            </Button>
          </Empty>
        )}
        <Divider />
        <ButtonGroup isLoading={isSubmitting} />
      </Spin>
    </Drawer>
  );
};

export default ItemEdit;

const formItemLayout = {
  padding: 0,
  margin: 2,
};
