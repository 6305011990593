import React, { useState, useEffect, useContext } from "react";

import { SocketContext } from "services/contexts/socket";
import { useSelector } from "react-redux";
import {
  Card,
  Input,
  Space,
  Tag,
  Typography,
  Button,
  Modal,
  Table,
  Alert,
  Tooltip,
  message,
} from "antd";

import { SearchOutlined, RightOutlined, PrinterOutlined } from "@ant-design/icons";
import { formatNumber } from "helpers/numberFormatter";

import InventoryEntryBalance from "./components/InventoryEntryBalance";
import InventoryTransferPrepArea from "./components/InventoryTransferPrepArea";
import {
  useGetPrepDailyInventory,
  useGetPrepPendingInventoryTransfer,
} from "services/hooks/api/prepAreaAPI";

const { Text } = Typography;
const KitchenInventory = () => {
  const socket = useContext(SocketContext);
  const { branch_id, user_id, username, inventory_cutoff, branch_name, print_server_id } =
    useSelector((state) => state.User);
  const { isLoading, data, refetch } = useGetPrepDailyInventory({ branch_id, inventory_cutoff });

  const {
    isLoading: pendingTransferIsLoading,
    data: pendingTransferData,
    refetch: pendingTransferRefetch,
  } = useGetPrepPendingInventoryTransfer({
    branch_id,
    inventory_cutoff,
  });

  const [inventory, setInventory] = useState([]);
  const [inventoryTemp, setInventoryTemp] = useState([]);
  const [inventoryEntryBalanceParams, setInventoryEntryBalanceParams] = useState({
    visible: false,
    inventory_cutoff,
    branch_id,
    user_id,
    username,
    branch_name,
    print_server_id,
    selectedItem: null,
  });
  const [itemGroup, setItemGroup] = useState([]);
  const [pendingTransfer, setPendingTransfer] = useState(0);
  const [inventoryTransferVisibiliy, setInventoryTransferVisibiliy] = useState(false);
  const [itemGroupFilter, setItemGroupFilter] = useState(null);

  useEffect(() => {
    socket.on("updateTransfer", () => {
      pendingTransferRefetch();
    });
    socket.on("updateTransferAdmin", () => {
      refetch();
    });
  }, []);

  useEffect(() => {
    if (!isLoading && data) {
      setInventory(data.inventory);
      setInventoryTemp(data.inventory);
      setItemGroup(
        data.itemGroup.map((i) => {
          return { text: i.group_name, value: i.group_name };
        })
      );
    }
  }, [isLoading, data]);

  useEffect(() => {
    if (!pendingTransferIsLoading && pendingTransferData) {
      setPendingTransfer(pendingTransferData.total_pending);
    }
  }, [pendingTransferIsLoading, pendingTransferData]);

  const handleSearchOnChange = (e) => {
    e.preventDefault();
    const value = e.target.value;
    setInventoryTemp(
      inventory.filter(
        (obj) =>
          obj.item_name.toLowerCase().includes(value.toLowerCase()) ||
          obj.group_name.toLowerCase().includes(value.toLowerCase())
      )
    );
  };

  const handleOnItemSelect = (item) => {
    if (pendingTransfer > 0) {
      Modal.info({
        title: "There are pending incoming stock transfer!",
        content: (
          <div>
            <p>All pending transfer must be accepted before processing inventory.</p>
          </div>
        ),
        onOk() {},
      });
    } else {
      setInventoryEntryBalanceParams((prevState) => ({
        ...prevState,
        visible: true,
        selectedItem: item,
      }));
    }
  };
  const handleInventoryTransferView = () => {
    setInventoryTransferVisibiliy(true);
  };

  const columns = [
    {
      title: <Text>Item ({inventoryTemp.length})</Text>,
      // dataIndex: "item_name",
      key: "group_name",
      width: 125,
      render: (text, record, index) => (
        <Space direction="vertical" size={0}>
          <Text>{record.item_name}</Text>
          <Tag color="purple" style={{ marginRight: 2, fontSize: 10 }}>
            {record.group_name}
          </Tag>
        </Space>
      ),
      filters: itemGroup,
      defaultFilteredValue: [],
      onFilter: (value, record) => record.group_name.indexOf(value) === 0,
    },

    {
      title: "Balance",
      dataIndex: "inventory_balance",
      key: "inventory_balance",
      width: 35,
      align: "left",
      render: (text, record, index) => (
        <Space direction="vertical" size={0}>
          {/* <Text style={{ fontSize: 13 }}>
            Beg : {formatNumber(record.inventory_balance, true, 3)}
          </Text> */}
          <Text style={{ fontSize: 13 }}>
            Bal : {formatNumber(record.inventory_balance, true, 3)}
          </Text>
        </Space>
      ),
    },

    {
      title: "",
      dataIndex: "actions",
      width: 20,
      render: () => (
        <RightOutlined
          style={{
            fontSize: 12,
            color: "#8c8c8c",
          }}
        />
      ),
    },
  ];

  const handlePrint = () => {
    if (!itemGroupFilter && inventoryTemp.length <= 0) {
      message.error("Nothing to print");
    } else if (!itemGroupFilter && inventoryTemp.length > 0) {
      console.log(inventoryTemp);
    } else if (itemGroupFilter && inventoryTemp.length > 0) {
      const toPrint = inventoryTemp.filter((i) => itemGroupFilter.includes(i.group_name));
      if (toPrint.length <= 0) {
        message.error("Nothing to print");
      } else {
        console.log(toPrint);
      }
    }
  };

  const handleTableChange = (_, filters) => {
    setItemGroupFilter(filters.group_name);
  };

  return (
    <>
      <Card
        bodyStyle={{ padding: "12px 14px" }}
        size="small"
        extra={
          <Tooltip title="Print">
            <Button type="dashed" shape="circle" icon={<PrinterOutlined />} onClick={handlePrint} />
          </Tooltip>
        }
        title={
          <Input
            placeholder="Search item here..."
            size="large"
            allowClear
            style={{ width: "80%" }}
            onChange={handleSearchOnChange}
            // onKeyUp={handleSearchOnChange}
            // value={searchValue}
            prefix={
              <Space>
                <SearchOutlined style={{ marginRight: 20 }} />
              </Space>
            }

            // onFocus={handleOnFocus}
            // onBlur={handleOnBlur}
          />
        }
      >
        {pendingTransfer > 0 && (
          <Alert
            message={<>Pending Transfer ({pendingTransfer})</>}
            showIcon
            description="All pending Tranfer must be accepted.!"
            type="warning"
            action={
              <Button size="small" danger onClick={handleInventoryTransferView}>
                Show List
              </Button>
            }
          />
        )}

        <Table
          dataSource={inventoryTemp}
          rowKey="item_id"
          columns={columns}
          size="small"
          // pagination={false}
          expandable={{
            columnWidth: 24,
            expandedRowRender: (record) => (
              <>
                <table
                  style={{
                    paddingLeft: 38,
                    fontSize: 14,
                    marginBottom: 10,
                  }}
                >
                  <tbody>
                    <tr
                      style={{
                        borderBottom: "1px solid #bfbfbf",
                        backgroundColor: "#f0f0f0",
                      }}
                    >
                      <th>Process</th>
                      <th>Qty</th>
                    </tr>
                    <tr
                      style={{
                        backgroundColor: "#e6f7ff",
                      }}
                    >
                      <td>Begin</td>
                      <td> {formatNumber(record.inventory_begin, true, 3)}</td>
                    </tr>
                    <tr
                      style={{
                        backgroundColor: "#e6f7ff",
                      }}
                    >
                      <td>Add</td>
                      <td> {formatNumber(record.inventory_add, true, 3)}</td>
                    </tr>

                    <tr
                      style={{
                        backgroundColor: "#e6f7ff",
                      }}
                    >
                      <td>Day Total</td>
                      <td> {formatNumber(record.inventory_day_total, true, 3)}</td>
                    </tr>

                    <tr>
                      <td>Return</td>
                      <td> {formatNumber(record.inventory_return, true, 3)}</td>
                    </tr>

                    <tr>
                      <td>Out</td>
                      <td> {formatNumber(record.inventory_out, true, 3)}</td>
                    </tr>

                    <tr>
                      <td>Spoil</td>
                      <td> {formatNumber(record.inventory_spoil, true, 3)}</td>
                    </tr>

                    <tr>
                      <td>Transfer</td>
                      <td> {formatNumber(record.inventory_transfer, true, 3)}</td>
                    </tr>

                    <tr>
                      <td>Consumed</td>
                      <td> {formatNumber(record.inventory_consumed, true, 3)}</td>
                    </tr>
                    <tr
                      style={{
                        backgroundColor: "#e6f7ff",
                      }}
                    >
                      <td>Balance</td>
                      <td> {formatNumber(record.inventory_balance, true, 3)}</td>
                    </tr>
                  </tbody>
                </table>
              </>
            ),
            // rowExpandable: (record) => record.name !== 'Not Expandable',
          }}
          onChange={handleTableChange}
          onRow={(record, rowIndex) => {
            return {
              onClick: (e) => {
                handleOnItemSelect(record);
              }, // click row
            };
          }}
        />
      </Card>

      <InventoryEntryBalance
        params={inventoryEntryBalanceParams}
        setParams={setInventoryEntryBalanceParams}
        refetchPending={pendingTransferRefetch}
        setInventory={setInventory}
        setInventoryTemp={setInventoryTemp}
      />
      <InventoryTransferPrepArea
        inventoryTransferVisibiliy={inventoryTransferVisibiliy}
        setInventoryTransferVisibiliy={setInventoryTransferVisibiliy}
        refetchPending={pendingTransferRefetch}
        refetchMasterData={refetch}
      />
    </>
  );
};

export default KitchenInventory;

const testStyle = { fontSize: 12 };
