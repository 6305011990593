import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user_id: "",
  name: "",
  role_id: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserDetails(_, action) {
      return action.payload.userDetails;
    },
  },
});

export const { setUserDetails } = userSlice.actions;

export default userSlice.reducer;
