import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const transStatusSlice = createSlice({
  name: "trans-status",
  initialState,
  reducers: {
    setTransStatus(_, action) {
      return action.payload;
    },
  },
});
export const { setTransStatus } = transStatusSlice.actions;
export default transStatusSlice.reducer;
