import { useQuery, useMutation } from "react-query";
import http from "services/httpService";

async function checkUserSession() {
  const { data } = await http.get(`/checkUserSession`);
  return data;
}

async function logout() {
  await http.get(`/logout`);
}

async function auth(data) {
  const { data: loginDetails } = await http.post(`/auth`, data);
  return loginDetails;
}

async function whatsMyIP() {
  const { data: gotMyIP } = await http.get(`/whatsMyIP`);
  return gotMyIP;
}

/* useQuery and useMutation */

function useCheckUserSession() {
  return useQuery("checkUserSession", checkUserSession, { cacheTime: 0 });
}

function useIPChecker({ enabled }) {
  return useQuery("checkMyIp", whatsMyIP, { enabled });
}

function useLogout({ enabled }) {
  return useQuery("logout", logout, { cacheTime: 0, enabled });
}

function useAuth() {
  return useMutation(auth);
}

export { useCheckUserSession, useLogout, useAuth, useIPChecker };
