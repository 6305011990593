import { useQuery, useMutation } from "react-query";
import http from "services/httpService";

async function getItems({ queryKey }) {
  const { searchValue } = queryKey[1];
  const { data } = await http.get(`/items/master/${searchValue}`);
  return data;
}
function useGetItems(searchValue) {
  return useQuery(["items", searchValue], getItems);
}

async function getSaleableItems() {
  const { data } = await http.get(`/items/saleable-items`);
  return data;
}
function useGetSaleableItems() {
  return useQuery("saleable-items", getSaleableItems);
}

// ------------Mutation

async function storeItem({ data }) {
  const { data: storedItemData } = await http.post(`/item`, data);
  return storedItemData;
}
function useMutationStoreItem() {
  return useMutation(storeItem);
}

async function updateItem({ item_id, isPriceChanged, data }) {
  const { data: updatedItemData } = await http.put(
    `/item/update/${item_id}/${isPriceChanged}`,
    data
  );
  return updatedItemData;
}
function useMutationUpdateItem() {
  return useMutation(updateItem);
}

export { useGetSaleableItems, useGetItems, useMutationStoreItem, useMutationUpdateItem };
