import React from "react";

import ActualInventory from "./ActualInventory";
import BalanceInventory from "./BalanceInventory";
import { useSelector } from "react-redux";

const PrepArea = () => {
  const { inventory_type } = useSelector((state) => state.User);

  return inventory_type === "actual" ? (
    <ActualInventory isBalance={false} />
  ) : (
    <BalanceInventory isBalance={true} />
  );
};

export default PrepArea;
