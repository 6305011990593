import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Drawer,
  Input,
  InputNumber,
  Button,
  Divider,
  Form,
  message,
  Checkbox,
  Typography,
  Select,
} from "antd";
// import { ExpandOutlined } from "@ant-design/icons";
import { upperCase, titleCase } from "helpers/textTransformer";
import whichOS from "helpers/whichOS";
import { generateString } from "helpers/randomStringGenerator";
import { useMutationStoreItem, useMutationUpdateItem } from "services/hooks/api/itemsAPI";

const { Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;

const FormItemMaster = ({ params, setParams, otherData, setItems, setItemsTemp }) => {
  const { user_id } = useSelector((state) => state.User);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isItem, setIsItem] = useState(false);
  const [form] = Form.useForm(); // other details form
  const [others, setOthers] = useState({ category: "", group_name: "", unit: "" });

  const mutationStoreItem = useMutationStoreItem();
  const mutationUpdateItem = useMutationUpdateItem();

  useEffect(() => {
    if (params.isEdit && params.item) {
      if (params.item.category_id === 2) {
        setIsItem(true);
      }
      form.setFieldsValue({
        ...params.item,
        status: params.item.status === "active" ? true : false,
      });

      setOthers({
        category: params.item.category,
        group_name: params.item.group_name,
        unit: params.item.unit,
      });
    } else {
      form.setFieldsValue({
        is_salable: true,
        is_unli_basis: false,
        is_table_item: false,
        unit_price: 0,
        selling_price: 0,
        status: true,
      });
      setIsItem(false);
    }
  }, [params, form]);

  const handleClose = () => {
    form.resetFields();
    // setIsEdit(false);
    setIsSubmitting(false);
    setParams({ visible: false, item: null, isEdit: false });
  };

  const handleSave = (e, mode) => {
    if (!isSubmitting) {
      setIsSubmitting(true);
      form
        .validateFields()
        .then(async (values) => {
          if (params.isEdit) {
            const reducedValues = Object.keys(values).reduce((object, key) => {
              if (!["item_id"].includes(key)) {
                object[key] = values[key];
              }
              return object;
            }, {});

            const updateData = {
              ...reducedValues,
              is_pax_counter: values.is_pax_counter ? 1 : 0,
              is_salable: values.is_salable ? 1 : 0,
              status: values.status ? "active" : "inactive",
              user_id,
            };
            const isPriceChanged = params.item.unit_price !== values.unit_price;

            mutationUpdateItem.mutate(
              { item_id: values.item_id, isPriceChanged, data: updateData },
              {
                onError: (error) => {
                  message.error("Something went wrong :/");
                  // An error happened!
                  setIsSubmitting(false);
                  console.log(`Error:  ${error.message}`);
                },
                onSuccess: (data) => {
                  // Boom baby!
                  console.log(data);
                  message.success(data.message);
                  setIsSubmitting(false);

                  setItems((prevState) =>
                    prevState.map((item) => {
                      if (item.item_id === values.item_id) {
                        return { ...item, ...values, ...updateData, ...others };
                      } else {
                        return item;
                      }
                    })
                  );
                  setItemsTemp((prevState) =>
                    prevState.map((item) => {
                      if (item.item_id === values.item_id) {
                        return { ...item, ...values, ...updateData, ...others };
                      } else {
                        return item;
                      }
                    })
                  );
                  setIsSubmitting(false);
                  handleClose();
                },
              }
            );
          } else {
            const itemID = generateString(10, "G");

            const storeData = {
              ...values,
              item_id: itemID,
              is_pax_counter: values.is_pax_counter ? 1 : 0,
              is_salable: values.is_salable ? 1 : 0,
              status: values.status ? "active" : "inactive",
              user_id,
            };
            // console.log(values);
            // console.log(others);

            mutationStoreItem.mutate(
              { data: storeData },
              {
                onError: (error) => {
                  message.error("Something went wrong :/");
                  // An error happened!
                  setIsSubmitting(false);
                  console.log(`Error:  ${error.message}`);
                },
                onSuccess: (data) => {
                  // Boom baby!
                  // console.log(data);
                  message.success(data.message);
                  setIsSubmitting(false);
                  setItems((prevState) => [...prevState, { ...storeData, ...others }]);
                  setItemsTemp((prevState) => [...prevState, { ...storeData, ...others }]);

                  if (mode === "savenew") {
                    form.resetFields();
                    // setIsEdit(false);
                    setIsSubmitting(false);
                    setParams({ visible: true, item: null, isEdit: false });
                  } else {
                    handleClose();
                  }

                  // navigate("/cashier");
                },
              }
            );
          }
        })
        .catch((errorInfo) => {
          console.log(errorInfo);
          message.warning("Unable to save, look's like you missed something.");
          setIsSubmitting(false);
        });
    }
  };

  const handleFormValueChange = (v) => {
    const key = Object.keys(v)[0];
    const value = v[key];
    // console.log(key);
    // console.log(value);

    if (key === "category_id") {
      if (value === 1) {
        form.setFieldsValue({
          group_id: null,
          unit_id: null,
          is_salable: true,
        });
        setIsItem(false);
      } else {
        setIsItem(true);
        if (params.isEdit) {
          form.setFieldsValue({ ...params.item, category_id: 2 });
        } else {
          form.setFieldsValue({
            group_id: null,
            unit_id: null,
            unit_price: 0,
            selling_price: 0,
          });
        }
      }
      const cat = otherData.category.filter((cat) => cat.category_id === value);
      setOthers({ ...others, category: cat[0].category });
    }
    if (key === "group_id") {
      const grou = otherData.group.filter((grou) => grou.group_id === value);
      setOthers({ ...others, group_name: grou[0].group_name });
    }

    if (key === "unit_id") {
      const uni = otherData.unit.filter((uni) => uni.unit_id === value);
      setOthers({ ...others, unit: uni[0].unit });
    }

    // console.log(form.getFieldValue(key));
  };

  const ButtonGroup = ({ isLoading }) => {
    return (
      <div style={{ textAlign: "right", marginBottom: 12 }}>
        <Button onClick={handleClose} disabled={isLoading}>
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={(e) => handleSave(e, "save")}
          loading={isLoading}
          style={{ marginLeft: 10 }}
        >
          {params.isEdit ? "Update" : "Save"}
        </Button>

        <Button
          type="primary"
          onClick={(e) => handleSave(e, "savenew")}
          loading={isLoading}
          style={{ marginLeft: 10 }}
          disabled={params.isEdit}
        >
          Save & New
        </Button>
      </div>
    );
  };

  const handlesBlur = (e, mode) => {
    const { id, value } = e.target;
    if (mode === "upper") {
      form.setFieldsValue({ [id]: upperCase(value) });
    } else {
      form.setFieldsValue({ [id]: titleCase(value) });
    }
  };

  return (
    <Drawer
      title="Item"
      placement="right"
      closable={false}
      visible={params.visible}
      destroyOnClose={true}
      width={whichOS === "Linux" ? 350 : 500}
      forceRender
    >
      <Form
        form={form}
        // initialValues={{
        //   remember: true,
        // }}
        autoComplete="off"
        onValuesChange={handleFormValueChange}
      >
        {params.isEdit ? (
          <Form.Item
            label="Item ID"
            name="item_id"
            rules={[
              {
                required: true,
                message: "This is required!",
              },
            ]}
            style={formItemLayout}
          >
            <Input disabled />
          </Form.Item>
        ) : (
          <Form.Item
            label="Item ID"
            rules={[
              {
                required: true,
                message: "This is required!",
              },
            ]}
            style={formItemLayout}
          >
            <Text code>Auto Generate</Text>
          </Form.Item>
        )}

        <Form.Item
          label="Item Name"
          name="item_name"
          rules={[
            {
              required: true,
              message: "This is required!",
            },
          ]}
          style={formItemLayout}
        >
          <Input onBlur={(e) => handlesBlur(e, "upper")} maxLength={25} />
        </Form.Item>
        <Form.Item label="Description" name="item_description" style={formItemLayout}>
          <TextArea rows={2} maxLength={150} onBlur={(e) => handlesBlur(e, "title")} />
        </Form.Item>

        <Form.Item
          label="Category"
          name="category_id"
          rules={[
            {
              required: true,
              message: "This is required!",
            },
          ]}
          style={formItemLayout}
        >
          <Select>
            {otherData.category.map((category) => {
              return (
                <Option value={category.category_id} key={category.category_id}>
                  {category.category}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        {!isItem && (
          <>
            <Form.Item
              name="is_pax_counter"
              valuePropName="checked"
              style={{ marginTop: 12, marginBottom: 12 }}
            >
              <Checkbox
              // onChange={checkBoxChanged}
              // disabled={isEdit && selectedCard.cardholder_id ? true : false}
              >
                Is Pax Counter?
              </Checkbox>
            </Form.Item>
            <Form.Item label="Pax Multiplier" name="pax_multiplier" style={formItemLayout}>
              <InputNumber min={0} />
            </Form.Item>
          </>
        )}

        <Form.Item
          name="is_salable"
          valuePropName="checked"
          style={{ marginTop: 12, marginBottom: 12 }}
        >
          <Checkbox
          // onChange={checkBoxChanged}
          // disabled={isEdit && selectedCard.cardholder_id ? true : false}
          >
            Salable item?
          </Checkbox>
        </Form.Item>

        {isItem && (
          <div>
            <Form.Item
              name="is_unli_basis"
              valuePropName="checked"
              style={{ marginTop: 12, marginBottom: 12 }}
            >
              <Checkbox
              // onChange={checkBoxChanged}
              // disabled={isEdit && selectedCard.cardholder_id ? true : false}
              >
                Unli Based Item?
              </Checkbox>
            </Form.Item>

            <Form.Item
              name="is_table_item"
              valuePropName="checked"
              style={{ marginTop: 12, marginBottom: 12 }}
            >
              <Checkbox
              // onChange={checkBoxChanged}
              // disabled={isEdit && selectedCard.cardholder_id ? true : false}
              >
                Order/Request Item?
              </Checkbox>
            </Form.Item>

            <Form.Item
              label="Item group"
              name="group_id"
              rules={[
                {
                  required: true,
                  message: "This is required!",
                },
              ]}
              style={formItemLayout}
            >
              <Select>
                {otherData.group.map((group) => {
                  return (
                    <Option value={group.group_id} key={group.group_id}>
                      {group.group_name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              label="Unit"
              name="unit_id"
              rules={[
                {
                  required: true,
                  message: "This is required!",
                },
              ]}
              style={formItemLayout}
            >
              <Select>
                {otherData.unit.map((unit) => {
                  return (
                    <Option value={unit.unit_id} key={unit.unit_id}>
                      {unit.unit}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item
              label="Unit Price"
              name="unit_price"
              rules={[
                {
                  required: true,
                  message: "This is required!",
                },
              ]}
              style={formItemLayout}
            >
              <InputNumber min={0} />
            </Form.Item>

            <Form.Item
              label="Minumum Stock Quantity"
              name="min_stock_qty"
              rules={[
                {
                  required: true,
                  message: "This is required!",
                },
              ]}
              style={formItemLayout}
            >
              <InputNumber min={0} />
            </Form.Item>
          </div>
        )}

        <Form.Item
          label="Selling Price"
          name="selling_price"
          rules={[
            {
              required: true,
              message: "This is required!",
            },
          ]}
          style={formItemLayout}
        >
          <InputNumber min={0} />
        </Form.Item>

        <Form.Item
          name="status"
          valuePropName="checked"
          style={{ marginTop: 12, marginBottom: 12 }}
        >
          <Checkbox
          // onChange={checkBoxChanged}
          // disabled={isEdit && selectedCard.cardholder_id ? true : false}
          >
            Check if Active
          </Checkbox>
        </Form.Item>
      </Form>

      <Divider />
      <ButtonGroup isLoading={isSubmitting} />
    </Drawer>
  );
};

export default FormItemMaster;

const formItemLayout = {
  padding: 4,
  margin: 2,
};
