import { createSlice } from "@reduxjs/toolkit";

const initialState = null;

const transactionSlice = createSlice({
    name: "transaction",
    initialState,
    reducers: {
        setTransactionDetails(_, action) {
            return action.payload;
        }
    }
});

export const { setTransactionDetails } = transactionSlice.actions;

export default transactionSlice.reducer;
