import React, { useState, useEffect, useRef, useContext } from "react";

// import { SocketContext } from "services/contexts/socket";
import {
  Typography,
  Divider,
  Space,
  Button,
  Drawer,
  Collapse,
  Table,
  Input,
  Descriptions,
  InputNumber,
  Row,
  Col,
  Spin,
  message,
  Select,
  Modal,
  Alert,
} from "antd";

import {
  SearchOutlined,
  CheckOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";

import whichOS from "helpers/whichOS";
import { useMutationStoreDelivery, useMutationStoreSupplier } from "services/hooks/api/deliveryAPI";
import { formatNumber } from "helpers/numberFormatter";
import { titleCase } from "helpers/textTransformer";
import customStyles from "helpers/customStyles";

const { Option } = Select;
const { Text } = Typography;
const ItemSelection = ({
  items,
  setItems,
  visibility,
  setVisibility,
  queryKeys,
  user_id,
  setDeliveryList,
  setDeliveryListTemp,
  supplierList,
  setSupplierList,
  socket,
}) => {
  // const socket = useContext(SocketContext);
  const mutationStoreDelivery = useMutationStoreDelivery();
  const mutationStoreSupplier = useMutationStoreSupplier();
  const [dataSource, setDataSource] = useState([]);
  const [iscollapse, setIsCollapse] = useState([0]);
  const [item, setItem] = useState(null);
  const [selectedItem, setSelecteItem] = useState(null);
  const [isBusy, setIsBusy] = useState(false);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [supplier, setSupplier] = useState([]);
  const [supplierName, setSupplierName] = useState("");

  const inputRef = useRef(null);

  useEffect(() => {
    if (items) {
      setDataSource(items);
      setSupplier(supplierList);
    }
  }, [items]);

  const handleClose = () => {
    setItem(null);
    setVisibility(false);
  };

  const handleSearchOnChange = (e) => {
    e.preventDefault();
    const value = e.target.value;
    // setSearchValue(value);
    setDataSource(items.filter((obj) => obj.item_name.toLowerCase().includes(value.toLowerCase())));
  };

  const handleOnFocus = () => {
    setIsCollapse([1]);
  };
  const handleOnBlur = () => {
    setIsCollapse([0]);
    // setSearchValue("");
  };

  const handleOnSelect = (e, r) => {
    const d = new Date();
    const timeStamp = d.getTime();
    setSelecteItem(r);
    setItem({
      ...r,
      time_stamp: timeStamp,
      qty: 1,
      total: r.unit_price,
      branch_id: queryKeys.branch_id,
      inventory_cutoff: queryKeys.inventory_cutoff,
      user_id,
      unit: r.unit,
    });
  };

  const handleOnQtyChange = (value) => {
    // // const value = e.target.value;
    console.log(value);
    const newQty = Number(value); //|| 0;
    const newTotal = item.unit_price * newQty;
    setItem({ ...item, qty: newQty, total: newTotal });
  };

  const handleOnPriceChange = (value) => {
    // // const value = e.target.value;
    console.log(value);
    const newPrice = Number(value) || 0;
    const newTotal = newPrice * item.qty;
    setItem({ ...item, unit_price: newPrice, total: newTotal });
  };

  const preSave = () => {
    if (!item) {
      message.error("No selected item.");
      return false;
    }

    if (!selectedSupplier) {
      message.error("No selected supplier.");
      return false;
    }

    if (item.total === 0) {
      message.error("Total price cannot be zero.");
      return false;
    }

    const hasPriceChange = selectedItem.unit_price !== item.unit_price ? true : false;

    Modal.confirm({
      width: 350,
      title: "Please check item.",
      icon: <QuestionCircleOutlined style={{ color: "#2f54eb" }} />,
      content: (
        <>
          <p style={{ textAlign: "left", fontSize: 20, padding: 0, margin: 0 }}>
            Supplier: <b style={{ color: "red" }}>{selectedSupplier.label} </b>
          </p>
          <p style={{ textAlign: "left", fontSize: 20, padding: 0, margin: 0 }}>
            Item: <b style={{ color: "red" }}>{item.item_name}</b>
          </p>
          <p style={{ textAlign: "left", fontSize: 20, padding: 0, margin: 0 }}>
            Unit Price: <b style={{ color: "red" }}>{formatNumber(item.unit_price)} </b>
          </p>
          <p style={{ textAlign: "left", fontSize: 20, padding: 0, margin: 0 }}>
            Qty: <b style={{ color: "red" }}>{formatNumber(item.qty, true, 3)} </b>
          </p>

          <p style={{ textAlign: "left", fontSize: 20, padding: 0, margin: 0 }}>
            Total: <b style={{ color: "red" }}>{formatNumber(item.total)} </b>
          </p>
          <Divider style={{ margin: 0, marginTop: 15, marginBottom: 10 }} />
          <span style={{ fontSize: 15, fontWeight: 600 }}>
            Make sure you input the right Item price and quantity.
          </span>

          {hasPriceChange && (
            <Alert
              message="Warning"
              description="Price change detected, this may reflect to global sales report!"
              type="warning"
              showIcon
            />
          )}
        </>
      ),
      okText: "Yes, I'm sure!",
      cancelText: "No",
      onOk: handleSave,
    });
  };

  const handleSave = () => {
    const reduceItem = Object.keys(item).reduce((object, key) => {
      if (!["item_name", "unit", "selling_price"].includes(key)) {
        object[key] = item[key];
      }
      return object;
    }, {});
    const finalData = { ...reduceItem, supplier_id: selectedSupplier.value };
    const listData = {
      ...finalData,
      supplier_name: selectedSupplier.label,
      item_name: item.item_name,
    };
    const isPriceChanged = selectedItem.unit_price !== finalData.unit_price ? true : false;
    const toStore = { deliveryData: finalData, isPriceChanged, oldItem: selectedItem };

    setIsBusy(true);

    mutationStoreDelivery.mutate(toStore, {
      onError: (error) => {
        message.error("Something went wrong :/");
        // An error happened!
        console.log(`Error:  ${error.message}`);
        setIsBusy(false);
      },
      onSuccess: (data) => {
        message.success("Delivery Item added.");
        setItems((prevItem) =>
          prevItem.map((i) => {
            if (i.item_id === item.item_id) {
              return { ...i, unit_price: item.unit_price };
            } else {
              return i;
            }
          })
        );
        setDataSource((prevDataSource) =>
          prevDataSource.map((i) => {
            if (i.item_id === item.item_id) {
              return { ...i, unit_price: item.unit_price };
            } else {
              return i;
            }
          })
        );
        setDeliveryList((prevDeliveryList) => [...prevDeliveryList, listData]);
        setDeliveryListTemp((prevDeliveryListTemp) => [...prevDeliveryListTemp, listData]);
        setSelecteItem(null);
        setItem(null);
        setIsBusy(false);
        socket.emit("updateTransferAdmin", { room: "gogi" });
      },
    });
  };

  const handleDelete = () => {
    // if (!item) {
    //   message.error("No selected item.");
    //   return false;
    // }
    // setIsBusy(true);
    // mutationDeleteDelivery.mutate(item, {
    //   onError: (error) => {
    //     message.error("Something went wrong :/");
    //     // An error happened!
    //     console.log(`Error:  ${error.message}`);
    //     setIsBusy(false);
    //   },
    //   onSuccess: (data) => {
    //     message.success(data.message);
    //     setTransferList((prevData) =>
    //       prevData.filter((prev) => prev.time_stamp !== item.time_stamp)
    //     );
    //     socket.emit("updateTransfer", { room: "gogi" });
    //     setIsBusy(false);
    //     handleClose();
    //   },
    // });
  };

  const columns = [
    {
      title: "Item",
      dataIndex: "item_name",
      render: (_, record) => <Text style={{ color: "#002766" }}>{record.item_name}</Text>,
    },
    {
      title: "UPrice",
      dataIndex: "unit_price",
      align: "right",
      width: 100,
      render: (_, record) => formatNumber(record.unit_price, true, 2),
    },
  ];

  const addSupplier = (e) => {
    e.preventDefault();
    if (!supplierName && supplierName.length <= 0) {
      message.error("Supplier name cannot be empty.");
      return false;
    }

    if (supplierName.length < 5) {
      message.error("Supplier name must be at least 5 letters.");
      return false;
    }
    const d = new Date();
    const timeStamp = d.getTime();
    const newSupplier = { supplier_id: timeStamp, supplier_name: titleCase(supplierName) };

    mutationStoreSupplier.mutate(newSupplier, {
      onError: (error) => {
        message.error("Something went wrong :/");
        // An error happened!
        console.log(`Error:  ${error.message}`);
        setIsBusy(false);
      },
      onSuccess: (_) => {
        message.success("Supplier added!");

        setSupplier([...supplier, newSupplier]);
        setSupplierList((preveData) => [...preveData, newSupplier]);
        setSupplierName("");
        setTimeout(() => {
          inputRef.current?.focus();
        }, 0);
      },
    });
  };

  const onSupplierChange = (event) => {
    setSupplierName(event.target.value);
  };
  const handleOnSupplierSelect = (_, b) => {
    setSelectedSupplier(b);
  };

  return (
    <Drawer
      headerStyle={{ padding: 0, margin: 0 }}
      title={
        <>
          <Divider orientation="left" plain style={{ margin: 6, marginTop: 20 }}>
            Supplier
          </Divider>
          <div
            style={{
              ...customStyles.quantityContainer,
              marginTop: 0,
              minHeight: "auto",
            }}
          >
            <div
              style={{
                border: "1px dashed #d9d9d9",
                padding: 6,
              }}
            >
              <Select
                style={{
                  width: whichOS !== "Linux" ? 380 : 275,
                  fontSize: 16,
                }}
                bordered={false}
                optionLabelProp="label"
                placeholder="select from list"
                onSelect={handleOnSupplierSelect}
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider
                      style={{
                        margin: "8px 0",
                      }}
                    />
                    <Space
                      style={{
                        padding: "0 8px 4px",
                      }}
                    >
                      <Input
                        placeholder="Please enter supplier"
                        ref={inputRef}
                        value={supplierName}
                        onChange={onSupplierChange}
                        size="large"
                      />
                      <Button type="text" icon={<PlusOutlined />} onClick={addSupplier}>
                        Add{" "}
                      </Button>
                    </Space>
                  </>
                )}
              >
                {supplier.map((s) => {
                  return (
                    <Option value={s.supplier_id} key={s.supplier_id} label={s.supplier_name}>
                      <div
                        style={{
                          display: "flex",
                          fontSize: 18,
                          height: 35,
                          alignItems: "center",
                        }}
                      >
                        {s.supplier_name}
                      </div>
                    </Option>
                  );
                })}
              </Select>
            </div>
            {/* <Select
                style={{
                  width: 300,                  
                }}
                placeholder="select from list"
                onSelect={handleOnSupplierSelect}
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider
                      style={{
                        margin: "8px 0",
                      }}
                    />
                    <Space
                      style={{
                        padding: "0 8px 4px",
                      }}
                    >
                      <Input
                        placeholder="Please enter supplier"
                        ref={inputRef}
                        value={supplierName}
                        onChange={onSupplierChange}
                      />
                      <Button type="text" icon={<PlusOutlined />} onClick={addSupplier}>
                        Add item
                      </Button>
                    </Space>
                  </>
                )}
                options={supplier.map((s) => ({
                  label: s.supplier_name,
                  value: s.supplier_id,
                }))}
              /> */}
          </div>

          <Divider orientation="left" plain style={{ margin: 0, marginTop: 16, padding: 0 }}>
            Add Item
          </Divider>
          <Collapse accordion bordered={false} activeKey={iscollapse} ghost>
            <Collapse.Panel
              header={
                <Input
                  placeholder="Search here..."
                  size="large"
                  allowClear
                  style={{ width: "100%" }}
                  onChange={handleSearchOnChange}
                  // onKeyUp={handleSearchOnChange}
                  // value={searchValue}
                  prefix={
                    <Space>
                      <SearchOutlined style={{ marginRight: 20 }} />
                    </Space>
                  }
                  onFocus={handleOnFocus}
                  onBlur={handleOnBlur}
                />
              }
              key={1}
              showArrow={false}
            >
              <Table
                rowKey="item_id"
                dataSource={dataSource}
                columns={columns}
                size="small"
                pagination={false}
                scroll={{
                  y: 200,
                }}
                onRow={(record) => {
                  return {
                    onClick: (event) => {
                      handleOnSelect(event, record);
                    },
                  };
                }}
              />
            </Collapse.Panel>
          </Collapse>
        </>
      }
      width={whichOS !== "Linux" ? 420 : 320}
      placement="right"
      closable={false}
      visible={visibility}
      bodyStyle={{ padding: "4px 12px" }}
    >
      <Spin spinning={isBusy}>
        <Divider orientation="left" plain style={{ margin: 6 }}>
          Details
        </Divider>

        <Descriptions bordered size="small" style={{ marginBottom: 12 }} column={1}>
          <Descriptions.Item
            label={<b>Supplier</b>}
            // labelStyle={{ width: 120 }}
            contentStyle={{
              fontSize: 16,
            }}
          >
            {selectedSupplier ? selectedSupplier.label : <Text italic>no supplier</Text>}
          </Descriptions.Item>
          <Descriptions.Item
            label={<b>Item</b>}
            // labelStyle={{ width: 50 }}
            contentStyle={{
              fontSize: 16,
            }}
          >
            {item ? `${item.item_name}(${item.unit})` : <Text italic>no item</Text>}
          </Descriptions.Item>

          <Descriptions.Item
            label={<b>Price</b>}
            labelStyle={{
              paddingLeft: 30,
              // width: 50,
              borderRadius: 6,
              border: "2px solid #69c0ff",
              borderStyle: "dashed",
              borderRight: "none",
            }}
            contentStyle={{
              borderRadius: 6,
              border: "2px solid #69c0ff",
              borderStyle: "dashed",
              borderLeft: "none",
              textAlign: "right",
            }}
          >
            <InputNumber
              min={0}
              value={item ? item.unit_price : 0}
              style={{
                fontSize: 18,
                padding: 2,
                minWidth: 120,
                textAlign: "center",
              }}
              onChange={handleOnPriceChange}
              disabled={!item}
            />
          </Descriptions.Item>

          <Descriptions.Item
            label={<b>Quantity</b>}
            labelStyle={{
              paddingLeft: 30,
              // width: 50,
              borderRadius: 6,
              border: "2px solid #69c0ff",
              borderStyle: "dashed",
              borderRight: "none",
            }}
            contentStyle={{
              borderRadius: 6,
              border: "2px solid #69c0ff",
              borderStyle: "dashed",
              borderLeft: "none",
              textAlign: "right",
            }}
          >
            <InputNumber
              // min={1}
              // max={30}
              value={item ? item.qty : 1}
              style={{
                fontSize: 18,
                padding: 2,
                minWidth: 120,
                textAlign: "center",
              }}
              onChange={handleOnQtyChange}
              disabled={!item}
            />
          </Descriptions.Item>
          <Descriptions.Item
            label={<b>Total</b>}
            // labelStyle={{ width: 50 }}
            contentStyle={{
              fontSize: 16,
              textAlign: "center",
            }}
          >
            {item ? formatNumber(item.total) : formatNumber(0)}
          </Descriptions.Item>
        </Descriptions>

        <Row gutter={10}>
          <Col flex={2}>
            <Button
              type="primary"
              block
              size="large"
              onClick={preSave}
              icon={<PlusOutlined />}
              loading={isBusy}
            >
              Add
            </Button>
          </Col>

          <Col flex={2}>
            <Button
              block
              size="large"
              onClick={handleClose}
              icon={<CheckOutlined />}
              loading={isBusy}
            >
              Done
            </Button>
          </Col>
        </Row>
      </Spin>
    </Drawer>
  );
};

export default ItemSelection;
