import { useQuery } from "react-query";
import http from "services/httpService";

async function getSystemInitData() {
    const { data } = await http.get(`/systemInitData`);
    return data;
}
/* useQuery and useMutation */

function useGetSystemInitData({ enabled }) {
    return useQuery("systemInitData", getSystemInitData, {
        enabled,
        cacheTime: 0
    });
}

export { useGetSystemInitData };
