import React, { useEffect, useState, useContext } from "react";
import { SocketContext } from "services/contexts/socket";
import { Card, Col, Row, Tag, Table, Space, Typography, message, Button, Badge, Modal } from "antd";

// import ContentSpinner from "common/ContentSpinner";
import customStyles from "helpers/customStyles";
import InventoryEntry from "./components/InventoryEntry";
import LedgerView from "./components/LedgerView";
import {
  StarFilled,
  WarningOutlined,
  DeleteOutlined,
  CarryOutOutlined,
  BlockOutlined,
} from "@ant-design/icons";
import { titleCase } from "helpers/textTransformer";
import { useSelector } from "react-redux";
import whichOS from "helpers/whichOS";

import {
  //   useGetFav,
  useGetItemsByGroup,
  useGetTransactionTables,
  useGetPrepPendingInventoryTransfer,
  useMutationUpdateInventory,
} from "services/hooks/api/prepAreaAPI";

import InventoryTransferPrepArea from "./components/InventoryTransferPrepArea";

import OSKInline from "common/OSKInline";

const { Title, Text } = Typography;
const ActualInventory = () => {
  const {
    branch_id,
    user_id,
    username,
    inventory_cutoff,
    branch_name,
    print_server_id,
    branch_type,
  } = useSelector((state) => state.User);
  const socket = useContext(SocketContext);
  const [inventory, setInventory] = useState([]);
  const [branches, setBranches] = useState([]);
  const [inventoryTemp, setInventoryTemp] = useState([]);
  const [inventoryFav, setInventoryFav] = useState([]);
  const [pendingTransfer, setPendingTransfer] = useState(0);

  const [searchedText, setSearchText] = useState("");
  const { isLoading, data: inventoryData } = useGetItemsByGroup({
    branch_id,
  });
  const {
    isLoading: pendingTransferIsLoading,
    data: pendingTransferData,
    refetch: pendingTransferRefetch,
  } = useGetPrepPendingInventoryTransfer({
    branch_id,
    inventory_cutoff,
  });

  const {
    isLoading: tablesIsLoading,
    data: transactionTables,
    refetch,
  } = useGetTransactionTables({ branch_id, inventory_cutoff });

  const [ledgerParams, setledgerParams] = useState({
    visible: false,
    inventory_cutoff: null,
    branch_id: null,
  });

  const [inventoryEntryParams, setInventoryEntryParams] = useState({
    visible: false,
    inventory_cutoff,
    branch_id,
    user_id,
    username,
    branch_name,
    print_server_id,
    dineInTables: [],
    selectedItem: null,
  });

  const [inventoryTransferVisibiliy, setInventoryTransferVisibiliy] = useState(false);
  const mutationUpdateInventory = useMutationUpdateInventory();

  useEffect(() => {
    socket.on("updateTransfer", () => {
      pendingTransferRefetch();
    });
  }, []);

  useEffect(() => {
    if (!isLoading && inventoryData) {
      setInventory(inventoryData.items);
      setInventoryTemp(inventoryData.items);
      setInventoryFav(inventoryData.items.filter((favItem) => favItem.is_fav === 1));
      setBranches(inventoryData.branches);
    }
  }, [inventoryData, isLoading]);

  useEffect(() => {
    if (!tablesIsLoading && transactionTables) {
      setInventoryEntryParams((prevState) => ({
        ...prevState,
        dineInTables: transactionTables,
      }));
    }
  }, [transactionTables, tablesIsLoading]);

  useEffect(() => {
    if (!pendingTransferIsLoading && pendingTransferData) {
      setPendingTransfer(pendingTransferData.total_pending);
    }
  }, [pendingTransferIsLoading, pendingTransferData]);

  const columns = [
    {
      title: (
        <>
          <span style={{ marginRight: 10, fontSize: 18 }}>
            {inventoryTemp.length}- Item/s{" "}
            <Tag color="volcano" style={{ fontSize: 18, paddin: 4 }}>
              {" "}
              Search - {searchedText || "none"}
            </Tag>
          </span>
        </>
      ),
      dataIndex: "item_name",
      render: (text, record, index) => (
        <>
          <span
            style={{
              fontSize: 18,
              fontWeight: 450,
              color: "#10239e",
            }}
          >
            {record.is_fav ? <StarFilled style={{ color: "#ffc53d" }} /> : null} {record.item_name}{" "}
            ({record.unit})
          </span>
          <br></br>
          <span style={{ fontSize: 12 }}>
            {record.is_salable ? (
              <span
                style={{
                  color: "#fa541c",
                  marginRight: 2,
                }}
              >
                (salable)
              </span>
            ) : (
              <span
                style={{
                  color: "#fa541c",
                  marginRight: 2,
                }}
              >
                (buffet)
              </span>
            )}
            <i>{record.item_description}</i>
          </span>
        </>
      ),
    },
    {
      title: "Group",
      dataIndex: "group_name",
      width: 100,
      render: (text, record, index) => <i>{record.group_name}</i>,
    },
  ];

  const handleOnKeyBoardPress = (v) => {
    const value = v ? v.toLowerCase() : "";
    setSearchText(titleCase(value));
    setInventoryTemp(inventory.filter((obj) => obj.item_name.toLowerCase().includes(value)));
  };

  const handleOnSelect = (record) => {
    if (pendingTransfer > 0) {
      Modal.info({
        title: "There are pending incoming stock transfer!",
        content: (
          <div>
            <p>All pending transfer must be accepted before processing inventory.</p>
          </div>
        ),
        onOk() {},
      });
    } else {
      setInventoryEntryParams((prevState) => ({
        ...prevState,
        visible: true,
        selectedItem: record,
      }));
    }
  };

  const handleCloseTag = (e, record) => {
    e.preventDefault();
    handleFav(0, record, branch_id);
  };

  const handleSelectTag = (record) => {
    handleOnSelect(record);
  };

  const handleLedgerView = () => {
    setledgerParams({ visible: true, inventory_cutoff, branch_id });
  };
  const handeInventoryTrasnferView = () => {
    setInventoryTransferVisibiliy(true);
  };

  const handleFav = (value, item, branch_id) => {
    const updateData = {
      item_id: item.item_id,
      branch_id: branch_id,
      data: {
        is_fav: value,
      },
    };

    mutationUpdateInventory.mutate(updateData, {
      onError: (error) => {
        message.error("Something went wrong :/");
        // An error happened!
        console.log(`Error:  ${error.message}`);
      },
      onSuccess: (data) => {
        if (data.error) {
          message.error(data.message);
        } else {
          message.success("Fav list updated!");

          if (inventoryEntryParams.selectedItem) {
            setInventoryEntryParams((prevState) => ({
              ...prevState,
              selectedItem: { ...prevState.selectedItem, is_fav: value },
            }));
          }
          setInventory((prevInvState) =>
            prevInvState.map((invItem) => {
              if (invItem.item_id === item.item_id) {
                return { ...item, is_fav: value };
              } else {
                return invItem;
              }
            })
          );
          setInventoryTemp((prevInvTempState) =>
            prevInvTempState.map((invItem) => {
              if (invItem.item_id === item.item_id) {
                return { ...item, is_fav: value };
              } else {
                return invItem;
              }
            })
          );

          if (value) {
            setInventoryFav((prevInvFavState) => [...prevInvFavState, { ...item, is_fav: value }]);
          } else {
            setInventoryFav((prevInvFavState) =>
              prevInvFavState.filter((favItem) => favItem.item_id !== item.item_id)
            );
          }
        }
      },
    });
  };

  return (
    <Card
      bodyStyle={{
        padding: 12,
        position: "fixed",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        marginTop: 60,
        marginBottom: whichOS === "Win" ? 60 : 0,
        background: "white",
        // border: "1px solid orange",
      }}
      size="small"
    >
      <Row
        style={{
          height: "100%",
          //   border: "1px solid black",
        }}
        gutter={16}
      >
        <Col
          span={10}
          style={{
            height: "100%",
            paddingLeft: 20,
            // border: "1px solid black",
            // padding: 8,
          }}
        >
          {/* <ContentSpinner /> */}

          <div style={{ ...customStyles.spaceBetween, marginBottom: 8 }}>
            <Title level={5}>Quick Select</Title>
            <Space size={0}>
              <Badge count={pendingTransfer} showZero size="small" offset={[-6, 10]}>
                <Button type="link" icon={<BlockOutlined />} onClick={handeInventoryTrasnferView}>
                  Transfer
                </Button>
              </Badge>
              <Button type="link" icon={<CarryOutOutlined />} onClick={handleLedgerView}>
                Ledger
              </Button>
            </Space>
          </div>

          <div
            style={{
              overflowY: "scroll",
              height: 450,
              borderBottom: "0.3pt solid #f0f0f0",
              marginBottom: 10,
            }}
          >
            {/* <Space wrap={true} size={6}>
              {inventoryFav.length > 0 ? (
                inventoryFav.map((favitem, i) => {
                  return (
                    <Tag
                      color={i % 2 !== 0 ? "geekblue" : ""}
                      closable
                      key={i}
                      onClose={(e) => handleCloseTag(e, favitem)}
                      size="large"
                      style={{ fontSize: 18, padding: 8, marginBottom: 4 }}
                      onClick={() => handleSelectTag(favitem)}
                    >
                      <span style={{ marginRight: 6 }}>{favitem.item_name}</span>
                    </Tag>
                  );
                })
              ) : (
                <>
                  <WarningOutlined style={{ color: "#fa541c", marginRight: 2 }} />{" "}
                  <Text>Fav list is empty</Text>
                </>
              )}
            </Space> */}

            {inventoryFav.length > 0 ? (
              <Card bodyStyle={{ margin: 0, padding: 0 }}>
                {inventoryFav.map((favitem, i) => {
                  return (
                    <Card.Grid key={i} style={gridStyle}>
                      <div style={{ position: "relative", fontSize: 12 }}>
                        <Button
                          size="small"
                          type="link"
                          style={{ color: "grey" }}
                          onClick={() => handleSelectTag(favitem)}
                        >
                          {favitem.item_name}
                        </Button>
                        <Button
                          size="small"
                          type="link"
                          icon={<DeleteOutlined style={{ color: "red" }} />}
                          style={{ position: "absolute", right: -14, top: -14 }}
                          onClick={(e) => handleCloseTag(e, favitem)}
                        />
                      </div>
                    </Card.Grid>
                  );
                })}

                {/* // <Tag
                    //   color={i % 2 !== 0 ? "geekblue" : ""}
                    //   closable
                    //   key={i}
                    //   onClose={(e) => handleCloseTag(e, favitem)}
                    //   size="large"
                    //   style={{ fontSize: 18, padding: 8, marginBottom: 4 }}
                    //   onClick={() => handleSelectTag(favitem)}
                    // >
                    //   <span style={{ marginRight: 6 }}>{favitem.item_name}</span>
                    // </Tag> */}
              </Card>
            ) : (
              <>
                <WarningOutlined style={{ color: "#fa541c", marginRight: 2 }} />{" "}
                <Text>Fav list is empty</Text>
              </>
            )}
          </div>
        </Col>
        <Col span={14} style={{ height: "100%" }}>
          <div style={{ height: 290, marginBottom: 20 }}>
            <Table
              style={{ minHeight: 240 }}
              scroll={{
                y: 240,
              }}
              dataSource={inventoryTemp}
              rowKey="rec"
              columns={columns}
              pagination={false}
              size="small"
              onRow={(record) => {
                return {
                  onClick: (_) => {
                    //   console.log(record);
                    handleOnSelect(record);
                  },
                };
              }}
            />
          </div>
          <OSKInline oskName="oskPrepArea" onKeyBoardPress={handleOnKeyBoardPress} />
        </Col>
      </Row>
      <InventoryEntry
        params={inventoryEntryParams}
        setParams={setInventoryEntryParams}
        refetchTables={refetch}
        // ledgerLimitRefetch={ledgerLimitRefetch}
        onFav={handleFav}
        // setInventoryLedgerLimit={setInventoryLedgerLimit}
        branchType={branch_type}
        branches={branches}
      />
      <LedgerView params={ledgerParams} setParams={setledgerParams} />

      <InventoryTransferPrepArea
        inventoryTransferVisibiliy={inventoryTransferVisibiliy}
        setInventoryTransferVisibiliy={setInventoryTransferVisibiliy}
        refetchPending={pendingTransferRefetch}
      />
    </Card>
  );
};

export default ActualInventory;
const gridStyle = {
  width: "50%",
  padding: 20,
  textAlign: "center",
  postition: "relative",
};
