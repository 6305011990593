import React, { useState, useEffect, useContext } from "react";
import { SocketContext } from "services/contexts/socket";
import { useSelector } from "react-redux";
import {
  Table,
  Card,
  Space,
  Input,
  Tooltip,
  Button,
  Row,
  Col,
  Typography,
  Tag,
  Select,
  Spin,
  message,
} from "antd";
import { LoadingOutlined, SearchOutlined, RedoOutlined, SnippetsOutlined } from "@ant-design/icons";
import moment from "moment";
import { formatNumber } from "helpers/numberFormatter";
import customStyles from "helpers/customStyles";

import { useGetInventoryTransfer } from "services/hooks/api/inventoryTransferAPI";
import { useGetBranches } from "services/hooks/api/branchAPI";

import RetryMessage from "common/RetryMessage";
import ItemSelection from "./components/ItemSelection";

const { Text, Title } = Typography;
const { Option } = Select;
const InventoryTransfer = () => {
  const socket = useContext(SocketContext);
  const { user_id, role_id, branch_name, branch_id } = useSelector((state) => state.User);
  const [queryKeys, setQueryKeys] = useState({
    branch_id: null,
    inventory_cutoff: null,
    from_branch_id: role_id === 1 ? null : branch_id,
  });
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [visibility, setVisibility] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [branches, setBranches] = useState([]);
  const [transferList, setTransferList] = useState([]);
  const [items, setItems] = useState([]);
  const [selectedList, setSelectedList] = useState(null);
  const [isEdit, setIsEdit] = useState(null);
  const { isLoading, data, refetch, error, isError } = useGetInventoryTransfer(queryKeys, {
    enabled: true,
  });

  const branchData = useGetBranches();

  useEffect(() => {
    socket.on("updateTransferAdmin", () => {
      refetch();
    });
  }, []);

  useEffect(() => {
    if (!isLoading && data) {
      setItems(data.items);
      if (role_id !== 1) {
        setTransferList(data.transferList.filter((i) => i.from_branch_id === branch_id));
      } else {
        setTransferList(data.transferList);
      }
    }
  }, [isLoading, data]);

  useEffect(() => {
    if (!branchData.isLoading && branchData.data) {
      setBranches(branchData.data);
    }
  }, [branchData]);

  const columns = [
    {
      title: "#",
      key: "row_count",
      width: 40,
      fixed: "left",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Item",
      key: "item_name",
      width: 120,
      ellipsis: {
        showTitle: false,
      },
      fixed: "left",
      render: (text, record, index) => record.item_name,
    },
    {
      title: "From",
      key: "branch_name",
      width: 100,
      ellipsis: {
        showTitle: false,
      },
      render: (text, record, index) => record.branch_name,
    },
    {
      title: "To",
      key: "to_branch_name",
      width: 100,
      ellipsis: {
        showTitle: false,
      },
      render: (text, record, index) => (selectedBranch ? selectedBranch.branch_name : null),
    },
    {
      title: "Status",
      key: "status",
      width: 80,
      ellipsis: {
        showTitle: false,
      },
      render: (text, record, index) => (
        <Tag color={record.status === "pending" ? "blue" : "green"}>{record.status}</Tag>
      ),
    },
    {
      title: "Trans.",
      key: "transfer_quantity",
      width: 70,
      align: "right",

      render: (text, record, index) => formatNumber(record.transfer_quantity, true, 3),
    },
    {
      title: "To Recv.",
      key: "received_quantity",
      width: 70,
      align: "right",
      render: (text, record, index) => formatNumber(record.received_quantity, true, 3),
    },
    {
      title: "Diff.",
      key: "descrepancy",
      width: 70,
      align: "right",
      render: (text, record, index) => formatNumber(record.descrepancy, true, 3),
    },
  ];

  const handleBranchSelect = (value) => {
    const b = branches.filter((b) => b.branch_id === value);
    setSelectedBranch(b[0]);
    setQueryKeys({
      ...queryKeys,
      branch_id: b[0].branch_id,
      inventory_cutoff: b[0].inventory_cutoff,
    });
  };

  const handleFromBranchSelect = (value) => {
    setQueryKeys({ ...queryKeys, from_branch_id: value });
  };

  const handleOpenSearch = () => {
    if (queryKeys.branch_id === queryKeys.from_branch_id) {
      message.error("Transfer is not application in the same Branch!");
      return false;
    }

    if (queryKeys.branch_id && queryKeys.inventory_cutoff && queryKeys.from_branch_id) {
      setVisibility(true);
    } else {
      message.error("Please select From/To Branches");
    }
  };

  const handleSearchOnChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);

    if (role_id !== 1) {
      setTransferList(
        data.transferList.filter(
          (obj) =>
            obj.item_name.toLowerCase().includes(value.toLowerCase()) &&
            obj.from_branch_id === branch_id
        )
      );
    } else {
      setTransferList(
        data.transferList.filter((obj) => obj.item_name.toLowerCase().includes(value.toLowerCase()))
      );
    }

    // setTransferList(
    //   data.transferList.filter((obj) => obj.item_name.toLowerCase().includes(value.toLowerCase()))
    // );
  };

  const handleRowclick = (item) => {
    // console.log(item);
    if (item.status === "accepted") {
      message.error("Edit is not available if transfer has been accepted!");
      return false;
    }

    setSelectedList(item);
    setIsEdit(true);
    setVisibility(true);
  };

  if (isError) {
    return (
      <Card>
        <RetryMessage message={error.message} onRetry={() => refetch()} />
      </Card>
    );
  }
  const handleRefresh = () => {
    refetch();
  };

  return (
    <Spin spinning={isLoading}>
      <Card bodyStyle={{ padding: "20px 14px" }}>
        <Row>
          <Col span={4} style={{ textAlign: "right", paddingTop: 10 }}>
            <Text>From:</Text>
          </Col>
          <Col span={20}>
            {role_id === 1 ? (
              <div
                style={{
                  ...customStyles.quantityContainer,
                  marginTop: 0,
                  minHeight: "auto",
                }}
              >
                <div
                  style={{
                    border: "1px dashed #d9d9d9",
                    padding: 6,
                  }}
                >
                  <Select
                    style={{
                      width: 220,
                      fontSize: 16,
                    }}
                    bordered={false}
                    optionLabelProp="label"
                    placeholder="Select Branch"
                    onSelect={handleFromBranchSelect}
                    value={queryKeys.from_branch_id}
                  >
                    {branches.map((branch) => {
                      return (
                        <Option
                          value={branch.branch_id}
                          key={branch.branch_id}
                          label={<span>Branch - {branch.branch_name}</span>}
                        >
                          <div
                            style={{
                              display: "flex",
                              fontSize: 16,
                              height: 35,
                              alignItems: "center",
                            }}
                          >
                            Branch - {branch.branch_name}
                          </div>
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </div>
            ) : (
              <div
                style={{
                  ...customStyles.quantityContainer,
                  marginTop: 0,
                  minHeight: "auto",
                }}
              >
                <Title level={5}>{branch_name}</Title>
              </div>
            )}
          </Col>
        </Row>

        <Row style={{ marginTop: 10 }}>
          <Col span={4} style={{ textAlign: "right", paddingTop: 10 }}>
            <Text>To:</Text>
          </Col>
          <Col span={20}>
            {" "}
            <div
              style={{
                ...customStyles.quantityContainer,
                marginTop: 0,
                minHeight: "auto",
              }}
            >
              <Space>
                <div
                  style={{
                    border: "1px dashed #d9d9d9",
                    padding: 6,
                  }}
                >
                  <Select
                    style={{
                      width: 220,
                      fontSize: 16,
                    }}
                    bordered={false}
                    optionLabelProp="label"
                    placeholder="Select Branch"
                    onSelect={handleBranchSelect}
                    // value={branchID}
                  >
                    {branches.map((branch) => {
                      if (role_id !== 1 && branch.branch_id !== branch_id) {
                        return (
                          <Option
                            value={branch.branch_id}
                            key={branch.branch_id}
                            label={<span>Branch - {branch.branch_name}</span>}
                          >
                            <div
                              style={{
                                display: "flex",
                                fontSize: 16,
                                height: 35,
                                alignItems: "center",
                              }}
                            >
                              Branch - {branch.branch_name}
                            </div>
                          </Option>
                        );
                      } else if (role_id === 1) {
                        return (
                          <Option
                            value={branch.branch_id}
                            key={branch.branch_id}
                            label={<span>Branch - {branch.branch_name}</span>}
                          >
                            <div
                              style={{
                                display: "flex",
                                fontSize: 16,
                                height: 35,
                                alignItems: "center",
                              }}
                            >
                              Branch - {branch.branch_name}
                            </div>
                          </Option>
                        );
                      }
                    })}
                  </Select>
                </div>
              </Space>
            </div>
          </Col>
        </Row>

        <Tag style={{ width: "100%", fontSize: 14, marginTop: 20, marginBottom: 20 }}>
          <Row justify="space-between" style={{ paddingTop: 10, paddingBottom: 10 }}>
            <Col>
              <Text>
                <b>Branch:</b> {selectedBranch ? selectedBranch.branch_name : "(none)"}
              </Text>
            </Col>
            <Col>
              <Text>
                <b>Inv. CutOff:</b>{" "}
                {selectedBranch
                  ? moment(selectedBranch.inventory_cutoff).format("MM/DD/YYYY")
                  : "(none)"}
              </Text>
            </Col>
          </Row>
        </Tag>
        <Row justify="space-between">
          <Col>
            <Input
              placeholder="Search item..."
              size="large"
              allowClear
              style={{ paddingLeft: 20, width: 200 }}
              // onPressEnter={handlesOnPressEnter}
              onChange={handleSearchOnChange}
              value={searchValue}
              prefix={
                <Space>
                  <SearchOutlined style={{ marginRight: 12 }} />
                </Space>
              }
              suffix={isLoading && <LoadingOutlined />}
            />
          </Col>
          <Col>
            <Space style={{ marginTop: 4 }}>
              <Tooltip title="Add New Card">
                <Button
                  type="primary"
                  shape="circle"
                  icon={<SnippetsOutlined />}
                  onClick={handleOpenSearch}
                  // disabled={!branchID ? true : false}
                />
              </Tooltip>
              <Tooltip title="Refresh">
                <Button
                  type="dashed"
                  shape="circle"
                  icon={<RedoOutlined />}
                  onClick={handleRefresh}
                />
              </Tooltip>
            </Space>
          </Col>
        </Row>

        <Table
          loading={isLoading}
          rowKey="time_stamp"
          dataSource={transferList}
          columns={columns}
          style={{ marginTop: 20 }}
          size="small"
          scroll={{ x: 300 }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (e) => {
                handleRowclick(record);
              }, // click row
            };
          }}
          pagination={false}
          // onChange={handleTableOnChange}
        />

        <ItemSelection
          items={items}
          visibility={visibility}
          setVisibility={setVisibility}
          queryKeys={queryKeys}
          user_id={user_id}
          transferList={transferList}
          setTransferList={setTransferList}
          selectedList={selectedList}
          setSelectedList={setSelectedList}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          branches={branches}
        />
      </Card>
    </Spin>
  );
};

export default InventoryTransfer;
