import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Layout, Row, Col, Card, Form, Input, Button, Typography, Alert, Avatar } from "antd";
import { UserOutlined, LockOutlined, KeyOutlined } from "@ant-design/icons";
import ContentLoader from "common/ContentLoader";
import { useCheckUserSession, useAuth } from "services/hooks/api/authApi";
import logo from "assets/images/logo.png";
import OSK from "common/OSK";
import whichOS from "helpers/whichOS";
import config from "config.json";

const { Title, Text } = Typography;

const Login = () => {
  const navigate = useNavigate();
  const authMutation = useAuth();
  const [form] = Form.useForm();

  // const ipCheckerResponse = useIPChecker({ enabled: false });
  const checkUserSessionResponse = useCheckUserSession();
  const [oskParams, setOSKParams] = useState({});
  const [oskVisibility, setOSKVisibility] = useState(false);
  const [isOSKEnabled, setIsOSKEnabled] = useState(false);

  useEffect(() => {
    if (checkUserSessionResponse.isSuccess) {
      const { isUserAuthenticated, roleId } = checkUserSessionResponse.data;
      if (isUserAuthenticated) {
        navigate(config.rolePages[roleId]);
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkUserSessionResponse.isSuccess]);

  useEffect(() => {
    const osType = whichOS;
    if (osType === "UNIX") {
      setIsOSKEnabled(true);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogin = (values) => {
    authMutation.mutate(values, {
      onSuccess: (resData) => {
        if (!resData.isAuthError) {
          // console.log(resData.roleId);
          // dispatch(
          //   setUserDetails({
          //     userDetails: { role_id: resData.roleId },
          //   })
          // );
          navigate(config.rolePages[resData.roleId]);
          // window.location = `${
          //   config.appBasePath
          // }/${config.rolePages[resData.roleId]}`;
        }
      },
    });
  };
  const showOSK = (e, field, label, isPassword) => {
    if (isOSKEnabled) {
      const val = e.target.value;
      setOSKParams({
        field,
        label,
        fieldValue: val,
        isPassword,
        textFormat: "normal",
      });
      setOSKVisibility(true);
    }
  };

  return (
    <Layout style={bodyBg}>
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{
          height: "100vh",
        }}
      >
        <Col xl={8} md={6} xs={1} />
        <Col xl={8} md={12} xs={22}>
          {checkUserSessionResponse.isLoading ? (
            <ContentLoader />
          ) : (
            <Card
              extra={<Avatar size={80} src={logo} shape="square" style={{ zIndex: 99 }} />}
              title={
                <>
                  <Title level={4} style={{ color: "white" }}>
                    User Login
                  </Title>
                </>
              }
              style={{
                width: "100%",
                backgroundColor: "rgba(255,255,255,.2)",
              }}
            >
              {authMutation.isError && (
                <Alert
                  type="error"
                  message="There are some problems with your operation. Please try again."
                  showIcon
                  style={{ marginBottom: 16 }}
                />
              )}
              {authMutation.data?.isAuthError && (
                <Alert
                  type="error"
                  message={authMutation.data.message}
                  showIcon
                  style={{ marginBottom: 16 }}
                />
              )}
              <Form
                name="basic"
                initialValues={{ remember: true }}
                form={form}
                onFinish={handleLogin}
              >
                <Form.Item
                  // label="Username"
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "Please input your username!",
                    },
                  ]}
                >
                  <Input
                    placeholder="Username"
                    prefix={
                      <UserOutlined
                        style={{
                          marginRight: 12,
                          paddingRight: 10,
                          borderRight: "1px solid #d9d9d9",
                        }}
                      />
                    }
                    style={{
                      height: 50,
                      borderRadius: 8,
                    }}
                    onFocus={(e) => showOSK(e, "username", "User Name", false)}
                  />
                </Form.Item>

                <Form.Item
                  // label="Password"

                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input.Password
                    placeholder="Password"
                    prefix={
                      <KeyOutlined
                        style={{
                          marginRight: 12,
                          paddingRight: 10,
                          borderRight: "1px solid #d9d9d9",
                        }}
                      />
                    }
                    style={{
                      height: 50,
                      borderRadius: 8,
                    }}
                    onFocus={(e) => showOSK(e, "password", "Password", true)}
                  />
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={authMutation.isLoading}
                    block
                    size="large"
                    style={{ height: 45, fontSize: 20, borderRadius: 20 }}
                    icon={<LockOutlined />}
                  >
                    Login
                  </Button>
                </Form.Item>
              </Form>
              <Text style={{ color: "white" }}>Gogilicious App Ver 1.8 </Text>
            </Card>
          )}
        </Col>
        <Col xl={8} md={6} xs={1} />
      </Row>
      <OSK
        visibility={oskVisibility}
        setVisibility={setOSKVisibility}
        form={form}
        params={oskParams}
      />
    </Layout>
  );
};

export default Login;
const bodyBg = {
  // backgroundImage: `url(${bg2})`,
  backgroundImage: `url(https://itmg.sgp1.cdn.digitaloceanspaces.com/gogi/app-background.png)`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "bottom",
};
