import React from "react";

const Public = () => {
    return (
        <div style={{ padding: 16 }}>
            <p>A Public Page</p>
        </div>
    );
};

export default Public;
