import { useQuery, useMutation } from "react-query";
import http from "services/httpService";

// -------------------------------------- Query Block
// -------------------------------------- Query Block

async function getFavItems({ queryKey }) {
  const { branch_id } = queryKey[1];
  const { data } = await http.get(`/prep-area/fav/${branch_id}`);
  return data;
}
function useGetFav(filters) {
  return useQuery(["fav-items", filters], getFavItems);
}

// ----------------------------------------------

async function getItemsByGroup({ queryKey }) {
  const { branch_id } = queryKey[1];
  const { data: timeByGroup } = await http.get(`/prep-area/group/${branch_id}`);
  return timeByGroup;
}
function useGetItemsByGroup(filters) {
  return useQuery(["items-by-group", filters], getItemsByGroup);
}

// ----------------------------------------------

async function getTransactionTables({ queryKey }) {
  const { branch_id, inventory_cutoff } = queryKey[1];
  const { data: transactionTable } = await http.get(
    `/prep-area/transaction-tables/${branch_id}/${inventory_cutoff}`
  );
  return transactionTable;
}
function useGetTransactionTables(filters) {
  return useQuery(["pre-area-transaction-table", filters], getTransactionTables);
}

// ----------------------------------------------

async function getItemCutoffInventory({ queryKey }) {
  const { item_id, branch_id, inventory_cutoff } = queryKey[1];
  const { data: itemCutoffInventory } = await http.get(
    `/prep-area/item-cutoff-inventory/${item_id}/${branch_id}/${inventory_cutoff}`
  );
  return itemCutoffInventory;
}
function useGetItemCutoffInventory(filters) {
  return useQuery(["item-cutoff-inventory", filters], getItemCutoffInventory);
}

// ----------------------------------------------

async function getItemCutoffLedger({ queryKey }) {
  const { item_id, branch_id, inventory_cutoff } = queryKey[1];

  const { data: itemCutoffLedger } = await http.get(
    `/prep-area/item-cutoff-ledger/${item_id}/${branch_id}/${inventory_cutoff}`
  );
  return itemCutoffLedger;
}
function useGetItemCutoffLedger(filters) {
  return useQuery(["item-cutoff-ledger", filters], getItemCutoffLedger);
}

// ----------------------------------------------

async function getItemCutoffLedgerLimit({ queryKey }) {
  const { branch_id, inventory_cutoff } = queryKey[1];
  const { data: itemCutoffLedgerLimit } = await http.get(
    `/prep-area/item-cutoff-ledger-limit/${branch_id}/${inventory_cutoff}`
  );
  return itemCutoffLedgerLimit;
}
function useGetItemCutoffLedgerLimit(filters) {
  return useQuery(["item-cutoff-ledger-limit", filters], getItemCutoffLedgerLimit);
}

// ----------------------------------------------

async function getPrepInventoryTransfer({ queryKey }) {
  const { branch_id, inventory_cutoff } = queryKey[1];
  const { data: inventoryTransfer } = await http.get(
    `/prep-area/inventory-transfer/${branch_id}/${inventory_cutoff}`
  );
  return inventoryTransfer;
}
function useGetPrepInventoryTransfer(filters) {
  return useQuery(["prep-inventory-transfer", filters], getPrepInventoryTransfer);
}

async function getPrepPendingInventoryTransfer({ queryKey }) {
  const { branch_id, inventory_cutoff } = queryKey[1];
  const { data: inventoryPendingTransfer } = await http.get(
    `/prep-area/pending-inventory-transfer/${branch_id}/${inventory_cutoff}`
  );
  return inventoryPendingTransfer;
}
function useGetPrepPendingInventoryTransfer(filters) {
  return useQuery(["prep-pending-inventory-transfer", filters], getPrepPendingInventoryTransfer);
}

async function getPrepDailyInventory({ queryKey }) {
  const { branch_id, inventory_cutoff } = queryKey[1];
  const { data: dailyInventory } = await http.get(
    `/prep-area/daily-inventory/${branch_id}/${inventory_cutoff}`
  );
  return dailyInventory;
}

function useGetPrepDailyInventory(filters) {
  return useQuery(["prep-daily-inventory", filters], getPrepDailyInventory);
}

// -------------------------------------- Query Block

// -------------------------------------- Mutation Block
// -------------------------------------- Mutation Block
async function updateInventory({ item_id, branch_id, data }) {
  const { data: updateInventoryData } = await http.put(
    `/prep-area/inventory-update/${item_id}/${branch_id}`,
    data
  );
  return updateInventoryData;
}

function useMutationUpdateInventory() {
  return useMutation(updateInventory);
}

async function updateInventoryDaily({ item_id, branch_id, inventory_cutoff, data }) {
  const { data: updateInventoryDailyData } = await http.put(
    `/prep-area/inventory-daily-update/${item_id}/${branch_id}/${inventory_cutoff}`,
    data
  );
  return updateInventoryDailyData;
}

function useMutationUpdateInventoryDaily() {
  return useMutation(updateInventoryDaily);
}

async function updateInventoryAll({ item_id, data }) {
  const { data: updateInventoryAllData } = await http.put(
    `/prep-area/inventory-update-all/${item_id}`,
    data
  );
  return updateInventoryAllData;
}

function useMutationUpdateInventoryAll() {
  return useMutation(updateInventoryAll);
}

async function updatePrepInventoryTranferItem(data) {
  const { data: updatePrepInventoryTransferData } = await http.put(
    `/prep-area/inventory-transfer/update/${data.time_stamp}`,
    data
  );
  return updatePrepInventoryTransferData;
}
function useMutationPrepUpdateInventoryTranferItem() {
  return useMutation(updatePrepInventoryTranferItem);
}

async function updateInventoryBalance({ item_id, data }) {
  const { data: updateInventoryBalance } = await http.put(
    `/prep-area/inventory-update-balance/${item_id}`,
    data
  );
  return updateInventoryBalance;
}

function useMutationUpdateInventoryBalance() {
  return useMutation(updateInventoryBalance);
}

// -------------------------------------- Mutation Block
// -------------------------------------- Mutation Block
export {
  useGetFav,
  useGetItemsByGroup,
  useGetTransactionTables,
  useGetItemCutoffInventory,
  useGetItemCutoffLedger,
  useGetItemCutoffLedgerLimit,
  useGetPrepInventoryTransfer,
  useGetPrepPendingInventoryTransfer,
  useGetPrepDailyInventory,
  useMutationUpdateInventory,
  useMutationUpdateInventoryDaily,
  useMutationUpdateInventoryAll,
  useMutationPrepUpdateInventoryTranferItem,
  useMutationUpdateInventoryBalance,
};
