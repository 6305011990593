import Login from "pages/public/Login";
import PageNotFound from "pages/public/PageNotFound";
import Public from "pages/public/Public";
import DeviceNotAllowed from "pages/public/DeviceNotAllowed";
import IPCheckerError from "pages/public/IPCheckerError";

let publicRoutes = [
  {
    path: "/public",
    component: <Public />,
  },
  {
    path: "/login",
    component: <Login />,
  },
  {
    path: "/404",
    component: <PageNotFound />,
  },
  {
    path: "/403",
    component: <DeviceNotAllowed />,
  },
  {
    path: "/ip-chekcer-error/:ip/:message",
    component: <IPCheckerError />,
  },
];

export default publicRoutes;
