import { useQuery, useMutation } from "react-query";
import http from "services/httpService";

// async function getItems({ queryKey }) {
//     const [_, searchValue] = queryKey;
//     const { data } = await http.get(`items/dynamicSearch/${searchValue}`);
//     return data;
// }
// function useGetItems(searchValue) {
//     return useQuery(["items", searchValue], getItems);
// }

async function getExpense({ queryKey }) {
    const { branch_id, cutoff } = queryKey[1];
    const { data } = await http.get(`expense/${branch_id}/${cutoff}`);
    return data;
}
function useGetExpense(filters) {
    return useQuery(["expense", filters], getExpense);
}

// -------------------------------------- Mutation
async function insertExpense(data) {
    console.log(data);
    const { data: expenseDetails } = await http.post(`/expense`, data);
    return expenseDetails;
}

function useMutationInsertExpense() {
    return useMutation(insertExpense);
}

async function deleteExpense(data) {
    const { data: expenseDetails } = await http.delete(
        `/expense/${data.expense_id}`,
        data
    );
    return expenseDetails;
}

function useMutationDeleteExpense() {
    return useMutation(deleteExpense);
}

export { useGetExpense, useMutationInsertExpense, useMutationDeleteExpense };
