import { useQuery, useMutation } from "react-query";
import http from "services/httpService";

async function getBranches() {
  const { data } = await http.get(`/branches`);
  return data;
}
function useGetBranches() {
  return useQuery(["branches"], getBranches);
}

// async function getSaleableItems() {
//   const { data } = await http.get(`/items/saleable-items`);
//   return data;
// }
// function useGetSaleableItems() {
//   return useQuery("saleable-items", getSaleableItems);
// }

// // ------------Mutation

// async function storeBranchInventory({ data }) {
//   const { data: storedInventoryData } = await http.post(`/branch-inventory`, data);
//   return storedInventoryData;
// }
// function useMutationStoreBranchInventory() {
//   return useMutation(storeBranchInventory);
// }

// async function updateBranchInventory({ item_id, branch_id, data }) {
//   const { data: updatedBranchInventory } = await http.put(
//     `/branch-inventory/update/${item_id}/${branch_id}`,
//     data
//   );
//   return updatedBranchInventory;
// }
// function useMutationUpdateBranchInventory() {
//   return useMutation(updateBranchInventory);
// }

export { useGetBranches };
