import React, { useEffect } from "react";

import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";

const OSKInline = ({
  oskName = "default",
  onKeyBoardPress,
  maxLength = 50,
  isNumeric = false,
  noDecimal = false,
  colorTheme = "",
  gotValue = false,
  oskValue,
}) => {
  let oskRef = React.useRef();

  const standardKeys = [
    // "1 2 3 4 5 6 7 8 9 0",
    "Q W E R T Y U I O P",
    "A S D F G H J K L",
    "Z X C V B N M {bksp}",
    "{clr} {space}",
  ];

  const numericKeys = [
    // "1 2 3 4 5 6 7 8 9 0",
    "7 8 9",
    "4 5 6",
    "1 2 3",
    "0 {clr} .",
  ];

  const numericKeysNoDecimal = [
    // "1 2 3 4 5 6 7 8 9 0",
    "7 8 9",
    "3 4 5",
    "0 1 2",
    "{clr}",
  ];

  useEffect(() => {
    if (oskRef.current) oskRef.current.clearInput();
  }, []);

  useEffect(() => {
    if (oskRef.current && gotValue) oskRef.current.setInput(oskValue);
    console.log(oskValue);
  }, [oskValue]);

  const onChange = (input) => {
    let i = input;
    if (!input && isNumeric) i = 0;
    onKeyBoardPress(i);
  };

  const onKeyPress = (button) => {
    switch (button) {
      case "{clr}":
        oskRef.current.clearInput();
        onKeyBoardPress(isNumeric ? "0" : "");
        break;
      default:
        return;
    }
  };

  return (
    <>
      <Keyboard
        keyboardRef={(r) => (oskRef.current = r)}
        onChange={onChange}
        onKeyPress={onKeyPress}
        disableButtonHold={true}
        maxLength={maxLength}
        inputName={oskName}
        baseClass={oskName}
        theme={`hg-theme-default ${colorTheme}`}
        // rtl={false}
        layoutName="default"
        layout={{
          default: isNumeric ? (noDecimal ? numericKeysNoDecimal : numericKeys) : standardKeys,
        }}
        display={{
          "{clr}": isNumeric ? "C" : "Clear",
          "{cancel}": "Close",
          "{bksp}": "&#8678;",
          "{ok}": "&#10004;",
          "{space}": "&#8212;",
        }}
      />
    </>
  );
};

export default OSKInline;
