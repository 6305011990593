import React, { useEffect, useState } from "react";
import { Result, Button, Modal } from "antd";
import { useLogout } from "services/hooks/api/authApi";
import { LoadingOutlined } from "@ant-design/icons";
import config from "config.json";

const DeviceNotAllowed = () => {
  const [isLogoutLoadingVisible, setIsLogoutLoadingVisible] = useState(false);
  const logoutResponse = useLogout({
    enabled: isLogoutLoadingVisible,
  });

  useEffect(() => {
    if (logoutResponse.isSuccess) {
      if (config.platform === "web") {
        window.location = `${config.appBasePath}/`;
      } else {
        window.location.reload();
      }
    }
  }, [logoutResponse.isSuccess]);
  return (
    <>
      <Modal
        visible={isLogoutLoadingVisible}
        footer={null}
        closable={false}
        width={300}
        style={{ zIndex: 999999 }}
      >
        <div style={{ textAlign: "center" }}>
          <LoadingOutlined /> Logging out. Please wait ...
        </div>
      </Modal>

      <Result
        status="403"
        title="Device Not Allowed!"
        subTitle="Sorry, your user account is not allowed to use this device"
        extra={
          <Button
            type="dashed"
            onClick={() => setIsLogoutLoadingVisible(true)}
            style={{ height: 50, width: 220, fontSize: 20 }}
          >
            Go to login page!
          </Button>
        }
        style={{ height: "calc(100vh)" }}
      />
    </>
  );
};

export default DeviceNotAllowed;
