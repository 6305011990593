import React, { useState, useEffect, useContext } from "react";
import { SocketContext } from "services/contexts/socket";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Table,
  Typography,
  Descriptions,
  Divider,
  Space,
  Button,
  Row,
  Col,
  Tag,
  message,
  Spin,
  Select,
  Modal,
  Form,
  InputNumber,
  Input,
} from "antd";
import {
  RightOutlined,
  UsergroupAddOutlined,
  ClockCircleOutlined,
  RightCircleOutlined,
  LeftCircleOutlined,
  PlusOutlined,
  SaveOutlined,
  DeleteOutlined,
  CloseOutlined,
  LockOutlined,
  CheckOutlined,
  UndoOutlined,
  PrinterOutlined,
  UnlockOutlined,
} from "@ant-design/icons";
import moment from "moment";
import customStyles from "helpers/customStyles";
import { generateString } from "helpers/randomStringGenerator";
import { hoursFormatter } from "helpers/hoursFormatter";
import { titleCase, upperCase } from "helpers/textTransformer";
import { setTransactionDetails } from "services/redux/slices/transaction";
import {
  useGetUsedTables,
  useMutationInsertTransaction,
  useMutationUpdateTransaction,
  useMutationUpdateTransactionBasic,
} from "services/hooks/api/transactionAPI";

import FormItem from "./components/FormItem";
import TableSelection from "./components/TableSelection";
import { formatNumber } from "helpers/numberFormatter";

const { Text } = Typography;
const { Option } = Select;
const { confirm } = Modal;

const Transaction = () => {
  const socket = useContext(SocketContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetails = useSelector((state) => state.User);
  const selectedTransaction = useSelector((state) => state.Transaction);
  const [form] = Form.useForm();
  // const occupiedTables = useSelector((state) => state.UsedTables);
  // const { transaction: selectedTransaction } = useSelector(
  //     (state) => state.Transaction
  // );
  const mutationInsertTransaction = useMutationInsertTransaction();
  const mutationUpdateTransaction = useMutationUpdateTransaction();
  const mutationUpdateTransactionBasic = useMutationUpdateTransactionBasic();
  const { isLoading, data: occupiedTables } = useGetUsedTables({
    branch_id: userDetails.branch_id,
    cutoff: userDetails.cutoff,
  });

  const transactionDefaultValue = {
    transaction_id: generateString(8, "T", "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"),
    branch_id: userDetails.branch_id,
    total: 0,
    pax: 0,
    items: 0,
    duration: 0,
    start: moment(userDetails.cutoff + moment().format(" HH:mm:ss")).format("YYYY-MM-DD HH:mm:ss"),
    end: null,
    tables: [],
    table_count: 0,
    status: "started",
    payment_type: "cash",
    cutoff: userDetails.cutoff,
    user_id: userDetails.user_id,
    remarks: 0,
  };
  const [formItemParams, setFormItemParams] = useState({
    visibility: false,
    isEdit: false,
    data: null,
  });
  const [transaction, setTransaction] = useState(transactionDefaultValue);
  const [transactionItem, setTransactionItem] = useState([]);

  const [isEdit, setIsEdit] = useState(false);

  const [usedTables, setUsedTables] = useState([]);
  const [isBusy, setIsBusy] = useState(false);
  const [payType, setPayType] = useState("cash");
  const [cashChange, setCashChange] = useState({
    cash: 0,
    change: 0,
    visible: false,
    mode: "checkout",
  });

  useEffect(() => {
    if (selectedTransaction) {
      setTransaction(
        Object.keys(selectedTransaction).reduce((object, key) => {
          if (!["transaction_item", "row_index", "used_tables"].includes(key)) {
            object[key] =
              key === "tables" ? JSON.parse(selectedTransaction[key]) : selectedTransaction[key];
          }
          return object;
        }, {})
      );

      setPayType(selectedTransaction.payment_type);
      setTransactionItem(selectedTransaction.transaction_item);
      // setTableValue(JSON.parse(selectedTransaction.tables));
      setIsEdit(true);
    }
  }, [selectedTransaction]);

  useEffect(() => {
    if (!isLoading && occupiedTables) {
      setUsedTables(occupiedTables);
    }
  }, [isLoading, occupiedTables]);

  useEffect(() => {
    setCashChange({ ...cashChange, cash: transaction.total, change: 0 });
  }, [transaction]);
  const columns = [
    {
      title: "",
      dataIndex: "counter",
      width: 15,
      render: (text, record, index) => (
        <Text
          style={{
            fontSize: 10,
            color: "#8c8c8c",
          }}
        >
          {index + 1}.
        </Text>
      ),
    },
    {
      title: "Item",
      dataIndex: "Item",
      width: 120,
      // ellipsis: {
      //     showTitle: false
      // },
      render: (_, record) => <Text style={{ color: "#002766" }}>{record.item_name}</Text>,
    },

    {
      title: "Qty",
      dataIndex: "qty",
      width: 45,
      align: "center",
      render: (_, record) => <Text>{record.qty}</Text>,
    },

    {
      title: "Price",
      dataIndex: "price",
      align: "right",
      render: (_, record) => <Text>{record.price}</Text>,
    },
    {
      title: "Total",
      dataIndex: "total_price",
      align: "right",
      render: (_, record) => <Text>{record.total_price}</Text>,
    },

    {
      title: "",
      dataIndex: "actions",
      width: 20,
      render: (text, record, index) => (
        <RightOutlined
          style={{
            fontSize: 12,
            color: "#bfbfbf",
          }}
        />
      ),
    },
  ];

  const handleOnCancel = () => {
    dispatch(setTransactionDetails(null));
    navigate("/cashier");
  };

  const updateTransaction = (itemMode, data, oldData = null) => {
    console.log(data);
    let newItemSet = null;
    if (itemMode === "new") {
      newItemSet = [
        ...transactionItem,
        {
          transaction_id: transaction.transaction_id,
          item_id: data.item_id,
          item_name: data.item_name,
          price: data.price,
          qty: data.qty,
          total_price: data.total_price,
          is_pax_counter: data.is_pax_counter,
          pax_multiplier: data.pax_multiplier,
          time_stamp: data.time_stamp,
        },
      ];
    } else if (itemMode === "edit") {
      newItemSet = transactionItem.map((item) =>
        item.time_stamp === data.time_stamp
          ? {
              transaction_id: transaction.transaction_id,
              item_id: data.item_id,
              item_name: data.item_name,
              price: data.price,
              qty: data.qty,
              total_price: data.total_price,
              is_pax_counter: data.is_pax_counter,
              pax_multiplier: data.pax_multiplier,
              time_stamp: data.time_stamp,
            }
          : item
      );
    } else if (itemMode === "delete") {
      newItemSet = transactionItem.filter((item) => item.time_stamp !== data.time_stamp);
    }

    const { items, pax, total } = newItemSet.reduce(
      (acc, result) => {
        const paxResult = result.qty * result.pax_multiplier;
        acc.items += result.qty;
        acc.pax += result.is_pax_counter ? paxResult : 0;
        acc.total += result.total_price;
        return acc;
      },
      { items: 0, pax: 0, total: 0 }
    );
    setTransactionItem(newItemSet);
    setTransaction((prevData) => ({
      ...prevData,
      total,
      pax,
      items,
    }));
  };

  const handleTableSelection = (value) => {
    setTransaction((prevData) => ({
      ...prevData,
      tables: value,
      table_count: value.length,
    }));
  };

  const hasError = () => {
    if (transactionItem.length <= 0) {
      message.error("Item/s cannot be empty!");
      return true;
    } else if (transaction.tables.length <= 0) {
      message.error("Please select table!");
      return true;
    } else {
      return false;
    }
  };

  const handleSaveTransaction = () => {
    // console.log(transaction);
    // console.log(transactionItem);
    // console.log(usedTables);
    setIsBusy(true);

    if (hasError()) {
      setIsBusy(false);
      return false;
    }

    const finalData = {
      transaction,
      transactionItem,
    };
    mutationInsertTransaction.mutate(finalData, {
      onError: (error) => {
        message.error("Something went wrong :/");
        // An error happened!
        setIsBusy(false);
        console.log(`Error:  ${error.message}`);
      },
      onSuccess: (data) => {
        // Boom baby!
        // console.log(data);
        message.success("Transaction saved!");
        setIsBusy(false);
        navigate("/cashier");
      },
    });
  };

  const handleCashChange = (val) => {
    const newCash = Number(val) || 0;
    const newChange = newCash - transaction.total;
    setCashChange({ ...cashChange, cash: newCash, change: newChange });
  };

  const handleCashChangeOk = () => {
    handleUpdateTransaction(cashChange.mode);
  };
  const handleCashChangeCancel = () => {
    setCashChange({
      ...cashChange,
      visible: false,
      cash: transaction.total,
      change: 0,
    });
  };

  const handleUpdateTransaction = (mode) => {
    console.log(mode);
    setIsBusy(true);
    if (hasError()) {
      setIsBusy(false);
      return false;
    }

    let updatedTransaction = null;
    if (mode === "checkout") {
      const startDate = moment(transaction.start);
      const endDate = moment(userDetails.cutoff + moment().format(" HH:mm:ss"));
      const endDateFinal = moment(userDetails.cutoff + moment().format(" HH:mm:ss")).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      const durationMins = endDate.diff(startDate, "minutes");

      updatedTransaction = {
        ...transaction,
        status: "done",
        end: endDateFinal,
        duration: durationMins,
      };
    } else if (mode === "delete") {
      updatedTransaction = {
        ...transaction,
        status: "deleted",
      };
    } else {
      updatedTransaction = { ...transaction };
    }

    const finalData = {
      transaction: updatedTransaction,
      transactionItem,
    };

    mutationUpdateTransaction.mutate(finalData, {
      onError: (error) => {
        message.error("Something went wrong :/");
        // An error happened!
        setIsBusy(false);
        console.log(`Error:  ${error.message}`);
      },
      onSuccess: (data) => {
        // Boom baby!

        // console.log(data);
        // if (mode === "checkout") { setCashChange({...cashChange, visible : false})}
        message.success(`${upperCase(mode)} successful!`);
        setIsBusy(false);
        navigate("/cashier");
      },
    });
  };

  const handleSimpleUpdateTransaction = (mode) => {
    setIsBusy(true);
    mutationUpdateTransactionBasic.mutate(
      { transaction_id: transaction.transaction_id, status: mode },
      {
        onError: (error) => {
          message.error("Something went wrong :/");
          // An error happened!
          setIsBusy(false);
          console.log(`Error:  ${error.message}`);
        },
        onSuccess: (data) => {
          // Boom baby!
          // console.log(data);
          message.success(`Update successful!`);
          socket.emit("updateTable", { room: "gogi" });
          setIsBusy(false);
          navigate("/cashier");
        },
      }
    );
  };

  const handlePayTypeChange = (value) => {
    setPayType(value);
    setTransaction({
      ...transaction,
      payment_type: value,
    });
  };

  const handlePrint = () => {
    const printData = { ...userDetails, data: { transaction, transactionItem } };
    socket.emit("printTransaction", { ...printData });

    // console.log(printData);
  };
  return (
    <>
      <Spin tip={isEdit ? "Saving Transaction...." : "Updating Transaction"} spinning={isBusy}>
        <Card bodyStyle={{ padding: "12px 14px" }} size="small" title="Transaction Form">
          <Divider
            plain
            style={{
              margin: 6,
              marginBottom: 12,
            }}
          >
            <Space>
              Transaction Details
              {transaction.status === "done" ? (
                <Tag color="red">
                  <LockOutlined /> Locked
                </Tag>
              ) : transaction.status === "deleted" ? (
                <Tag>Deleted</Tag>
              ) : isEdit ? (
                <Tag color="#f50">Edit</Tag>
              ) : (
                <Tag color="#108ee9">New</Tag>
              )}
            </Space>
          </Divider>
          <Descriptions size="small" bordered column={3}>
            <Descriptions.Item
              label={<b>#</b>}
              // labelStyle={{ width: 50 }}
              style={{
                fontSize: 12,
                padding: 4,
                textAlign: "center",
              }}
            >
              {selectedTransaction ? selectedTransaction.row_index : "-"}
            </Descriptions.Item>
            <Descriptions.Item
              label={<b>Trans#</b>}
              // labelStyle={{ width: 50 }}
              style={{
                fontSize: 12,
                padding: 4,
                textAlign: "center",
              }}
            >
              {transaction.transaction_id}
            </Descriptions.Item>
            <Descriptions.Item
              label={<b>Status</b>}
              // labelStyle={{ width: 50 }}
              style={{
                fontSize: 12,
                padding: 4,
                textAlign: "center",
              }}
            >
              {isEdit ? titleCase(transaction.status) : "-"}
            </Descriptions.Item>
          </Descriptions>

          <div
            style={{
              ...customStyles.spaceBetween,
              marginTop: 6,
              padding: "4px 6px",
            }}
          >
            <Space style={{ fontSize: 12 }}>
              {isEdit ? (
                <>
                  <Text>
                    <UsergroupAddOutlined style={{ color: "#40a9ff" }} /> {transaction.pax}
                  </Text>
                  <Text>
                    <RightCircleOutlined
                      style={{
                        color: "#40a9ff",
                      }}
                    />{" "}
                    S-
                    {moment(transaction.start).format("h:mma")}
                  </Text>
                </>
              ) : (
                <Text>
                  <UsergroupAddOutlined
                    style={{
                      color: "#40a9ff",
                      marginRight: 10,
                    }}
                  />
                  Pax -{transaction.pax}
                </Text>
              )}

              {transaction.status === "started" ? (
                isEdit ? (
                  <Text>
                    <ClockCircleOutlined style={{ color: "#f5222d" }} /> EE-
                    {moment(transaction.start)
                      .add(userDetails.dine_in_mins, "minutes")
                      .format("h:mma")}
                  </Text>
                ) : null
              ) : (
                <>
                  <Text>
                    <LeftCircleOutlined style={{ color: "#40a9ff" }} /> E-
                    {transaction.end && transaction.end !== "Invalid date"
                      ? moment(transaction.end).format("h:mma")
                      : "-"}
                  </Text>
                  <Text>
                    <ClockCircleOutlined style={{ color: "#f5222d" }} /> ST-
                    {hoursFormatter(transaction.duration, 1)}
                  </Text>
                </>
              )}

              {/* <Text>
                            <ClockCircleOutlined style={{ color: "#f5222d" }} />{" "}
                            Est-2:30pm
                        </Text> */}
            </Space>
            <Button
              type="primary"
              size="small"
              // shape="circle"
              icon={<PlusOutlined />}
              onClick={() => {
                setFormItemParams({
                  visibility: true,
                  data: null,
                  isEdit: false,
                });

                // console.log(transactionItem);
                // console.log(JSON.stringify(tableValue));
              }}
              disabled={transaction.status === "started" ? false : true}
            >
              Item
            </Button>
          </div>

          <Divider orientation="left" plain style={{ margin: 6 }}>
            Items
          </Divider>
          <Table
            dataSource={transactionItem}
            rowKey="time_stamp"
            bordered={false}
            columns={columns}
            size="small"
            pagination={false}
            style={{ marginBottom: 12 }}
            summary={(pageData) => {
              let i = 0;
              let t = 0;
              pageData.forEach(({ qty, total_price }) => {
                i += qty;
                t += total_price;
              });
              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0} />
                    <Table.Summary.Cell index={1}>
                      <b>Total</b>
                    </Table.Summary.Cell>

                    <Table.Summary.Cell index={2} align="center">
                      <Text>{i}</Text>
                    </Table.Summary.Cell>

                    {/* <Table.Summary.Cell index={3} /> */}
                    <Table.Summary.Cell colSpan={2} index={4} align="right">
                      <Text> &#8369;{formatNumber(t)}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={5} />
                  </Table.Summary.Row>
                </>
              );
            }}
            onRow={(record) => {
              return {
                onClick: (event) => {
                  setFormItemParams({
                    visibility: true,
                    data: record,
                    isEdit: true,
                  });
                },
              };
            }}
          />

          {/* <Divider orientation="left" plain style={{ margin: 6 }}>
                    Tables
                </Divider> */}

          <Row gutter={16}>
            <Col
              span={6}
              style={{
                paddingTop: 6,
                paddingLeft: 30,
              }}
            >
              Tables
            </Col>
            <Col span={18}>
              <TableSelection
                tableValue={transaction.tables}
                onSelection={handleTableSelection}
                usedTables={usedTables}
                setUsedTables={setUsedTables}
                occupiedTables={usedTables}
                disabled={transaction.status === "started" ? false : true}
              />
            </Col>

            <Col
              span={6}
              style={{
                paddingTop: 6,
                paddingLeft: 30,
                marginTop: 8,
              }}
            >
              PayType
            </Col>
            <Col
              span={18}
              style={{
                marginTop: 8,
              }}
            >
              <Select
                size="large"
                style={{ width: "100%" }}
                value={payType}
                disabled={transaction.status === "started" ? false : true}
                onChange={handlePayTypeChange}
              >
                <Option value="cash">Cash</Option>
                <Option value="gcash">Gcash</Option>
              </Select>
            </Col>
          </Row>

          <Row style={{ marginTop: 10 }}></Row>

          <Divider orientation="left" plain style={{ margin: 6 }}>
            Actions
          </Divider>

          <div style={{ textAlign: "center" }}>
            {transaction.status === "deleted" ? (
              <Row gutter={5}>
                {userDetails.role_id === 2 ? (
                  <>
                    <Col span={12}>
                      <Button icon={<PrinterOutlined />} block>
                        Print
                      </Button>
                    </Col>

                    <Col span={12}>
                      <Button icon={<CloseOutlined />} onClick={handleOnCancel} block>
                        Close
                      </Button>
                    </Col>
                  </>
                ) : (
                  <>
                    <Col span={8}>
                      <Button icon={<PrinterOutlined />} block>
                        Print
                      </Button>
                    </Col>
                    <Col span={8}>
                      <Button
                        type="primary"
                        ghost
                        icon={<UndoOutlined />}
                        block
                        onClick={() => handleSimpleUpdateTransaction("started")}
                      >
                        Restore
                      </Button>
                    </Col>
                    <Col span={8}>
                      <Button icon={<CloseOutlined />} onClick={handleOnCancel} block>
                        Close
                      </Button>
                    </Col>
                  </>
                )}
              </Row>
            ) : (
              <>
                <Row gutter={5}>
                  <Col span={8}>
                    <Button
                      type="primary"
                      icon={<SaveOutlined />}
                      block
                      onClick={(e) => {
                        isEdit ? handleUpdateTransaction("update") : handleSaveTransaction();
                      }}
                      disabled={transaction.status === "done" ? true : false}
                    >
                      {isEdit ? "Update" : "Save"}
                    </Button>
                  </Col>
                  <Col span={8}>
                    <Button
                      icon={<PrinterOutlined />}
                      block
                      onClick={handlePrint}
                      disabled={!isEdit}
                    >
                      Print
                    </Button>
                  </Col>
                  <Col span={8}>
                    <Button icon={<CloseOutlined />} onClick={handleOnCancel} block danger>
                      {transaction.status === "done" ? "Close" : "Cancel"}
                    </Button>
                  </Col>
                </Row>

                <Button
                  icon={<CheckOutlined />}
                  type="primary"
                  ghost
                  size="large"
                  shape="round"
                  disabled={!isEdit || transaction.status === "done" ? true : false}
                  block
                  onClick={(e) => {
                    setCashChange({ ...cashChange, visible: true });
                  }}
                  style={{ marginTop: 10, marginBottom: 10 }}
                >
                  CheckOut
                </Button>

                {isEdit && userDetails.role_id !== 2 ? (
                  <Row gutter={5} style={{ marginTop: 10, marginBottom: 10 }}>
                    <Col span={4}>
                      <Button
                        type="dashed"
                        danger
                        block
                        icon={<DeleteOutlined />}
                        onClick={() => handleSimpleUpdateTransaction("deleted")}
                      />
                      {/* Delete
                      </Button> */}
                    </Col>
                    <Col span={20}>
                      <Button
                        type="dashed"
                        ghost
                        danger
                        icon={<UnlockOutlined />}
                        block
                        onClick={() => handleSimpleUpdateTransaction("started")}
                        disabled={transaction.status === "done" ? false : true}
                      >
                        Unlock
                      </Button>
                    </Col>
                  </Row>
                ) : null}
              </>
            )}
          </div>

          <FormItem
            formItemParams={formItemParams}
            setFormItemParams={setFormItemParams}
            onSave={updateTransaction}
          />
        </Card>
        <Modal
          title={
            <>
              <CheckOutlined style={{ color: "green" }} /> Check-Out.
            </>
          }
          visible={cashChange.visible}
          closable={true}
          // onOk={handleCashChangeOk}
          // onCancel={handleCashChangeCancel}
          footer={[
            <Button
              key="cancel"
              loading={isBusy}
              onClick={handleCashChangeCancel}
              size="large"
              icon={<CloseOutlined />}
            >
              Cancel
            </Button>,
            <Button
              key="back"
              type="primary"
              onClick={handleCashChangeOk}
              loading={isBusy}
              size="large"
              style={{ width: 80 }}
              icon={<CheckOutlined />}
            >
              Ok
            </Button>,
          ]}
        >
          <Descriptions bordered column={1}>
            <Descriptions.Item
              label={<b>Pay Type</b>}
              // labelStyle={{ width: 50 }}
            >
              {upperCase(transaction.payment_type)}
            </Descriptions.Item>
            <Descriptions.Item
              label={<b>Amount</b>}
              // labelStyle={{ width: 50 }}
            >
              &#8369;{formatNumber(transaction.total)}
            </Descriptions.Item>
            <Descriptions.Item
              label={<b>Cash</b>}
              // labelStyle={{ width: 50 }}
            >
              <InputNumber
                value={cashChange.cash}
                onChange={handleCashChange}
                size="large"
                style={{ width: 100 }}
              />
            </Descriptions.Item>
            <Descriptions.Item
              label={<b>Change</b>}
              // labelStyle={{ width: 50 }}
            >
              &#8369;{formatNumber(cashChange.change)}
            </Descriptions.Item>
          </Descriptions>
        </Modal>
      </Spin>
    </>
  );
};

export default Transaction;
