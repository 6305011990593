import React from "react";
import { Progress, Space } from "antd";

import Line from "@ant-design/plots/es/components/line";
import moment from "moment";

const Statistics = ({
  cutoff,
  stat = [],
  group,
  statHeight = 120,
  circleWidth = 80,
}) => {
  const config = {
    data: stat,
    height: statHeight,
    xField: "time",
    yField: "pax",
    // yAxis: {
    //   minLimit: 20,
    // },
    xAxis: {
      // max: 14,
      label: {
        formatter: (v) =>
          moment(
            cutoff + " " + v + ":00:00"
          ).format("ha"),
      },
    },
    point: {
      size: 5,
      shape: "circle",
    },
    tooltip: {
      domStyles: {
        "g2-tooltip-title": { display: "none" },
      },
      formatter: (data) => {
        return {
          name: `Time - ${moment(
            cutoff + " " + data.time + ":00:00"
          ).format("ha")}`,
          value: `Pax - ${data.pax}`,
        };
      },
    },
  };
  return (
    <div style={{ textAlign: "center" }}>
      <Line {...config} />
      <Space
        align="center"
        style={{
          marginTop: 10,
          marginBottom: 10,
        }}
      >
        <Progress
          type="circle"
          strokeColor="#096dd9"
          percent={
            (group.started / group.total) * 100
          }
          width={circleWidth}
          format={() => (
            <>
              <span
                style={{
                  fontSize: 13,
                  color: "#096dd9",
                }}
              >
                Started
              </span>
              <br></br>
              <span style={{ color: "#096dd9" }}>
                {group.started || "0"}
              </span>
            </>
          )}
        />

        <Progress
          type="circle"
          percent={
            (group.done / group.total) * 100
          }
          width={circleWidth}
          strokeColor="#bfbfbf"
          format={(percent) => (
            <>
              <span
                style={{
                  fontSize: 13,
                  color: "#bfbfbf",
                }}
              >
                Done
              </span>
              <br></br>
              <span
                style={{
                  color: "#bfbfbf",
                }}
              >
                {group.done || "0"}
              </span>
            </>
          )}
        />
        <Progress
          type="circle"
          percent={100}
          width={circleWidth}
          format={(percent) => (
            <>
              <span style={{ fontSize: 13 }}>
                Total
              </span>
              <br></br>
              <span>{group.total || "0"}</span>
            </>
          )}
        />
        <Progress
          type="circle"
          percent={100}
          width={circleWidth}
          status="exception"
          format={() => (
            <>
              <span style={{ fontSize: 13 }}>
                Deleted
              </span>
              <br></br>
              <span>{group.deleted || "0"}</span>
            </>
          )}
        />
      </Space>
    </div>
  );
};

export default Statistics;
