import React, { useState } from "react";
import { Card, Form, Input, Button, Divider, message, Spin } from "antd";
import { useMutationStoreIP } from "services/hooks/api/settingsAPi";
const Settings = () => {
  const [ipForm] = Form.useForm();
  const [isBusy, setIsBusy] = useState(false);
  const mutationStoreIP = useMutationStoreIP();

  const ipFormOnFinish = () => {
    const ip_address = ipForm.getFieldValue("ip_address");
    setIsBusy(true);
    mutationStoreIP.mutate(
      { ip_address },
      {
        onError: (error) => {
          message.error("Something went wrong :/");
          // An error happened!
          console.log(`Error:  ${error.message}`);
          setIsBusy(false);
        },
        onSuccess: (data) => {
          message.success(data.message);
          ipForm.resetFields();
          setIsBusy(false);
        },
      }
    );
  };

  return (
    <Card>
      <Form
        form={ipForm}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        // initialValues={{ remember: true }}
        size="large"
        onFinish={ipFormOnFinish}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Internet Address"
          name="ip_address"
          extra="IP Address format example 123.456.789.111"
          rules={[
            {
              required: true,
              message: "Internet Address Required!",
            },
            {
              pattern:
                /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
              message: "Please enter a valid channel ID",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item wrapperCol={{ span: 16 }}>
          <Button type="primary" htmlType="submit" block>
            Add Internet Address
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default Settings;
