import React from "react";

//   import PageTitle from "common/PageTitle";
import PrepArea from "pages/private/PrepArea/Index";
import InventoryEntry from "pages/private/InventoryEntry/Index";
import Cashier from "pages/private/Cashier/Index";
import Transaction from "pages/private/Transaction/Index";
import TransactionSummary from "pages/private/TransactionSummary/Index";
import Expense from "pages/private/Expense/Index";
import Admin from "pages/private/Admin/Index";
import Inventory from "pages/private/Inventory/Index";
import Items from "pages/private/Items/Index";
import BranchInventory from "pages/private/BranchInventory/Index";
import InventoryTransfer from "pages/private/InventoryTransfer/Index";
import Settings from "pages/private/Settings/Index";
import SalesSummary from "pages/private/SalesSummary/Index";
import KitchenInventory from "pages/private/KitchenInventory/Index";
import Delivery from "pages/private/Delivery/Index";
import Users from "pages/private/Users/Index";

const r = [
  {
    path: "/prep-area",
    component: <PrepArea />,
    pageTitle: {
      title: "",
      subTitle: "",
    },
    restriction: [3, 4],
  },
  {
    path: "/inventory-entry",
    component: <InventoryEntry />,
    pageTitle: {
      title: "",
      subTitle: "",
    },
    restriction: [3],
  },
  {
    path: "/cashier",
    component: <Cashier />,
    pageTitle: {
      title: "",
      subTitle: "",
    },
    restriction: [1, 2, 5],
  },
  {
    path: "/transaction",
    component: <Transaction />,
    pageTitle: {
      title: "",
      subTitle: "",
    },
    restriction: [1, 2, 5],
  },

  {
    path: "/transaction-summary",
    component: <TransactionSummary />,
    pageTitle: {
      title: "",
      subTitle: "",
    },
    restriction: [1, 2, 5],
  },
  {
    path: "/expense",
    component: <Expense />,
    pageTitle: {
      title: "",
      subTitle: "",
    },
    restriction: [1, 2, 5],
  },
  {
    path: "/admin-page",
    component: <Admin />,
    pageTitle: {
      title: "",
      subTitle: "",
    },
    restriction: [1, 5],
  },
  {
    path: "/inventory",
    component: <Inventory />,
    pageTitle: {
      title: "",
      subTitle: "",
    },
    restriction: [1, 3, 4, 5],
  },
  {
    path: "/items",
    component: <Items />,
    pageTitle: {
      title: "",
      subTitle: "",
    },
    restriction: [1, 3, 4, 5],
  },
  {
    path: "/branch-inventory",
    component: <BranchInventory />,
    pageTitle: {
      title: "",
      subTitle: "",
    },
    restriction: [1, 3, 4, 5],
  },
  {
    path: "/inventory-transfer",
    component: <InventoryTransfer />,
    pageTitle: {
      title: "",
      subTitle: "",
    },
    restriction: [1, 3, 4, 5],
  },
  {
    path: "/settings",
    component: <Settings />,
    pageTitle: {
      title: "",
      subTitle: "",
    },
    restriction: [1, 3, 4, 5],
  },
  {
    path: "/sales-summary",
    component: <SalesSummary />,
    pageTitle: {
      title: "",
      subTitle: "",
    },
    restriction: [1, 3, 4, 5],
  },
  {
    path: "/kitchen-inventory",
    component: <KitchenInventory />,
    pageTitle: {
      title: "",
      subTitle: "",
    },
    restriction: [1, 3, 4, 5],
  },
  {
    path: "/delivery",
    component: <Delivery />,
    pageTitle: {
      title: "",
      subTitle: "",
    },
    restriction: [1, 3, 4, 5],
  },
  {
    path: "/users",
    component: <Users />,
    pageTitle: {
      title: "",
      subTitle: "",
    },
    restriction: [1, 3, 4, 5],
  },
];

let privateRoutes = [...r];

// privateRoutes = privateRoutes.map(
//   (privateRoute) => {
//     // const pageTitle = privateRoute.pageTitle;

//     return {
//       ...privateRoute,
//       component: (
//         <div
//           style={{
//             marginTop: 4,
//             // marginTop: pageTitle ? 0 : 16
//           }}
//         >
//           {/* {pageTitle && <PageTitle {...pageTitle} />} */}
//           <PageContent>
//             <privateRoute.component />
//           </PageContent>
//         </div>
//       ),
//     };
//   }
// );

export default privateRoutes;
