import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ReactQueryDevtools } from "react-query/devtools";
import { useSelector } from "react-redux";
import Main from "./layouts/Main";
import publicRoutes from "./routes/public";
import privateRoutes from "./routes/private";
import PageNotFound from "./pages/public/PageNotFound";

import config from "./config.json";

const App = () => {
  // set restriction to routes
  const { role_id } = useSelector((state) => state.User);

  return (
    <>
      <Router basename={config.appBasePath}>
        <Routes>
          {publicRoutes.map((publicRoute, index) => (
            <Route key={index} exact path={publicRoute.path} element={publicRoute.component} />
          ))}

          <Route path="/" element={<Main />}>
            {privateRoutes.map((privateRoute, index) => {
              // console.log(
              //   privateRoute.restriction.includes(role_id),
              //   privateRoute.restriction,
              //   privateRoute.path,
              //   role_id
              // );
              return (
                <Route
                  key={index}
                  exact={privateRoute.isExact === undefined ? true : privateRoute.isExact}
                  path={privateRoute.path}
                  element={privateRoute.component}
                  title={privateRoute.title}
                />
              );

              // if (privateRoute.restriction.includes(role_id)) {
              //   return (
              //     <Route
              //       key={index}
              //       exact={privateRoute.isExact === undefined ? true : privateRoute.isExact}
              //       path={privateRoute.path}
              //       element={privateRoute.component}
              //       title={privateRoute.title}
              //     />
              //   );
              // }
            })}
          </Route>

          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Router>
      <ReactQueryDevtools initialOpen={false} />
    </>
  );
};

export default App;
