import React, { useState, useEffect } from "react";

import {
  Table,
  Card,
  Space,
  Input,
  Tooltip,
  Button,
  Row,
  Col,
  Typography,
  Tag,
  Switch,
} from "antd";
import {
  LoadingOutlined,
  PlusOutlined,
  SearchOutlined,
  RedoOutlined,
  CheckOutlined,
  CloseOutlined,
  UserAddOutlined,
} from "@ant-design/icons";

import { useGetItems } from "services/hooks/api/itemsAPI";
import { formatNumber } from "helpers/numberFormatter";

import FormItemMaster from "./components/FormItemMaster";

import RetryMessage from "common/RetryMessage";
const defaultValue = {
  visible: false,
  isEdit: false,
  item: null,
};

const { Text } = Typography;
const Items = () => {
  const [searchValue, setSearchValue] = useState(null);
  const [items, setItems] = useState([]);
  const [itemsTemp, setItemsTemp] = useState([]);
  const [filter, setFilters] = useState([]);
  const [filter2, setFilters2] = useState([]);
  const [otherData, setOtherData] = useState({ category: [], branch: [], unit: [], group: [] });
  const [formItemParams, setFormItemParams] = useState(defaultValue);
  const { isLoading, data, refetch, error, isError } = useGetItems({ searchValue: null });

  useEffect(() => {
    if (!isLoading && data) {
      setItems(data.items);
      setItemsTemp(data.items);
      setOtherData(data.otherData);
    }
  }, [isLoading, data]);

  const columns = [
    {
      title: "Item",
      key: "status",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record, index) => (
        <Text>
          {record.status === "active" ? (
            <CheckOutlined style={{ color: "green", fontSize: 9 }} />
          ) : (
            <CloseOutlined style={{ color: "red", fontSize: 9 }} />
          )}{" "}
          {record.item_name} <br></br>
          {record.unit_id && record.category_id === 2 && (
            <Tag style={{ fontSize: 10, padding: 0, paddingLeft: 4, paddingRight: 4 }}>
              {record.unit}
            </Tag>
          )}
          {record.group_id && record.category_id === 2 && (
            <Tag
              color="magenta"
              style={{ fontSize: 10, padding: 0, paddingLeft: 4, paddingRight: 4 }}
            >
              {record.group_name}
            </Tag>
          )}
          {record.category_id === 1 && (
            <Text italic style={{ fontSize: 10, paddingLeft: 12, color: "#096dd9" }}>
              {record.is_pax_counter ? <UserAddOutlined style={{ color: "#fa541c" }} /> : null}{" "}
              {record.item_description}
            </Text>
          )}
        </Text>
      ),
      filters: [
        {
          text: "Active",
          value: "active",
        },
        {
          text: "Inactive",
          value: "inactive",
        },
      ],
      defaultFilteredValue: filter2, //[],
      onFilter: (value, record) => record.status.indexOf(value) === 0,
    },
    {
      title: "Category",
      key: "category",
      width: 90,
      render: (text, record, index) => record.category,
      filters: [
        {
          text: "Package",
          value: "Package",
        },
        {
          text: "Item",
          value: "Item",
        },
      ],
      defaultFilteredValue: filter, //[],
      onFilter: (value, record) => record.category.indexOf(value) === 0,
    },
    {
      title: "U/Price",
      key: "selling_price",
      align: "right",
      width: 80,
      render: (text, record) => (
        // record.is_salable ? (
        <span>
          {record.category_id === 1 || record.is_salable ? (
            <span style={{ color: "#10239e" }}> &#8369;{formatNumber(record.selling_price)}</span>
          ) : (
            <span style={{ color: "#cf1322" }}> &#8369;{formatNumber(record.unit_price)}</span>
          )}
        </span>
      ),
      // ) : (
      //   "--"
      // ),
    },
  ];

  const handleSearchOnChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    setItemsTemp(items.filter((obj) => obj.item_name.toLowerCase().includes(value.toLowerCase())));
  };

  const handleRowclick = (item) => {
    console.log(item);
    setFormItemParams({ visible: true, item, isEdit: true });
  };

  const handleNew = () => {
    setFormItemParams({ visible: true, item: null, isEdit: false });
  };

  if (isError) {
    return (
      <Card>
        <RetryMessage message={error.message} onRetry={() => refetch()} />
      </Card>
    );
  }

  const handleTableOnChange = (_, filter) => {
    setFilters(filter.category || []);
    setFilters2(filter.status || []);
  };

  const handleRefresh = () => {
    refetch();

    setSearchValue(null);
    setItems(data.items);
  };

  if (data && !isError) {
    return (
      <Card bodyStyle={{ padding: "20px 14px" }}>
        <Row justify="space-between">
          <Col>
            {" "}
            <Input
              placeholder="Search item name..."
              size="large"
              allowClear
              style={{ paddingLeft: 20, width: 220 }}
              // onPressEnter={handlesOnPressEnter}
              onChange={handleSearchOnChange}
              value={searchValue}
              prefix={
                <Space>
                  <SearchOutlined style={{ marginRight: 12 }} />
                </Space>
              }
              suffix={isLoading && <LoadingOutlined />}
            />
          </Col>
          <Col>
            {" "}
            <Space style={{ marginTop: 5 }}>
              <Tooltip title="Add New Card">
                <Button type="primary" shape="circle" icon={<PlusOutlined />} onClick={handleNew} />
              </Tooltip>
              <Tooltip title="Refresh">
                <Button
                  type="dashed"
                  shape="circle"
                  icon={<RedoOutlined />}
                  onClick={handleRefresh}
                />
              </Tooltip>
            </Space>
          </Col>
        </Row>

        <Table
          loading={isLoading}
          rowKey="item_id"
          dataSource={itemsTemp}
          columns={columns}
          style={{ marginTop: 10 }}
          size="small"
          onRow={(record, rowIndex) => {
            return {
              onClick: (e) => {
                handleRowclick(record);
              }, // click row
            };
          }}
          onChange={handleTableOnChange}
        />

        <FormItemMaster
          params={formItemParams}
          setParams={setFormItemParams}
          otherData={otherData}
          setItems={setItems}
          setItemsTemp={setItemsTemp}
        />
      </Card>
    );
  }
};

export default Items;
