import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Layout, Row, Col, Avatar, Modal, Typography, Tag, Space, Button } from "antd";
import {
  UserOutlined,
  LoadingOutlined,
  CalendarOutlined,
  DashboardOutlined,
  LeftOutlined,
  SnippetsOutlined,
  DropboxOutlined,
  BlockOutlined,
  SettingOutlined,
  AppstoreAddOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";

import Sider from "./Sider";
import moment from "moment";
import { useLogout } from "services/hooks/api/authApi";
import config from "../config.json";
import { toggleSiderInDrawerCollapse } from "services/redux/slices/app";
import { useIPChecker } from "services/hooks/api/authApi";

const { Title, Text } = Typography;
const { Header } = Layout;
const AppHeader = () => {
  const [isLogoutLoadingVisible, setIsLogoutLoadingVisible] = useState(false);
  const { firstname, branch_name, cutoff, role_id, inventory_cutoff } = useSelector(
    (state) => state.User
  );
  const logoutResponse = useLogout({
    enabled: isLogoutLoadingVisible,
  });
  const ipCheckerResponse = useIPChecker({ enabled: true });

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (ipCheckerResponse.isSuccess && role_id !== 1) {
      const { data: ipCheckerData } = ipCheckerResponse;
      if (ipCheckerData.error) {
        console.log(ipCheckerData.ip, ipCheckerData.message);
        navigate(`/ip-chekcer-error/${ipCheckerData.ip}/${ipCheckerData.message}`);
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ipCheckerResponse.isSuccess]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (logoutResponse.isSuccess) {
      // setTimeout(() => {
      //   navigate("/login");
      // }, 3000);

      if (config.platform === "web") {
        window.location = `${config.appBasePath}/`;
      } else {
        window.location.reload();
      }
    }
  }, [logoutResponse.isSuccess]);

  const handlesBackButton = () => {
    navigate(-1);
  };
  return (
    <>
      <Modal
        visible={isLogoutLoadingVisible}
        footer={null}
        closable={false}
        width={300}
        style={{ zIndex: 999999 }}
      >
        <div style={{ textAlign: "center" }}>
          <LoadingOutlined /> Logging out. Please wait ...
        </div>
      </Modal>
      <Header
        style={{
          position: "fixed",
          zIndex: 99,
          width: "100vw",
          padding: "0px 16px",
          boxShadow: "0 1px 4px rgba(0,21,41,.08)",
          backgroundColor: "white",
          height:
            location &&
            [
              "/admin-page",
              "/items",
              "/branch-inventory",
              "/inventory-transfer",
              "/settings",
              "/delivery",
              "/users",
            ].includes(location.pathname)
              ? 60
              : role_id !== 3 //&& role_id !== 4
              ? 80
              : 60,

          //         ["/admin-page","/items","/branch-inventory","/inventory-transfer", "/settings"]
          // (location && location.pathname === "/admin-page") ||
          // location.pathname === "/items" ||
          // location.pathname === "/branch-inventory" ||
          // location.pathname === "/inventory-transfer" ||
          // location.pathname === "/settings"
        }}
      >
        <Row type="flex" justify="space-between">
          <Col>
            <Space>
              {location && location.pathname === "/admin-page" ? (
                <Title level={4} style={{ marginTop: 15 }}>
                  <DashboardOutlined /> Dashboard
                </Title>
              ) : location && location.pathname === "/items" ? (
                <Title level={4} style={{ marginTop: 15 }}>
                  <LeftOutlined
                    style={{ fontSize: 25, color: "#1890ff" }}
                    onClick={handlesBackButton}
                  />{" "}
                  <SnippetsOutlined /> Items
                </Title>
              ) : location && location.pathname === "/branch-inventory" ? (
                <Title level={4} style={{ marginTop: 15 }}>
                  <LeftOutlined
                    style={{ fontSize: 25, color: "#1890ff" }}
                    onClick={handlesBackButton}
                  />{" "}
                  <DropboxOutlined /> Branch Inventory
                </Title>
              ) : location && location.pathname === "/inventory-transfer" ? (
                <Title level={4} style={{ marginTop: 15 }}>
                  <LeftOutlined
                    style={{ fontSize: 25, color: "#1890ff" }}
                    onClick={handlesBackButton}
                  />{" "}
                  <BlockOutlined /> Inventory Transfer
                </Title>
              ) : location && location.pathname === "/settings" ? (
                <Title level={4} style={{ marginTop: 15 }}>
                  <LeftOutlined
                    style={{ fontSize: 25, color: "#1890ff" }}
                    onClick={handlesBackButton}
                  />{" "}
                  <SettingOutlined /> Settings
                </Title>
              ) : location && location.pathname === "/delivery" ? (
                <Title level={4} style={{ marginTop: 15 }}>
                  <LeftOutlined
                    style={{ fontSize: 25, color: "#1890ff" }}
                    onClick={handlesBackButton}
                  />{" "}
                  <AppstoreAddOutlined /> Delivery
                </Title>
              ) : location && location.pathname === "/users" ? (
                <Title level={4} style={{ marginTop: 15 }}>
                  <LeftOutlined
                    style={{ fontSize: 25, color: "#1890ff" }}
                    onClick={handlesBackButton}
                  />{" "}
                  <UsergroupAddOutlined /> Users
                </Title>
              ) : (
                <Space style={{ paddingTop: 10 }} size={20}>
                  <LeftOutlined
                    style={{ fontSize: 25, color: "#1890ff" }}
                    onClick={handlesBackButton}
                  />

                  <Title level={5}>
                    GOGI - {branch_name} {role_id === 3 ? null : <br />}
                    <Tag
                      style={{
                        fontSize: 16,
                        padding: 4,
                      }}
                    >
                      {location && location.pathname === "/inventory" ? (
                        <>Inventory Summary</>
                      ) : location &&
                        ["/prep-area", "/kitchen-inventory", "/delivery"].includes(
                          location.pathname
                        ) ? (
                        <Text style={{ fontSize: 13 }}>
                          Inventory CutOff <CalendarOutlined />{" "}
                          {moment(inventory_cutoff).format("MM/DD/YYYY")}
                        </Text>
                      ) : (
                        <>
                          <Text style={{ fontSize: 13 }}>
                            Cutoff <CalendarOutlined /> {moment(cutoff).format("MM/DD/YYYY")}
                          </Text>
                        </>
                      )}
                    </Tag>
                  </Title>
                </Space>
              )}
            </Space>
          </Col>
          <Col>
            <Space size={1}>
              <Button
                type="link"
                onClick={() => dispatch(toggleSiderInDrawerCollapse())}
                style={{ padding: 0, margin0: 0 }}
              >
                <Avatar
                  size="small"
                  icon={<UserOutlined />}
                  style={{
                    marginRight: 8,
                    backgroundColor: "#1890ff",
                  }}
                />
                {firstname}
              </Button>
            </Space>
          </Col>
        </Row>
      </Header>
      <Sider setIsLogoutLoadingVisible={setIsLogoutLoadingVisible} />
    </>
  );
};

export default AppHeader;
