export function hoursFormatter(minutes, format = 0) {
    //  0 - full , 1 - 1 letter , 3 - 3 letters

    const v = Number(minutes);
    const fixMin = 60;
    let m = 0;
    let h = 0;

    h = Math.floor(v / fixMin);
    m = v % fixMin;

    if (h > 0 && m > 0) {
        return format === 0
            ? `${h}hours ${m}minutes`
            : format === 3
            ? `${h}hrs ${m}min`
            : format === 1
            ? `${h}h ${m}m`
            : "Ivalid format code";
    } else if (h > 0 && m === 0) {
        return format === 0
            ? `${h}hours`
            : format === 3
            ? `${h}hrs`
            : format === 1
            ? `${h}h`
            : "Ivalid format code";
    } else if (h === 0 && m > 0) {
        return format === 0
            ? `${m}minutes`
            : format === 3
            ? `${m}min`
            : format === 1
            ? `${m}m`
            : "Ivalid format code";
    } else {
        return "-";
    }
}
