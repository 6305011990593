import { useQuery, useMutation } from "react-query";
import http from "services/httpService";

async function getBranchInventory({ queryKey }) {
  const { branch_id } = queryKey[1];
  const { data } = await http.get(`/branch-inventory/${branch_id}`);
  return data;
}
function useGetBranchInventory(data) {
  return useQuery(["branch-inventory", data], getBranchInventory);
}

async function getItemInventory({ queryKey }) {
  const { item_id, branch_id, inventory_cutoff } = queryKey[1];
  const { data: itemInventoryData } = await http.get(
    `/branch-inventory/item-inventory/${item_id}/${branch_id}/${inventory_cutoff}`
  );
  return itemInventoryData;
}
function useGetItemInventory(data) {
  return useQuery(["branch-item-inventory", data], getItemInventory);
}

// async function getSaleableItems() {
//   const { data } = await http.get(`/items/saleable-items`);
//   return data;
// }
// function useGetSaleableItems() {
//   return useQuery("saleable-items", getSaleableItems);
// }

// // ------------Mutation

async function storeBranchInventory({ data }) {
  const { data: storedInventoryData } = await http.post(`/branch-inventory`, data);
  return storedInventoryData;
}
function useMutationStoreBranchInventory() {
  return useMutation(storeBranchInventory);
}

async function updateBranchInventory({ item_id, branch_id, data }) {
  const { data: updatedBranchInventory } = await http.put(
    `/branch-inventory/update/${item_id}/${branch_id}`,
    data
  );
  return updatedBranchInventory;
}
function useMutationUpdateBranchInventory() {
  return useMutation(updateBranchInventory);
}

async function executeAction({ branch_id, inventory_cutoff, action_type }) {
  const { data: executeActionBranchInventory } = await http.put(
    `/branch-inventory/execute_action/${branch_id}/${inventory_cutoff}/${action_type}`
  );
  return executeActionBranchInventory;
}

function useMutationExecuteAction() {
  return useMutation(executeAction);
}

async function createItemInventory(data) {
  const { data: createItemInventoryData } = await http.post(
    `/branch-inventory/create-item-inventory`,
    data
  );
  return createItemInventoryData;
}

function useMutationCreateItemInventory() {
  return useMutation(createItemInventory);
}

async function updateItemInventory({ item_id, branch_id, inventory_cutoff, data }) {
  const { data: updateItemInventoryData } = await http.put(
    `/branch-inventory/update-item-inventory/${item_id}/${branch_id}/${inventory_cutoff}`,
    data
  );
  return updateItemInventoryData;
}

function useMutationUpdateItemInventory() {
  return useMutation(updateItemInventory);
}

export {
  useGetBranchInventory,
  useMutationStoreBranchInventory,
  useMutationUpdateBranchInventory,
  useMutationExecuteAction,
  useGetItemInventory,
  useMutationCreateItemInventory,
  useMutationUpdateItemInventory,
};
