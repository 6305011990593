import React, { useState, useEffect, useContext } from "react";
import { SocketContext } from "services/contexts/socket";
import {
  Descriptions,
  Card,
  Divider,
  Table,
  Button,
  Col,
  Row,
  Alert,
  DatePicker,
  InputNumber,
  Collapse,
  Modal,
  Spin,
  message,
} from "antd";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  CloseOutlined,
  PrinterOutlined,
  ScissorOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import moment from "moment";

import config from "../../../config.json";
import { formatNumber } from "helpers/numberFormatter";
import {
  useGetTransactionSummary,
  useMutationStoreCutOff,
  useMutationStoreDenomination,
} from "services/hooks/api/transactionAPI";

import { useLogout } from "services/hooks/api/authApi";

import Statistics from "common/Statistics";
import ContentLoader from "common/ContentLoader";
import RetryMessage from "common/RetryMessage";

const denominationDefault = {
  data: [
    { denomination: 1000, qty: 0, total: 0 },
    { denomination: 500, qty: 0, total: 0 },
    { denomination: 200, qty: 0, total: 0 },
    { denomination: 100, qty: 0, total: 0 },
    { denomination: 50, qty: 0, total: 0 },
    { denomination: 20, qty: 0, total: 0 },
    { denomination: 10, qty: 0, total: 0 },
    { denomination: 5, qty: 0, total: 0 },
    { denomination: 1, qty: 0, total: 0 },
  ],
  cash_on_hand: 0,
  cash_over: 0,
  cash_short: 0,
};
const { Panel } = Collapse;
const { confirm } = Modal;
const TransactionSummary = () => {
  const socket = useContext(SocketContext);
  const navigate = useNavigate();
  const { branch_id, cutoff, user_id, username, branch_name, print_server_id } = useSelector(
    (state) => state.User
  );
  const [cutOffDate, setCutOffDate] = useState(moment(cutoff));
  const { isLoading, data, isError, error, refetch } = useGetTransactionSummary({
    branch_id,
    cutoff: moment(cutOffDate).format("YYYY-MM-DD"),
  });

  const [denomination, setDenomination] = useState(denominationDefault);
  const [summaryTemp, setSummaryTemp] = useState(null);
  const [isBusy, setIsBusy] = useState(false);

  const mutationStoreCutOff = useMutationStoreCutOff();
  const mutationStoreDenomination = useMutationStoreDenomination();

  const [isLogoutLoadingVisible, setIsLogoutLoadingVisible] = useState(false);

  const logoutResponse = useLogout({
    enabled: isLogoutLoadingVisible,
  });

  useEffect(() => {
    if (!isLoading && data) {
      setSummaryTemp(data.summary);
      setDenomination(data.denomination_data || denominationDefault);

      // console.log(
      //   moment(cutOffDate).format("YYYY-MM-DD")
      // );
      // // console.log(cutoff);
      // // console.table(data.transGroup);
      console.table(data);
      // // console.table(data.items);
      // // console.table(data.stat[0]);
      // // console.table(data.expense);
    }
  }, [data, isLoading]);

  useEffect(() => {
    if (logoutResponse.isSuccess) {
      if (config.platform === "web") {
        window.location = `${config.appBasePath}/`;
      } else {
        window.location.reload();
      }
    }
  }, [logoutResponse.isSuccess]);

  const itemColumn = [
    {
      title: "Item",
      dataIndex: "item_name",
      // ellipsis: true,
      width: 140,
      fixed: "left",
    },
    {
      title: "Price",
      dataIndex: "price",
      width: 80,
      align: "right",
      render: (text, record) => <span>{formatNumber(record.price)}</span>,
    },
    {
      title: "Qty",
      dataIndex: "qty",
      align: "center",
    },

    {
      title: "Total",
      dataIndex: "total_price",
      align: "right",
      render: (text, record) => (
        <span>
          &#8369;
          {formatNumber(record.total_price)}
        </span>
      ),
    },
  ];

  const expenseColumn = [
    {
      title: "Details",
      dataIndex: "expense_details",
      ellipsis: true,
      // width: 120,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      align: "right",
      render: (text, record) => <span>&#8369;{formatNumber(record.amount)} </span>,
    },
  ];

  const denominationColumn = [
    {
      title: "Denomination",
      dataIndex: "denomination",
    },

    {
      title: "",
      dataIndex: "X",
      render: () => "X",
    },

    {
      title: "Qty",
      render: (text, record) => (
        <InputNumber
          size="small"
          min={0}
          value={record.qty}
          onChange={(value) => handlesQtyChange(value, record)}
        />
      ),
    },
    {
      title: "Total",
      key: "total",
      align: "right",
      render: (text, record) => formatNumber(record.total),
    },
  ];

  const handlesQtyChange = (value, record) => {
    const money = Number(record.denomination) || 0;
    const newQty = Number(value) || 0;
    const oldQty = Number(record.qty) || 0;

    const oldTotal = Number(record.total) || 0;
    const newTotal = newQty * money;

    console.log(oldQty, oldTotal, newQty, newTotal);

    const cashOnHand = denomination.cash_on_hand - oldTotal + newTotal;

    let cashOver = 0;
    let cashShort = 0;
    const calculatedOverShort = denomination.cash_on_hand - oldTotal + newTotal - summaryTemp.cash;

    if (calculatedOverShort < 0) {
      cashShort = calculatedOverShort * -1;
    } else if (calculatedOverShort > 0) {
      cashOver = calculatedOverShort;
    }

    // console.log(cashOnHand, cashOver, cashShort);

    setDenomination((prevState) => ({
      ...prevState,
      cash_on_hand: cashOnHand,
      cash_over: cashOver,
      cash_short: cashShort,
      data: prevState.data.map((item) => {
        if (item.denomination === record.denomination) {
          return {
            ...item,
            qty: newQty,
            total: newTotal,
          };
        } else {
          return item;
        }
      }),
    }));
  };

  if (isLoading) {
    return <ContentLoader />;
  }

  if (isError) {
    return (
      <Card>
        <RetryMessage message={error.message} onRetry={() => refetch()} />
      </Card>
    );
  }

  const handleDateChange = (date, dateString) => {
    setCutOffDate(date);
  };

  const handleCutOff = () => {
    confirm({
      title: "Warning",
      icon: <ExclamationCircleOutlined />,
      content: (
        <>
          <p>This will change the Current CutOff Date,</p>
          <p>This action is irreversible!</p>
          <p>You will be redirected to log out page after cutoff.</p>
          <p>Please make that, transaction and inventory are in place before performing cutoff</p>
          <p>
            <b>Are your sure?</b>
          </p>
        </>
      ),
      okText: "Yes I'm Sure",
      cancelText: "Not now",
      onOk() {
        setIsBusy(true);
        const finalData = {
          ...data.summary,
          cash_to_remit: data.summary.cash,
          cash_on_hand: denomination.cash_on_hand,
          cash_over: denomination.cash_over,
          cash_short: denomination.cash_short, // denomination_data: JSON.stringify(denomination),
          user_id,
          branch_id,
          cutoff,
        };
        // console.log(finalData);
        // setIsBusy(false);
        mutationStoreCutOff.mutate(finalData, {
          onError: (error) => {
            message.error("Something went wrong :/");
            // An error happened!
            setIsBusy(false);
            console.log(`Error:  ${error.message}`);
          },
          onSuccess: (data) => {
            // Boom baby!

            if (data.error) {
              setIsBusy(false);
              Modal.error({
                title: "CutOff Failed!",
                content: (
                  <div>
                    <p>{data.message}</p>
                    <p>Click Ok to Log-Out. </p>
                    <p>CutOff date will be automatically updated once you logged-in. </p>
                  </div>
                ),

                onOk() {
                  setIsLogoutLoadingVisible(true);
                },
              });
            } else {
              message.success(data.message);
              setIsBusy(false);
              setIsLogoutLoadingVisible(true);
            }
            // navigate("/cashier");
          },
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handlePrint = () => {
    const summaryData = Object.keys(data).reduce((object, key) => {
      if (!["cutoff", "stat", "transGroup"].includes(key)) {
        object[key] = data[key];
      }
      return object;
    }, {});

    const printData = {
      branch_id,
      cutoff: moment(cutOffDate).format("YYYY-MM-DD"),
      user_id,
      username,
      branch_name,
      print_server_id,
      ...summaryData,
      denomination,
    };
    socket.emit("printSummary", { ...printData });
    const finalData = { cutoff, branch_id, user_id, denomination };
    mutationStoreDenomination.mutate(finalData, {
      onError: (error) => {
        console.log(`Error:  ${error.message}`);
      },
      onSuccess: (data) => {
        console.log(data.message);
      },
    });
    // console.log(printData);
  };
  if (data && !isError) {
    const { transGroup, summary, items, stat, expense } = data;

    return (
      <>
        <Spin tip="Performing CutOff, please wait... " spinning={isBusy}>
          <Card title="Transaction Summary" bodyStyle={{ padding: "12px 14px" }} size="small">
            <Row
              gutter={16}
              style={{
                // borderStyle: "dashed",
                border:
                  cutoff !== moment(cutOffDate).format("YYYY-MM-DD")
                    ? "4px dashed #f5222d"
                    : "4px dashed #1d39c4",

                padding: 6,
              }}
            >
              <Col
                span={12}
                style={{
                  paddingTop: 6,
                  paddingLeft: 30,
                }}
              >
                <b>CutOff Date</b>
              </Col>
              <Col span={12}>
                <DatePicker
                  value={cutOffDate}
                  onChange={handleDateChange}
                  style={{ width: "100%" }}
                  disabledDate={(current) => {
                    let customDate = moment(cutoff).add(1, "d").format("YYYY-MM-DD");
                    return current >= moment(customDate, "YYYY-MM-DD");
                  }}
                />
              </Col>
            </Row>

            {cutoff !== moment(cutOffDate).format("YYYY-MM-DD") && (
              <Alert
                message={<b>Warning</b>}
                description={
                  <span>
                    Date specified is not the actual <b>CutOff.</b>
                  </span>
                }
                type="warning"
                showIcon
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                }}
              />
            )}
            <Divider style={{ margin: 6 }}>Statistics</Divider>
            <Statistics cutoff={cutoff} stat={stat[0]} group={transGroup} />
            <Divider style={{ margin: 6 }}>Sales</Divider>
            <Descriptions size="small" bordered column={1}>
              <Descriptions.Item
                label="Gross Sales"
                style={style.descriptionText}
                contentStyle={{
                  textAlign: "right",
                }}
              >
                &#8369;
                {formatNumber(summary.gross)}
              </Descriptions.Item>
              <Descriptions.Item
                label="Expense"
                style={style.descriptionText}
                contentStyle={{
                  textAlign: "right",
                }}
              >
                &#8369;
                {formatNumber(summary.expense)}
              </Descriptions.Item>

              <Descriptions.Item
                label="Net Sales"
                style={{
                  ...style.descriptionText,
                  backgroundColor: "#10239e",
                  color: "white",
                  fontSize: 17,
                }}
                contentStyle={{
                  textAlign: "right",
                }}
              >
                &#8369;{formatNumber(summary.net)}
              </Descriptions.Item>

              <Descriptions.Item
                label="Cash Payment"
                style={{
                  ...style.descriptionText,
                  backgroundColor: "#2f54eb",
                  color: "white",
                }}
                contentStyle={{
                  textAlign: "right",
                }}
              >
                &#8369;
                {formatNumber(summary.cash)}
              </Descriptions.Item>
              <Descriptions.Item
                label="GCash Payment"
                style={{
                  ...style.descriptionText,
                  backgroundColor: "#2f54eb",
                  color: "white",
                }}
                contentStyle={{
                  textAlign: "right",
                }}
              >
                &#8369;
                {formatNumber(summary.gcash)}
              </Descriptions.Item>

              <Descriptions.Item
                label="To Remit"
                style={{
                  ...style.descriptionText,
                  backgroundColor: "#10239e",
                  color: "white",
                  fontSize: 17,
                }}
                contentStyle={{
                  textAlign: "right",
                }}
              >
                &#8369;
                {formatNumber(summary.cash)}
              </Descriptions.Item>
              <Descriptions.Item
                label="Total Transaction"
                style={style.descriptionText}
                contentStyle={{
                  textAlign: "right",
                }}
              >
                {summary.transactions || 0}
              </Descriptions.Item>
              <Descriptions.Item
                label="Total Pax"
                style={style.descriptionText}
                contentStyle={{
                  textAlign: "right",
                }}
              >
                {summary.pax || 0}
              </Descriptions.Item>
              <Descriptions.Item
                label="Total Items"
                style={style.descriptionText}
                contentStyle={{
                  textAlign: "right",
                }}
              >
                {summary.items || 0}
              </Descriptions.Item>
            </Descriptions>

            <Collapse
              defaultActiveKey={["3"]}
              // onChange={onChange}
            >
              <Panel header="Items Sold" key="1">
                <Table
                  dataSource={items}
                  columns={itemColumn}
                  rowKey="item_name"
                  size="small"
                  pagination={false}
                  scroll={{ x: 400 }}
                />
              </Panel>
              <Panel header="Expense List" key="2">
                <Table
                  dataSource={expense}
                  columns={expenseColumn}
                  rowKey="expense_id"
                  size="small"
                  pagination={false}
                />
              </Panel>
              <Panel header="Cash Denomination Breakdown" key="3">
                <Table
                  dataSource={denomination.data}
                  columns={denominationColumn}
                  rowKey="denomination"
                  size="small"
                  pagination={false}
                  summary={() => {
                    return (
                      <>
                        <Table.Summary.Row>
                          <Table.Summary.Cell index={1} colSpan={3}>
                            <b>Cash On Hand</b>
                          </Table.Summary.Cell>

                          <Table.Summary.Cell index={2} align="right">
                            {formatNumber(denomination.cash_on_hand)}
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                        <Table.Summary.Row>
                          <Table.Summary.Cell index={1} colSpan={3}>
                            <b>Cash Over</b>
                          </Table.Summary.Cell>

                          <Table.Summary.Cell index={2} align="right">
                            {formatNumber(denomination.cash_over)}
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                        <Table.Summary.Row>
                          <Table.Summary.Cell index={1} colSpan={3}>
                            <b>Cash Short</b>
                          </Table.Summary.Cell>

                          <Table.Summary.Cell index={2} align="right">
                            {formatNumber(denomination.cash_short)}
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      </>
                    );
                  }}
                />
              </Panel>
            </Collapse>

            {transGroup.started > 0 || transGroup.total === 0 ? (
              <Alert
                message={<b>CutOff Unavailable</b>}
                description={
                  <>
                    <p>
                      <b>
                        <i>Reason 1:</i>
                      </b>{" "}
                      There are Started/Open transaction that needs to be checked-out.
                    </p>
                    <p>
                      <b>
                        <i>Reason 2:</i>
                      </b>{" "}
                      There is no specified transaction under this cutoff date.
                    </p>
                  </>
                }
                type="error"
                showIcon
                closable
              />
            ) : null}

            <Row gutter={4} style={{ marginTop: 12 }}>
              <Col span={8}>
                <Button
                  type="primary"
                  icon={<PrinterOutlined />}
                  size="large"
                  block
                  onClick={handlePrint} // remove stat, transGroup from object
                >
                  Print
                </Button>
              </Col>
              <Col span={8}>
                <Button
                  type="danger"
                  icon={<ScissorOutlined />}
                  size="large"
                  ghost
                  block
                  disabled={
                    transGroup.started > 0 || cutoff !== moment(cutOffDate).format("YYYY-MM-DD")
                      ? true
                      : false
                  }
                  onClick={handleCutOff}
                >
                  Cut-Off
                </Button>
              </Col>
              <Col span={8}>
                <Button icon={<CloseOutlined />} size="large" block onClick={() => navigate(-1)}>
                  Close
                </Button>
              </Col>
            </Row>
          </Card>
        </Spin>
        <Modal
          visible={isLogoutLoadingVisible}
          footer={null}
          closable={false}
          width={300}
          style={{ zIndex: 999999 }}
        >
          <div style={{ textAlign: "center" }}>
            <LoadingOutlined /> Logging out. Please wait ...
          </div>
        </Modal>
      </>
    );
  }
};

export default TransactionSummary;

const style = {
  descriptionText: {
    // fontSize: 13,
    padding: 4,
    paddingLeft: 10,
    paddingRight: 10,

    // textAlign: "center"
  },
};
