import React, { useContext, useState, useEffect } from "react";
import { SocketContext } from "services/contexts/socket";
import { Drawer, Typography, Card } from "antd";
// import { PrinterOutlined, CloseOutlined } from "@ant-design/icons";
import { useGetItemCutoffLedgerLimit } from "services/hooks/api/prepAreaAPI";

import ContentLoader from "common/ContentLoader";
import RetryMessage from "common/RetryMessage";

const { Text } = Typography;
const LedgerView = ({ params, setParams }) => {
  const socket = useContext(SocketContext);
  const {
    isLoading: ledgerLimitIsLoading,
    data: ledgerLimitData,
    isError,
    error,
    refetch,
  } = useGetItemCutoffLedgerLimit({
    branch_id: params.branch_id,
    inventory_cutoff: params.inventory_cutoff,
  });
  const [inventoryLedgerLimit, setInventoryLedgerLimit] = useState([]);

  const OnClose = () => {
    setParams({ visible: false, inventory_cutoff: null, branch_id: null });
  };

  useEffect(() => {
    if (!ledgerLimitIsLoading && ledgerLimitData) {
      setInventoryLedgerLimit(ledgerLimitData);
    }
  }, [ledgerLimitData, ledgerLimitIsLoading]);

  // const handlePrint = () => {
  //   const branchDetails = Object.keys(params).reduce((object, key) => {
  //     if (!["selectedItem", "visible", "item_id"].includes(key)) {
  //       object[key] = params[key];
  //     }
  //     return object;
  //   }, {});
  //   const printData = {
  //     ...branchDetails,
  //     data,
  //   };
  //   socket.emit("printDaily", { ...printData });
  //   // console.log(printData);
  // };

  return (
    <Drawer
      title="Process Ledger (Last 30 Items)"
      closable={true}
      visible={params.visible}
      onClose={OnClose}
      destroyOnClose={true}
      width={500}
    >
      {ledgerLimitIsLoading ? (
        <ContentLoader />
      ) : isError ? (
        <Card>
          <RetryMessage message={error.message} onRetry={() => refetch()} />
        </Card>
      ) : ledgerLimitData ? (
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr style={trStyle}>
              <th style={{ ...cellStyle, width: 180 }}>Item</th>
              <th style={cellStyle}>Qty</th>
              <th style={cellStyle}>Type</th>
              <th style={cellStyle}>Tables</th>
            </tr>
          </thead>
          <tbody
            style={{
              display: "block",
              // height: 160,
              // overflowY: "scroll",
              width: "100%",
              paddingBottom: 20,
              //   border: "1px solid grey ",
            }}
          >
            {inventoryLedgerLimit.map((ledgerItem, index) => {
              return (
                <tr key={index} style={trStyle}>
                  <td style={{ ...cellStyle, width: 180 }}>
                    <Text
                      style={{
                        width: 120,
                      }}
                      ellipsis={{
                        tooltip: ledgerItem.item_name,
                      }}
                    >
                      {ledgerItem.item_name}
                    </Text>
                  </td>
                  <td style={cellStyle}>
                    {ledgerItem.qty < 0 ? (
                      <Text style={{ color: "red" }}>{ledgerItem.qty}</Text>
                    ) : (
                      ledgerItem.qty
                    )}
                  </td>
                  <td style={cellStyle}>{ledgerItem.process_type}</td>
                  <td style={cellStyle}>{ledgerItem.tables}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : null}
    </Drawer>
  );
};
export default LedgerView;

const cellStyle = {
  textAlign: "left",
  padding: 8,
  borderBottom: "solid 1px #f0f0f0",
};

const trStyle = {
  display: "table",
  width: "100%",
  tableLayout: "fixed",
};
