const styles = {
  quantityContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    minHeight: 160,
    // border: "solid 0.5px #f0f0f0"
  },
  spaceBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
  menuButtons: {
    // background: "#0092ff",
    height: 80,
    width: "100%",
    marginBottom: 2,
    // color: "white",
    fontSize: "1rem",
  },
  avatarNew: {
    color: "#ffffff",
    backgroundColor: "#096dd9",
    // border: "1px solid #0050b3"
  },
  avatarStarted: {
    color: "#ffffff",
    backgroundColor: "#096dd9",
  },
  avatarDeleted: {
    color: "#ffffff",
    backgroundColor: "#fa541c",
  },
  avatarEnd: {
    color: "#f56a00",
    backgroundColor: "#fde3cf",
  },
};

export default styles;
