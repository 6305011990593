import React from "react";
import { Result, Button } from "antd";

const RetryMessage = ({ message, onRetry }) => {
    return (
        <Result
            status="warning"
            title="There are some problems with your operation."
            subTitle={message || null}
            extra={
                <Button type="primary" key="console" onClick={onRetry}>
                    Retry
                </Button>
            }
        />
    );
};

export default RetryMessage;
