import React, { useContext } from "react";
import { SocketContext } from "services/contexts/socket";
import { Drawer, Descriptions, Card, Button, Space, Table } from "antd";
import { PrinterOutlined, CloseOutlined } from "@ant-design/icons";
import { useGetItemCutoffLedger } from "services/hooks/api/prepAreaAPI";

import { formatNumber } from "helpers/numberFormatter";
import { titleCase } from "helpers/textTransformer";
import moment from "moment";

import ContentLoader from "common/ContentLoader";
import RetryMessage from "common/RetryMessage";

import whichOS from "helpers/whichOS";

const InventoryLedger = ({ params, setParams }) => {
  const socket = useContext(SocketContext);
  const { isLoading, data, isError, error, refetch } = useGetItemCutoffLedger({
    item_id: params.selectedItem ? params.selectedItem.item_id : null,
    branch_id: params.branch_id,
    inventory_cutoff: params.inventory_cutoff,
  });

  const OnClose = () => {
    setParams((prevState) => ({ ...prevState, visible: false, selectedItem: null }));
  };

  const handlePrint = () => {
    const branchDetails = Object.keys(params).reduce((object, key) => {
      if (!["selectedItem", "visible"].includes(key)) {
        object[key] = params[key];
      }
      return object;
    }, {});

    const printData = {
      ...branchDetails,
      item: params.selectedItem,
      data,
    };
    socket.emit("printLedger", { ...printData });
    // console.log(printData);
  };
  const columns = [
    {
      title: "Process",
      dataIndex: "process_type",
      width: 90,
      render: (text, record) => titleCase(record.process_type),
    },
    {
      title: "Qty",
      dataIndex: "qty",
      key: "qty",
      align: "center",
    },
    {
      title: "Tables",
      dataIndex: "tables",
      key: "tables",
    },
    {
      title: "Time",
      dataIndex: "created_at",
      render: (text, record) => moment(record.created_at).format("hh:mma"),
    },
    {
      title: "Beg",
      dataIndex: "beginning",
      align: "right",
      render: (text, record) => formatNumber(record.beginning, true),
    },
    {
      title: "Bal",
      dataIndex: "balance",
      align: "right",

      render: (text, record) => formatNumber(record.balance, true),
    },
  ];

  return (
    <Drawer
      title={
        params.selectedItem && (
          <>
            {params.selectedItem.item_name} ({params.selectedItem.unit})
          </>
        )
      }
      closable={true}
      visible={params.visible}
      onClose={OnClose}
      destroyOnClose={true}
      width={whichOS !== "Linux" ? 550 : 320}
      bodyStyle={{ padding: 10 }}
    >
      {isLoading ? (
        <ContentLoader />
      ) : isError ? (
        <Card>
          <RetryMessage message={error.message} onRetry={() => refetch()} />
        </Card>
      ) : data ? (
        <>
          <Descriptions bordered size="small" column={1}>
            <Descriptions.Item
              label="Branch"
              style={{
                fontWeight: 600,
                border: "1px solid #d9d9d9",
                // borderTop: "1px solid #d9d9d9",
              }}
              contentStyle={{
                textAlign: "right",
                fontWeight: 600,
              }}
            >
              {params.branch_name}
            </Descriptions.Item>
            <Descriptions.Item
              label="Inventory CutOff"
              style={{
                fontWeight: 600,
                border: "1px solid #d9d9d9",
                // borderTop: "1px solid #d9d9d9",
              }}
              contentStyle={{
                textAlign: "right",
                fontWeight: 600,
              }}
            >
              {params.inventory_cutoff}
            </Descriptions.Item>

            <Descriptions.Item
              label="Time Checked"
              style={{
                fontWeight: 600,
                border: "1px solid #d9d9d9",
                // borderTop: "1px solid #d9d9d9",
              }}
              contentStyle={{
                textAlign: "right",
                fontWeight: 600,
              }}
            >
              {moment().format("hh:mma")}
            </Descriptions.Item>
          </Descriptions>

          <Table
            dataSource={data}
            rowKey="rec"
            columns={columns}
            size="small"
            pagination={false}
            style={{ marginTop: 12 }}
            scroll={{ x: 380 }}
          />

          <Space style={{ marginTop: 12 }}>
            <Button type="primary" icon={<PrinterOutlined />} onClick={handlePrint}>
              Print
            </Button>
            <Button type="secondary" icon={<CloseOutlined />} onClick={OnClose}>
              Close
            </Button>
          </Space>
        </>
      ) : null}
    </Drawer>
  );
};
export default InventoryLedger;
