import React, { useEffect, useState } from "react";
import { Typography, Drawer } from "antd";

import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import { titleCase, upperCase } from "helpers/textTransformer";
const { Title, Text } = Typography;

const OSK = ({ visibility, setVisibility, form, params, isInputOnly, onCloseOSK }) => {
  const [textValue, setTextValue] = useState(params.fieldValue);
  let oskRef = React.useRef();

  const standardKeys = [
    "{clr} 1 2 3 4 5 6 7 8 9 0 -",
    "q w e r t y u i o p {bksp}",
    "a s d f g h j k l : {ok}",
    "z x c v b n m ñ . , {cancel}",
    "@ {space}",
  ];

  const numericKeys = ["7 8 9 {clr}", "4 5 6 {bksp}", "1 2 3 {ok}", "0 . {cancel}", ""];

  useEffect(() => {
    if (oskRef.current) oskRef.current.setInput(params.fieldValue);
    setTextValue(params.fieldValue);
  }, [params]);

  const onChange = (input) => {
    const textVal =
      params.textFormat === "upperCase"
        ? upperCase(input)
        : params.textFormat === "titleCase"
        ? titleCase(input)
        : input;
    if (params.isNumberOnly) {
      const n = Number(textVal) || 0;
      const max = params.maxNumberAllowed;
      if (n > max) {
        setTextValue("");
        oskRef.current.clearInput();
      } else {
        setTextValue(n);
      }
    } else {
      setTextValue(textVal);
    }
  };
  const onClose = () => {
    setTextValue("");
    oskRef.current.clearInput();
    if (onCloseOSK) onCloseOSK(params.field);
    setVisibility(false);
  };

  const onKeyPress = (button) => {
    switch (button) {
      case "{clr}":
        setTextValue("");
        oskRef.current.clearInput();
        break;

      case "{ok}":
        if (isInputOnly) {
          form(textValue);
        } else {
          form.setFieldsValue({ [params.field]: textValue });
        }

        onClose();
        //  dito magaganap
        break;
      case "{cancel}":
        onClose();
        break;
      default:
        return;
    }
  };

  function toAskterisk(text) {
    if (text) {
      return text.replace(/./g, "*");
    } else {
      return "";
    }
  }

  return (
    <Drawer
      placement="bottom"
      closable={true}
      visible={visibility}
      height={520}
      onClose={onClose}
      zIndex={2000}
      destroyOnClose={true}
    >
      <div style={textContainer}>
        <div style={{ position: "absolute", top: 10, left: 20 }}>
          <Text type="secondary">{params && params.label}</Text>
        </div>
        <Title level={1} style={{ fontSize: 67 }}>
          {params && params.isPassword ? toAskterisk(textValue) : textValue}
        </Title>
      </div>
      <Keyboard
        keyboardRef={(r) => (oskRef.current = r)}
        onChange={onChange}
        onKeyPress={onKeyPress}
        inputName="default"
        layout={{
          default: params.isNumberOnly ? numericKeys : standardKeys,
        }}
        display={{
          "{clr}": "C",
          "{cancel}": "&#x2716;",
          "{bksp}": "&#8678;",
          "{ok}": "&#10004;",
          "{space}": "&#8212;",
        }}
        buttonTheme={[
          {
            class: "hg-green",
            buttons: "{&#10004;}",
          },
          {
            class: "hg-highlight",
            buttons: "{&#10004;}",
          },
          {
            class: "hg-red",
            buttons: "{&#x2716;}",
          },
        ]}
      />
    </Drawer>
  );
};

export default OSK;

const textContainer = {
  width: "100%",
  height: 120,
  padding: "15px",
  border: "2px solid #85a5ff",
  marginTop: 10,
  marginBottom: 30,
  textAlign: "center",
  borderRadius: 10,
  position: "relative",
};
