import React, { useState } from "react";

import { Descriptions, Card, Divider, Table, Col, Row, Collapse, Spin, Drawer } from "antd";

import { formatNumber } from "helpers/numberFormatter";
import { useGetTransactionSummary } from "services/hooks/api/transactionAPI";
import whichOS from "helpers/whichOS";

const { Panel } = Collapse;
const Summary = ({ visibility, setVisibility, branch_id, cutoff }) => {
  const { isLoading, data, isError } = useGetTransactionSummary({
    branch_id,
    cutoff,
  });

  const itemColumn = [
    {
      title: "Item",
      dataIndex: "item_name",
      // ellipsis: true,
      width: 140,
      fixed: "left",
    },
    {
      title: "Price",
      dataIndex: "price",
      align: "right",
      render: (text, record) => <span>{formatNumber(record.price)}</span>,
    },
    {
      title: "Qty",
      dataIndex: "qty",
      width: 70,
      align: "right",
    },

    {
      title: "Total",
      dataIndex: "total_price",
      align: "right",
      render: (text, record) => (
        <span>
          &#8369;
          {formatNumber(record.total_price)}
        </span>
      ),
    },
  ];

  const expenseColumn = [
    {
      title: "Details",
      dataIndex: "expense_details",
      ellipsis: true,
      // width: 120,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      align: "right",
      render: (text, record) => <span>&#8369;{formatNumber(record.amount)} </span>,
    },
  ];

  const handleClose = () => {
    setVisibility(false);
  };

  return (
    <Drawer
      title="Transaction Summary"
      width={whichOS !== "Linux" ? 420 : 350}
      placement="right"
      closable={true}
      visible={visibility}
      bodyStyle={{ padding: "4px 18px" }}
      onClose={handleClose}
    >
      <Spin tip="Loading" spinning={isLoading}>
        {data && !isError ? (
          <>
            <Row
              gutter={16}
              style={{
                // borderStyle: "dashed",
                border: "4px dashed #1d39c4",

                padding: 6,
              }}
            >
              <Col
                span={12}
                style={{
                  paddingTop: 4,
                  paddingLeft: 30,
                }}
              >
                <b>CutOff Date</b>
              </Col>
              <Col
                span={12}
                style={{
                  paddingTop: 4,
                  textAlign: "right",
                }}
              >
                <b>{cutoff}</b>
              </Col>
            </Row>
            <Divider style={{ margin: 6 }}>Sales</Divider>
            <Descriptions size="small" bordered column={1}>
              <Descriptions.Item
                label="Gross Sales"
                style={style.descriptionText}
                contentStyle={{
                  textAlign: "right",
                }}
              >
                &#8369;
                {formatNumber(data.summary.gross)}
              </Descriptions.Item>
              <Descriptions.Item
                label="Expense"
                style={style.descriptionText}
                contentStyle={{
                  textAlign: "right",
                }}
              >
                &#8369;
                {formatNumber(data.summary.expense)}
              </Descriptions.Item>

              <Descriptions.Item
                label="Net Sales"
                style={{
                  ...style.descriptionText,
                  backgroundColor: "#10239e",
                  color: "white",
                  fontSize: 17,
                }}
                contentStyle={{
                  textAlign: "right",
                }}
              >
                &#8369;{formatNumber(data.summary.net)}
              </Descriptions.Item>

              <Descriptions.Item
                label="Cash Payment"
                style={{
                  ...style.descriptionText,
                  backgroundColor: "#2f54eb",
                  color: "white",
                }}
                contentStyle={{
                  textAlign: "right",
                }}
              >
                &#8369;
                {formatNumber(data.summary.cash)}
              </Descriptions.Item>
              <Descriptions.Item
                label="GCash Payment"
                style={{
                  ...style.descriptionText,
                  backgroundColor: "#2f54eb",
                  color: "white",
                }}
                contentStyle={{
                  textAlign: "right",
                }}
              >
                &#8369;
                {formatNumber(data.summary.gcash)}
              </Descriptions.Item>

              <Descriptions.Item
                label="To Remit"
                style={{
                  ...style.descriptionText,
                  backgroundColor: "#10239e",
                  color: "white",
                  fontSize: 17,
                }}
                contentStyle={{
                  textAlign: "right",
                }}
              >
                &#8369;
                {formatNumber(data.summary.cash)}
              </Descriptions.Item>
              <Descriptions.Item
                label="Total Transaction"
                style={style.descriptionText}
                contentStyle={{
                  textAlign: "right",
                }}
              >
                {data.summary.transactions || 0}
              </Descriptions.Item>
              <Descriptions.Item
                label="Total Pax"
                style={style.descriptionText}
                contentStyle={{
                  textAlign: "right",
                }}
              >
                {data.summary.pax || 0}
              </Descriptions.Item>
              <Descriptions.Item
                label="Total Items"
                style={style.descriptionText}
                contentStyle={{
                  textAlign: "right",
                }}
              >
                {data.summary.items || 0}
              </Descriptions.Item>
            </Descriptions>

            <Collapse
            // defaultActiveKey={["1", "2"]}
            // onChange={onChange}
            >
              <Panel header="Items Sold" key="1">
                <Table
                  dataSource={data.items}
                  columns={itemColumn}
                  rowKey="item_name"
                  size="small"
                  pagination={false}
                  scroll={{ x: 400 }}
                />
              </Panel>
              <Panel header="Expense List" key="2">
                <Table
                  dataSource={data.expense}
                  columns={expenseColumn}
                  rowKey="expense_id"
                  size="small"
                  pagination={false}
                />
              </Panel>
            </Collapse>
          </>
        ) : null}
      </Spin>
    </Drawer>
  );
};

export default Summary;

const style = {
  descriptionText: {
    // fontSize: 13,
    padding: 4,
    paddingLeft: 10,
    paddingRight: 10,

    // textAlign: "center"
  },
};
