import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setUserDetails } from "services/redux/slices/user";

import {
  BarChartOutlined,
  ShoppingCartOutlined,
  CarryOutOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import { Avatar, Space, Card, Button, Typography, Collapse, Descriptions } from "antd";
import noImg from "assets/images/no_image_available.png";
import moment from "moment";
import { useGetDashboardData } from "services/hooks/api/adminPageAPI";
import { useMutationUpdateUser } from "services/hooks/api/userAPI";
import { formatNumber } from "helpers/numberFormatter";
import AdminGraph from "./components/AdminGraph";
import ContentLoader from "common/ContentLoader";
import RetryMessage from "common/RetryMessage";

const { Panel } = Collapse;
const { Text } = Typography;

const Admin = () => {
  const userDetails = useSelector((state) => state.User);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [summary, setSummary] = useState([]);
  const toDate = moment().format("YYYY-MM-DD");
  const fromDate = moment().subtract(10, "days").format("YYYY-MM-DD");
  const { isLoading, data, isError, error, refetch } = useGetDashboardData({ toDate, fromDate });
  const mutationUpdateUser = useMutationUpdateUser();
  useEffect(() => {
    if (!isLoading && data) {
      setSummary(data.summary);
    }
  }, [isLoading, data]);

  const OnClick = (e, mode, branch) => {
    if (userDetails.branch_id !== branch.branch_id) {
      mutationUpdateUser.mutate(
        { user_id: userDetails.user_id, data: { branch_id: branch.branch_id } },
        {
          onError: (error) => {
            console.log(`Error:  ${error.message}`);
          },
          onSuccess: (_) => {
            console.log("branch changed");
          },
        }
      );
      dispatch(
        setUserDetails({
          userDetails: {
            ...userDetails,
            branch_id: branch.branch_id,
            branch_name: branch.branch_name,
            cutoff: branch.cutoff,
            inventory_cutoff: branch.inventory_cutoff,
            table_count: branch.table_count,
            print_server_id: branch.print_server_id,
          },
        })
      );
    }

    switch (mode) {
      case 0:
        navigate("/cashier");
        break;
      case 1:
        navigate("/transaction-summary");
        break;
      case 2:
        navigate("/inventory");
        break;
      case 3:
        navigate("/sales-summary");
        break;
      default:
        return;
    }
  };

  const genExtra = (b) => (
    <Space direction="vertical">
      <Space>
        {b.branch_type === "store" ? (
          <>
            <Button
              shape="circle"
              icon={<CarryOutOutlined />}
              type="primary"
              ghost
              onClick={(e) => OnClick(e, 0, b)}
            />
            <Button
              shape="circle"
              icon={<BarChartOutlined />}
              type="dashed"
              onClick={(e) => OnClick(e, 1, b)}
            />
          </>
        ) : null}
        <Button
          shape="circle"
          icon={<ShoppingCartOutlined />}
          type="dashed"
          onClick={(e) => OnClick(e, 2, b)}
        />
      </Space>
      {b.branch_type === "store" ? (
        <Button type block icon={<BarChartOutlined />} onClick={(e) => OnClick(e, 3, b)}>
          Sales
        </Button>
      ) : null}
    </Space>
  );

  if (isLoading) {
    return <ContentLoader />;
  }

  if (isError) {
    return (
      <Card>
        <RetryMessage message={error.message} onRetry={() => refetch()} />
      </Card>
    );
  }
  if (data) {
    const { branch } = data;
    const branchMap = branch.map((b) => {
      const { items, pax, gross, transactions } = b.transaction.reduce(
        (acc, result) => {
          acc.items += result.items;
          acc.pax += result.pax;
          acc.gross += result.total;
          acc.transactions += 1;

          return acc;
        },
        { items: 0, pax: 0, gross: 0, transactions: 0 }
      );
      const expense = b.expense.reduce((t, item) => t + item.amount, 0);
      return { ...b, items, pax, gross, transactions, expense };
    });

    return (
      <Card bodyStyle={{ padding: "12px 14px" }} size="small">
        <Collapse>
          {branchMap.map((b, index) => {
            return (
              <Panel
                header={
                  <Space>
                    <Avatar size={60} src={b.image || noImg} />
                    <Space direction="vertical" size={1} style={{ marginLeft: 4 }}>
                      <b>{b.branch_name}</b>
                      <Text>
                        <CalendarOutlined style={{ color: "" }} />{" "}
                        {moment(b.cutoff).format("MM/DD/YYYY")}
                      </Text>
                      {b.branch_type === "store" ? (
                        <Text>
                          <BarChartOutlined /> &#8369;{formatNumber(b.gross)}
                        </Text>
                      ) : null}
                    </Space>
                  </Space>
                }
                key={(index + 1).toString()}
                extra={genExtra(b)}
                collapsible={b.branch_type === "store" ? "header" : "disabled"}
                showArrow={false}
              >
                <Descriptions size="small" bordered column={1}>
                  <Descriptions.Item
                    label="Total Transaction"
                    style={style.descriptionText}
                    contentStyle={{
                      textAlign: "right",
                    }}
                  >
                    {b.transactions}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label="Total Pax"
                    style={style.descriptionText}
                    contentStyle={{
                      textAlign: "right",
                    }}
                  >
                    {b.pax}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label="Total Items"
                    style={style.descriptionText}
                    contentStyle={{
                      textAlign: "right",
                    }}
                  >
                    {b.items}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label="Gross Sales"
                    style={{
                      ...style.descriptionText,
                      backgroundColor: "#2f54eb",
                      color: "white",
                    }}
                    contentStyle={{
                      textAlign: "right",
                    }}
                  >
                    &#8369;
                    {formatNumber(b.gross)}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label="Expense"
                    style={{
                      ...style.descriptionText,
                      backgroundColor: "#2f54eb",
                      color: "white",
                    }}
                    contentStyle={{
                      textAlign: "right",
                    }}
                  >
                    (&#8369;
                    {formatNumber(b.expense)})
                  </Descriptions.Item>

                  <Descriptions.Item
                    label="Net Sales"
                    style={{
                      ...style.descriptionText,
                      backgroundColor: "#10239e",
                      color: "white",
                    }}
                    contentStyle={{
                      textAlign: "right",
                    }}
                  >
                    &#8369;{formatNumber(b.gross - b.expense)}
                  </Descriptions.Item>
                </Descriptions>
              </Panel>
            );
          })}

          <Panel
            header={
              <>
                <b>Sales Graph</b> ({moment(fromDate).format("MMM DD")} -{" "}
                {moment(toDate).format("MMM DD")})
              </>
            }
            key="100"
          >
            <AdminGraph
              data={summary}
              xField="cutoff"
              yField="gross"
              seriesField="branch"
              isCurrency={false}
              // title="Sales Graph"
            />
          </Panel>
          <Panel
            header={
              <>
                <b>Client Graph</b> ({moment(fromDate).format("MMM DD")} -{" "}
                {moment(toDate).format("MMM DD")})
              </>
            }
            key="200"
          >
            <AdminGraph
              data={summary}
              xField="cutoff"
              yField="pax"
              seriesField="branch"
              isCurrency={false}
              // title="Client Graph"
            />
          </Panel>
        </Collapse>
      </Card>
    );
  }
};
export default Admin;

const style = {
  descriptionText: {
    padding: 4,
    paddingLeft: 10,
    paddingRight: 10,
  },
};
