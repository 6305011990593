import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { Menu, Drawer, Image, Divider } from "antd";
import {
  SettingOutlined,
  LogoutOutlined,
  ShopOutlined,
  BarChartOutlined,
  HomeOutlined,
  SnippetsOutlined,
  DropboxOutlined,
  BlockOutlined,
  DotChartOutlined,
  AppstoreAddOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";

import { toggleSiderInDrawerCollapse } from "services/redux/slices/app";

import logo from "assets/images/logo.png";

const AppSider = ({ setIsLogoutLoadingVisible }) => {
  const navigate = useNavigate();
  let { isSiderInDrawerCollapsed } = useSelector((state) => state.App);
  let { role_id } = useSelector((state) => state.User);

  const dispatch = useDispatch();
  const location = useLocation();

  const handleSidebarMenuClick = ({ key }) => {
    if (key === "logout") {
      setIsLogoutLoadingVisible(true);
    } else {
      navigate(key);
    }
    dispatch(toggleSiderInDrawerCollapse());
  };

  const cashierMenu = [
    {
      label: "Home",
      key: "/cashier",
      icon: <HomeOutlined />,
    },
    {
      label: "Expense",
      key: "/expense",
      icon: <ShopOutlined />,
    },
    {
      label: "Summary",
      key: "/transaction-summary",
      icon: <BarChartOutlined />,
    },
    // {
    //   label: "Account Settings",
    //   key: "/account-settings",
    //   icon: <SettingOutlined />,
    //   disabled: true,
    // },
    {
      label: "Logout",
      key: "logout",
      icon: <LogoutOutlined />,
    },
  ];

  const adminMenu = [
    {
      label: "Home",
      key: "/admin-page",
      icon: <HomeOutlined />,
    },
    {
      label: "Items",
      key: "/items",
      icon: <SnippetsOutlined />,
    },
    {
      label: "Branch Items",
      key: "/branch-inventory",
      icon: <DropboxOutlined />,
    },
    {
      label: "Delivery",
      key: "/delivery",
      icon: <AppstoreAddOutlined />,
    },
    {
      label: "Inventory Transfer",
      key: "/inventory-transfer",
      icon: <BlockOutlined />,
    },
    {
      label: "Sales Summary",
      key: "/sales-summary",
      icon: <DotChartOutlined />,
    },
    {
      label: "Users",
      key: "/users",
      icon: <UsergroupAddOutlined />,
    },
    {
      label: "App Settings",
      key: "/settings",
      icon: <SettingOutlined />,
    },
    // {
    //   label: "Account Settings",
    //   key: "/account-settings",
    //   icon: <SettingOutlined />,
    //   disabled: true,
    // },
    {
      label: "Logout",
      key: "logout",
      icon: <LogoutOutlined />,
    },
  ];

  const prepAreaMenu = [
    {
      label: "Home",
      key: "/prep-area",
      icon: <HomeOutlined />,
    },
    {
      label: "Inventory",
      key: "/inventory",
      icon: <SettingOutlined />,
    },
    {
      label: "Inventory Transfer",
      key: "/inventory-transfer",
      icon: <BlockOutlined />,
    },

    // {
    //   label: "Account Settings",
    //   key: "/account-settings",
    //   icon: <SettingOutlined />,
    //   disabled: true,
    // },
    {
      label: "Logout",
      key: "logout",
      icon: <LogoutOutlined />,
    },
  ];

  const kitchenMenu = [
    {
      label: "Home",
      key: "/kitchen-inventory",
      icon: <HomeOutlined />,
    },
    {
      label: "Inventory",
      key: "/inventory",
      icon: <SettingOutlined />,
    },
    {
      label: "Inventory Transfer",
      key: "/inventory-transfer",
      icon: <BlockOutlined />,
    },
    {
      label: "Delivery",
      key: "/delivery",
      icon: <AppstoreAddOutlined />,
    },
    // {
    //   label: "Account Settings",
    //   key: "/account-settings",
    //   icon: <SettingOutlined />,
    //   disabled: true,
    // },

    {
      label: "Logout",
      key: "logout",
      icon: <LogoutOutlined />,
    },
  ];

  const SiderLogo = () => {
    return (
      <div
        className="sider-logo"
        style={{
          backgroundColor: "white",
          // border: "1px solid black",
          textAlign: "center",
          padding: 10,
          paddingBottom: 0,
        }}
      >
        <Image width={90} src={logo} />
        <Divider style={{ padding: 0, margin: 4 }} />
      </div>
    );
  };

  const SiderMenu = (
    <Menu
      theme="light" //{pageTheme === "light" ? "light" : "dark"}
      mode="inline"
      selectedKeys={[location.pathname]}
      onClick={handleSidebarMenuClick}
      items={
        role_id === 2
          ? cashierMenu
          : role_id === 3
          ? prepAreaMenu
          : role_id === 4
          ? kitchenMenu
          : adminMenu
      }
    />
  );

  return (
    <>
      <Drawer
        placement="left"
        visible={isSiderInDrawerCollapsed}
        width={220}
        closable={false}
        bodyStyle={{
          padding: 0,
          // backgroundColor: "#001529",
        }}
        destroyOnClose
        onClose={() => dispatch(toggleSiderInDrawerCollapse())}
      >
        <SiderLogo />
        {SiderMenu}
      </Drawer>
    </>
  );
};

export default AppSider;
