import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
const OffSetDiv = () => {
  const { role_id } = useSelector((state) => state.User);
  const location = useLocation();

  return (
    <div
      style={{
        width: "100vw",
        height:
          // (location && location.pathname === "/admin-page") ||
          // location.pathname === "/items" ||
          // location.pathname === "/branch-inventory" ||
          // location.pathname === "/inventory-transfer" ||
          // location.pathname === "/settings"
          //   ? 60
          //   : role_id !== 3 //&& role_id !== 4
          //   ? 80
          //   : 60,
          location &&
          [
            "/admin-page",
            "/items",
            "/branch-inventory",
            "/inventory-transfer",
            "/settings",
            "/delivery",
            "/users",
          ].includes(location.pathname)
            ? 60
            : role_id !== 3 //&& role_id !== 4
            ? 80
            : 60,
      }}
    />
  );
};

export default OffSetDiv;
