import React, { useState, useEffect } from "react";
// import { useQueryClient } from "react-query";
import {
  Drawer,
  Typography,
  Button,
  Row,
  Col,
  message,
  Spin,
  Modal,
  Descriptions,
  Divider,
  InputNumber,
} from "antd";
import {
  QuestionCircleOutlined,
  CloseOutlined,
  CheckOutlined,
  PlusCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";

import { useMutationUpdateInventoryBalance } from "services/hooks/api/prepAreaAPI";
import { formatNumber } from "helpers/numberFormatter";

const { Title } = Typography;

const qtyLimit = { out: 25, add: 25, spoil: 5, return: 5 };
const decimalPoints = 3;

const InventoryEntryBalance = ({
  params,
  setParams,
  refetchPending,
  setInventoryTemp,
  setInventory,
}) => {
  // const queryClient = useQueryClient();
  const [focusAt, setFocusAt] = useState("balance");
  const [selectedItem, setSelectedItem] = useState(null);

  const mutationUpdateInventoryBalance = useMutationUpdateInventoryBalance();
  const [isBusy, setIsbusy] = useState(false);

  useEffect(() => {
    if (params.visible) {
      setSelectedItem(params.selectedItem);
    }
  }, [params]);

  const handleOnKeyBoardPress = (v) => {
    console.log(v);
    const val = Number(v) || 0;
    let consumed = 0;
    const daytotal = selectedItem.inventory_day_total;
    const transfer = selectedItem.inventory_transfer;
    let spoil = 0;
    let balance = 0;
    let out = 0;

    if (focusAt === "balance") {
      balance = val;
      spoil = selectedItem.inventory_spoil;
      consumed = daytotal - spoil - transfer - balance;
      out = consumed;

      setSelectedItem((prevState) => ({
        ...prevState,
        inventory_balance: balance,
        inventory_spoil: spoil,
        inventory_out: out,
        inventory_consumed: consumed,
      }));
    } else if (focusAt === "spoil") {
      spoil = val;
      balance = selectedItem.inventory_balance;
      consumed = daytotal - spoil - transfer - balance;
      out = consumed;
      setSelectedItem((prevState) => ({
        ...prevState,
        inventory_balance: balance,
        inventory_spoil: spoil,
        inventory_consumed: consumed,
        inventory_out: out,
      }));
    } else if (focusAt === "out") {
      out = val;
      consumed = out;
      spoil = selectedItem.inventory_spoil;
      balance = daytotal - out - spoil - transfer;

      setSelectedItem((prevState) => ({
        ...prevState,
        inventory_balance: balance,
        inventory_spoil: spoil,
        inventory_consumed: consumed,
        inventory_out: out,
      }));
    }
  };

  const OnClose = () => {
    setParams((prevState) => ({ ...prevState, visible: false, selectedItem: null }));
    setFocusAt("balance");
    setSelectedItem(null);
    refetchPending();
    // refetchdailyInventory();
  };

  const preSave = () => {
    const spoilLimit = qtyLimit["spoil"];
    const {
      inventory_day_total,
      inventory_transfer,
      inventory_spoil,
      inventory_balance,
      inventory_consumed,
      inventory_out,
      unit,
    } = selectedItem;
    // const qty = Number(inventory_spoil) || 0;
    const spoilQty = Number(inventory_spoil) || 0;
    const balanceQty = Number(inventory_balance) || 0;
    const consumedQty = Number(inventory_consumed) || 0;
    const outQty = Number(inventory_out) || 0;

    if (inventory_consumed < 0) {
      message.error(
        <>
          <b style={{ color: "red", fontSize: 20 }}>Cosumed </b> cannot be less than{" "}
          <b style={{ color: "red", fontSize: 20 }}>Zero(0)</b>.
        </>
      );
      return false;
    }

    if (spoilQty > spoilLimit) {
      message.error(
        <>
          Maximum <b style={{ color: "red", fontSize: 20 }}>Spoil</b> must be less than or equal to{" "}
          <b style={{ color: "red", fontSize: 20 }}>
            {spoilLimit}
            {unit}
          </b>
          .
        </>
      );
      return false;
    }

    if (balanceQty < 0) {
      message.error(
        <>
          <b style={{ color: "red", fontSize: 20 }}>Balance</b> cannot be less than{" "}
          <b style={{ color: "red", fontSize: 20 }}>Zero(0)</b>.
        </>
      );
      return false;
    }

    Modal.confirm({
      width: 350,
      title: "Please check quantity. ",
      icon: <QuestionCircleOutlined style={{ color: "#2f54eb" }} />,
      content: (
        <>
          <p style={{ textAlign: "left", fontSize: 20, padding: 0, margin: 0 }}>
            Day Total:{" "}
            <b style={{ color: "red" }}>
              {formatNumber(inventory_day_total, true, decimalPoints)}{" "}
            </b>
          </p>
          <p style={{ textAlign: "left", fontSize: 20, padding: 0, margin: 0 }}>
            Transferred:{" "}
            <b style={{ color: "red" }}>{formatNumber(inventory_transfer, true, decimalPoints)} </b>
          </p>
          <p style={{ textAlign: "left", fontSize: 20, padding: 0, margin: 0 }}>
            Consumed Qty:{" "}
            <b style={{ color: "red" }}>{formatNumber(outQty, true, decimalPoints)} </b>
          </p>

          <p style={{ textAlign: "left", fontSize: 20, padding: 0, margin: 0 }}>
            Spoil Qty:{" "}
            <b style={{ color: "red" }}>{formatNumber(spoilQty, true, decimalPoints)} </b>
          </p>
          <p style={{ textAlign: "left", fontSize: 20, padding: 0, margin: 0 }}>
            Balance Qty:{" "}
            <b style={{ color: "red" }}>{formatNumber(balanceQty, true, decimalPoints)} </b>
          </p>

          <Divider style={{ margin: 0, marginTop: 5, marginBottom: 5 }} />
          <p style={{ textAlign: "left", fontSize: 20, padding: 0, margin: 0 }}>
            Out Qty:{" "}
            <b style={{ color: "red" }}>{formatNumber(consumedQty, true, decimalPoints)} </b>
          </p>

          <Divider style={{ margin: 0, marginTop: 15, marginBottom: 10 }} />
          <span style={{ fontSize: 15, fontWeight: 600 }}>Are you sure?</span>
        </>
      ),
      okText: "Yes, I'm sure!",
      cancelText: "No",
      onOk: OnSave,
    });
  };

  const OnSave = () => {
    setIsbusy(true);

    const finalData = {
      item_id: selectedItem.item_id,
      data: {
        branch_id: params.branch_id,
        inventory_cutoff: params.inventory_cutoff,
        itemData: { ...selectedItem },
      },
    };

    mutationUpdateInventoryBalance.mutate(finalData, {
      onError: (error) => {
        setIsbusy(false);
        message.error("Something went wrong :/");
        // An error happened!
        console.log(`Error:  ${error.message}`);
      },

      onSuccess: (data) => {
        setIsbusy(false);
        message.success(data.message);
        setInventoryTemp((prevState) =>
          prevState.map((obj) => {
            if (obj.item_id === selectedItem.item_id) {
              return selectedItem;
            } else {
              return obj;
            }
          })
        );

        setInventory((prevState) =>
          prevState.map((obj) => {
            if (obj.item_id === selectedItem.item_id) {
              return selectedItem;
            } else {
              return obj;
            }
          })
        );
        // queryClient.setQueryData(
        //   [
        //     "prep-daily-inventory",
        //     {
        //       branch_id: params.branch_id,
        //       inventory_cutoff: params.inventory_cutoff,
        //     },
        //   ],
        //   (old) => old.map((i) => (i.item_id === selectedItem.item_id ? selectedItem : i))
        // );
        OnClose();
      },
    });
  };

  const handleSpoilFocus = () => {
    setFocusAt("spoil");
  };

  const handleBalanceFocus = () => {
    setFocusAt("balance");
  };

  const handleOutFocus = () => {
    setFocusAt("out");
  };

  if (!selectedItem) {
    return null;
  } else {
    return (
      <>
        <Drawer
          title={
            <Title level={3} style={{ marginTop: 8 }}>
              {selectedItem.item_name} ({selectedItem.unit})
            </Title>
          }
          headerStyle={{
            padding: 4,
            paddingLeft: 20,
            marginRight: 9,
          }}
          closable={true}
          visible={params.visible}
          width={350}
          onClose={OnClose}
          destroyOnClose={true}
        >
          <Spin spinning={isBusy}>
            <Descriptions bordered size="small" column={1}>
              <Descriptions.Item
                label="Begin"
                style={{
                  fontWeight: 600,
                  border: "1px solid #d9d9d9",
                }}
                contentStyle={{
                  textAlign: "right",
                  fontWeight: 600,
                }}
              >
                {formatNumber(selectedItem.inventory_begin, true, decimalPoints)}
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <>
                    <PlusCircleOutlined /> Add
                  </>
                }
                style={{
                  fontWeight: 600,
                  border: "1px solid #d9d9d9",
                }}
                contentStyle={{
                  textAlign: "right",
                  fontWeight: 600,
                }}
              >
                {formatNumber(selectedItem.inventory_add, true, decimalPoints)}
              </Descriptions.Item>
              <Descriptions.Item
                label="Day Total"
                style={{
                  fontWeight: 600,
                  border: "1px solid #d9d9d9",
                }}
                contentStyle={{
                  textAlign: "right",
                  fontWeight: 600,
                }}
              >
                {formatNumber(selectedItem.inventory_day_total, true, decimalPoints)}
              </Descriptions.Item>

              <Descriptions.Item
                label={
                  <>
                    <MinusCircleOutlined /> Transfer
                  </>
                }
                style={{
                  fontWeight: 600,
                  border: "1px solid #d9d9d9",
                }}
                contentStyle={{
                  textAlign: "right",
                  fontWeight: 600,
                }}
              >
                {formatNumber(selectedItem.inventory_transfer, true, decimalPoints)}
              </Descriptions.Item>

              <Descriptions.Item
                label={
                  <>
                    <MinusCircleOutlined /> Out
                  </>
                }
                style={{ ...style.descriptionText, backgroundColor: "#096dd9", color: "white" }}
                contentStyle={{
                  textAlign: "right",
                  fontWeight: 600,
                }}
              >
                <InputNumber
                  size="large"
                  onFocus={handleOutFocus}
                  onChange={handleOnKeyBoardPress}
                  value={selectedItem.inventory_out}
                />
              </Descriptions.Item>

              <Descriptions.Item
                label={
                  <>
                    <MinusCircleOutlined /> Spoil
                  </>
                }
                style={{ ...style.descriptionText, backgroundColor: "#096dd9", color: "white" }}
                contentStyle={{
                  textAlign: "right",
                  fontWeight: 600,
                }}
              >
                <InputNumber
                  size="large"
                  onFocus={handleSpoilFocus}
                  onChange={handleOnKeyBoardPress}
                  value={selectedItem.inventory_spoil}
                />
              </Descriptions.Item>

              <Descriptions.Item
                label="Balance"
                style={{ ...style.descriptionText, backgroundColor: "#0050b3", color: "white" }}
                contentStyle={{
                  textAlign: "right",
                  fontWeight: 600,
                }}
              >
                <InputNumber
                  size="large"
                  onFocus={handleBalanceFocus}
                  onChange={handleOnKeyBoardPress}
                  value={selectedItem.inventory_balance}
                />
              </Descriptions.Item>
              <Divider />
              <Descriptions.Item
                label="Consumed"
                style={{
                  ...style.descriptionText,
                  backgroundColor: "#f5222d",
                  color: "white",
                  padding: 12,
                }}
                contentStyle={{
                  textAlign: "right",
                  fontWeight: 600,
                }}
              >
                {formatNumber(selectedItem.inventory_out, true, decimalPoints)}
              </Descriptions.Item>
            </Descriptions>

            <Divider />
            <Row gutter={10}>
              <Col span={12}>
                <Button
                  loading={isBusy}
                  onClick={OnClose}
                  block
                  size="large"
                  icon={<CloseOutlined />}
                >
                  Cancel
                </Button>
              </Col>

              <Col span={12}>
                <Button
                  type="primary"
                  onClick={preSave}
                  loading={isBusy}
                  size="large"
                  icon={<CheckOutlined />}
                  block
                >
                  Ok
                </Button>
              </Col>
            </Row>
          </Spin>
        </Drawer>
      </>
    );
  }
};

export default InventoryEntryBalance;
const textContainer = {
  width: "100%",
  height: 100,
  padding: "20px",
  border: "2px solid #85a5ff",
  // marginTop: 10,
  marginBottom: 10,
  textAlign: "center",
  borderRadius: 10,
  position: "relative",
};

const style = {
  descriptionText: {
    // fontSize: 13,
    padding: 4,
    paddingLeft: 10,
    paddingRight: 10,

    // textAlign: "center"
  },
};
