import React, { useContext } from "react";
import { SocketContext } from "services/contexts/socket";
import { Drawer, Descriptions, Card, Button, Space } from "antd";
import { PrinterOutlined, CloseOutlined } from "@ant-design/icons";
import { useGetItemCutoffInventory } from "services/hooks/api/prepAreaAPI";
import ContentLoader from "common/ContentLoader";
import RetryMessage from "common/RetryMessage";
import moment from "moment";

const InventoryItem = ({ params, setParams }) => {
  const socket = useContext(SocketContext);
  const { isLoading, data, isError, error, refetch } = useGetItemCutoffInventory(params);

  const OnClose = () => {
    setParams((prevState) => ({ ...prevState, visible: false, item_id: null }));
  };

  const handlePrint = () => {
    const branchDetails = Object.keys(params).reduce((object, key) => {
      if (!["selectedItem", "visible", "item_id"].includes(key)) {
        object[key] = params[key];
      }
      return object;
    }, {});
    const printData = {
      ...branchDetails,
      data,
    };
    socket.emit("printDaily", { ...printData });
    // console.log(printData);
  };

  return (
    <Drawer
      title={
        data && (
          <>
            {data.item_name} ({data.unit})
          </>
        )
      }
      closable={true}
      visible={params.visible}
      onClose={OnClose}
      destroyOnClose={true}
    >
      {isLoading ? (
        <ContentLoader />
      ) : isError ? (
        <Card>
          <RetryMessage message={error.message} onRetry={() => refetch()} />
        </Card>
      ) : data ? (
        <>
          <Descriptions bordered size="small" column={1}>
            <Descriptions.Item
              label="Branch"
              style={{
                fontWeight: 600,
                border: "1px solid #d9d9d9",
                // borderTop: "1px solid #d9d9d9",
              }}
              contentStyle={{
                textAlign: "right",
                fontWeight: 600,
              }}
            >
              {params.branch_name}
            </Descriptions.Item>
            <Descriptions.Item
              label="Inventory CutOff"
              style={{
                fontWeight: 600,
                border: "1px solid #d9d9d9",
                // borderTop: "1px solid #d9d9d9",
              }}
              contentStyle={{
                textAlign: "right",
                fontWeight: 600,
              }}
            >
              {params.inventory_cutoff}
            </Descriptions.Item>

            <Descriptions.Item
              label="Time Checked"
              style={{
                fontWeight: 600,
                border: "1px solid #d9d9d9",
                // borderTop: "1px solid #d9d9d9",
              }}
              contentStyle={{
                textAlign: "right",
                fontWeight: 600,
              }}
            >
              {moment().format("hh:mma")}
            </Descriptions.Item>

            <Descriptions.Item
              label="Begin"
              contentStyle={{ ...style.descriptionText, textAlign: "right" }}
            >
              {data.inventory_begin}
            </Descriptions.Item>
            <Descriptions.Item
              label="Add"
              contentStyle={{ ...style.descriptionText, textAlign: "right" }}
            >
              {data.inventory_add}
            </Descriptions.Item>
            <Descriptions.Item
              label="Day Total"
              contentStyle={{ ...style.descriptionText, textAlign: "right" }}
            >
              {data.inventory_day_total}
            </Descriptions.Item>
            <Descriptions.Item
              label="Return"
              contentStyle={{ ...style.descriptionText, textAlign: "right" }}
            >
              {data.inventory_return}
            </Descriptions.Item>
            <Descriptions.Item
              label="Out"
              contentStyle={{ ...style.descriptionText, textAlign: "right" }}
            >
              {data.inventory_out}
            </Descriptions.Item>
            <Descriptions.Item
              label="Spoil"
              contentStyle={{ ...style.descriptionText, textAlign: "right" }}
            >
              {data.inventory_spoil}
            </Descriptions.Item>
            <Descriptions.Item
              label="Balance"
              style={{ ...style.descriptionText, backgroundColor: "#2f54eb", color: "white" }}
              contentStyle={{
                textAlign: "right",
              }}
            >
              {data.inventory_balance}
            </Descriptions.Item>

            <Descriptions.Item
              label="Consumed"
              style={{ ...style.descriptionText, backgroundColor: "#10239e", color: "white" }}
              contentStyle={{
                textAlign: "right",
              }}
            >
              {data.inventory_consumed}
            </Descriptions.Item>
          </Descriptions>
          <Space style={{ marginTop: 12 }}>
            <Button type="primary" icon={<PrinterOutlined />} onClick={handlePrint}>
              Print
            </Button>
            <Button type="secondary" icon={<CloseOutlined />} onClick={OnClose}>
              Close
            </Button>
          </Space>
        </>
      ) : null}
    </Drawer>
  );
};
export default InventoryItem;

const style = {
  descriptionText: {
    // fontSize: 13,
    padding: 4,
    paddingLeft: 10,
    paddingRight: 10,

    // textAlign: "center"
  },
};
