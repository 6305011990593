import { useQuery, useMutation } from "react-query";
import http from "services/httpService";

// async function getItems({ queryKey }) {
//     const [_, searchValue] = queryKey;
//     const { data } = await http.get(`items/dynamicSearch/${searchValue}`);
//     return data;
// }
// function useGetItems(searchValue) {
//     return useQuery(["items", searchValue], getItems);
// }

async function getTransactions({ queryKey }) {
  const { branch_id, cutoff } = queryKey[1];
  const { data } = await http.get(`transactions/${branch_id}/${cutoff}`);
  return data;
}
function useGetTransactions(filters) {
  return useQuery(["transactions", filters], getTransactions);
}

async function getUsedTables({ queryKey }) {
  const { branch_id, cutoff } = queryKey[1];
  const { data } = await http.get(`transactions/used-tables/${branch_id}/${cutoff}`);
  return data;
}
function useGetUsedTables(filters) {
  return useQuery(["used-tables", filters], getUsedTables);
}

async function getTransactionSummary({ queryKey }) {
  const { branch_id, cutoff } = queryKey[1];
  const { data } = await http.get(`transactions/summary/${branch_id}/${cutoff}`);
  return data;
}
function useGetTransactionSummary(filters) {
  return useQuery(["transaction-summary", filters], getTransactionSummary);
}
// -------------------------------------- Mutation
async function insertTransaction(data) {
  const { data: transactionDetails } = await http.post(`/transaction`, data);
  return transactionDetails;
}

function useMutationInsertTransaction() {
  return useMutation(insertTransaction);
}

async function updateTransaction(data) {
  const { data: transactionDetails } = await http.put(
    `/transactions/update/${data.transaction.transaction_id}`,
    data
  );
  return transactionDetails;
}

function useMutationUpdateTransaction() {
  return useMutation(updateTransaction);
}

async function updateTransactionBasic(data) {
  const { data: transactionDetails } = await http.put(
    `/transactions/update-basic/${data.transaction_id}`,
    data
  );
  return transactionDetails;
}

function useMutationUpdateTransactionBasic() {
  return useMutation(updateTransactionBasic);
}

async function storeCutOff(data) {
  const { data: cutOffDatails } = await http.post(`/transaction/cutoff`, data);
  return cutOffDatails;
}

function useMutationStoreCutOff() {
  return useMutation(storeCutOff);
}

async function storeDenomination(data) {
  const { data: denominationDetails } = await http.post(`/transaction/denomination`, data);
  return denominationDetails;
}

function useMutationStoreDenomination() {
  return useMutation(storeDenomination);
}

export {
  useGetTransactions,
  useGetUsedTables,
  useGetTransactionSummary,
  useMutationInsertTransaction,
  useMutationUpdateTransaction,
  useMutationUpdateTransactionBasic,
  useMutationStoreCutOff,
  useMutationStoreDenomination,
};
