import React, { useState, useEffect, useContext } from "react";
import { SocketContext } from "services/contexts/socket";
import {
  Typography,
  Divider,
  Space,
  Button,
  Drawer,
  Collapse,
  Table,
  Input,
  Descriptions,
  InputNumber,
  Row,
  Col,
  Spin,
  message,
  Modal,
} from "antd";

import {
  SearchOutlined,
  CheckOutlined,
  PlusOutlined,
  SaveOutlined,
  DeleteOutlined,
  CloseOutlined,
  WarningOutlined,
} from "@ant-design/icons";

import whichOS from "helpers/whichOS";
import {
  useMutationStoreInventoryTranferItem,
  useMutationUpdateInventoryTranferItem,
  useMutationDeleteInventoryTranferItem,
} from "services/hooks/api/inventoryTransferAPI";
import { formatNumber } from "helpers/numberFormatter";

const { Text } = Typography;
const ItemSelection = ({
  items,
  visibility,
  setVisibility,
  queryKeys,
  user_id,
  setTransferList,
  isEdit,
  setIsEdit,
  selectedList,
  setSelectedList,
  branches,
}) => {
  const mutationStoreInventoryTranferItem = useMutationStoreInventoryTranferItem();
  const mutationUpdateInventoryTranferItem = useMutationUpdateInventoryTranferItem();
  const mutationDeleteInventoryTranferItem = useMutationDeleteInventoryTranferItem();

  const [dataSource, setDataSource] = useState([]);
  const [iscollapse, setIsCollapse] = useState([0]);
  const [item, setItem] = useState(null);
  const [isBusy, setIsBusy] = useState(false);

  const socket = useContext(SocketContext);
  useEffect(() => {
    if (items) {
      setDataSource(items);
    }
  }, [items]);

  useEffect(() => {
    if (isEdit && selectedList) {
      setItem(selectedList);
    }
  }, [isEdit, selectedList]);

  const handleClose = () => {
    setIsEdit(false);
    setItem(null);
    setSelectedList(null);
    setVisibility(false);
  };

  const handleSearchOnChange = (e) => {
    e.preventDefault();
    const value = e.target.value;
    // setSearchValue(value);
    setDataSource(items.filter((obj) => obj.item_name.toLowerCase().includes(value.toLowerCase())));
  };

  const handleOnFocus = () => {
    setIsCollapse([1]);
  };
  const handleOnBlur = () => {
    setIsCollapse([0]);
    // setSearchValue("");
  };

  const handleOnSelect = (e, r) => {
    const d = new Date();
    const timeStamp = d.getTime();

    setItem({
      ...r,
      time_stamp: timeStamp,
      transfer_quantity: 1,
      received_quantity: 1,
      descrepancy: 0,
      branch_id: queryKeys.branch_id,
      inventory_cutoff: queryKeys.inventory_cutoff,
      from_branch_id: queryKeys.from_branch_id,
      user_id,
      status: "pending",
    });
  };

  const handleOnQtyChange = (value) => {
    // // const value = e.target.value;
    const newQty = Number(value) || 1;
    setItem({ ...item, transfer_quantity: newQty, received_quantity: newQty });
  };

  const handleSave = () => {
    if (!item) {
      message.error("No selected item.");
      return false;
    }
    setIsBusy(true);
    if (!isEdit) {
      const finalStoreData = Object.keys(item).reduce((object, key) => {
        if (!["group_name", "item_name", "branch_name", "unit", "qty"].includes(key)) {
          object[key] = item[key];
        }
        return object;
      }, {});

      mutationStoreInventoryTranferItem.mutate(finalStoreData, {
        onError: (error) => {
          message.error("Something went wrong :/");
          // An error happened!
          console.log(`Error:  ${error.message}`);
          setIsBusy(false);
        },
        onSuccess: (data) => {
          if (data.error) {
            if (data.transferData) {
              Modal.warning({
                width: 350,
                title: data.message,
                icon: <WarningOutlined />,
                content: (
                  <>
                    <p style={{ textAlign: "left", fontSize: 18, padding: 0, margin: 0 }}>
                      Balance:{" "}
                      <b style={{ color: "red" }}>
                        {formatNumber(data.transferData.invetoryBalance, true, 2)}{" "}
                      </b>
                    </p>
                    <p style={{ textAlign: "left", fontSize: 18, padding: 0, margin: 0 }}>
                      Pending:{" "}
                      <b style={{ color: "red" }}>
                        {formatNumber(data.transferData.pendingTransfer, true, 3)}
                      </b>
                    </p>
                    <p style={{ textAlign: "left", fontSize: 18, padding: 0, margin: 0 }}>
                      Transfer Qty :{" "}
                      <b style={{ color: "red" }}>
                        {formatNumber(data.transferData.transfer_quantity, true, 3)}
                      </b>
                    </p>
                  </>
                ),
              });

              // message.error(
              //   <>
              //     <b>{data.message}</b> - Balace: {data.transferData.invetoryBalance} Pending:{" "}
              //     {data.transferData.totalPending}, Transfer Qty:{" "}
              //     {data.transferData.transfer_quantity}
              //   </>
              // );
            } else {
              message.error(data.message);
            }
            setIsBusy(false);
          } else {
            message.success(data.message);
            const b = branches.filter((branch) => branch.branch_id === queryKeys.from_branch_id);
            const br = b[0] ? b[0].branch_name : "";
            setTransferList((prevData) => [...prevData, { ...item, branch_name: br }]);
            setItem(null);
            setIsBusy(false);
            socket.emit("updateTransfer", { room: "gogi" });
          }
        },
      });
    } else {
      const finalUpdateData = Object.keys(item).reduce((object, key) => {
        if (!["group_name", "item_name", "branch_name", "unit"].includes(key)) {
          object[key] = item[key];
        }
        return object;
      }, {});

      mutationUpdateInventoryTranferItem.mutate(finalUpdateData, {
        onError: (error) => {
          message.error("Something went wrong :/");
          // An error happened!
          console.log(`Error:  ${error.message}`);
          setIsBusy(false);
        },
        onSuccess: (data) => {
          message.success(data.message);
          setTransferList((prevData) =>
            prevData.map((prev) => (prev.time_stamp === item.time_stamp ? item : prev))
          );
          socket.emit("updateTransfer", { room: "gogi" });
          setIsBusy(false);
          handleClose();
        },
      });
    }
  };

  const handleDelete = () => {
    if (!item) {
      message.error("No selected item.");
      return false;
    }
    setIsBusy(true);
    mutationDeleteInventoryTranferItem.mutate(item, {
      onError: (error) => {
        message.error("Something went wrong :/");
        // An error happened!
        console.log(`Error:  ${error.message}`);
        setIsBusy(false);
      },
      onSuccess: (data) => {
        message.success(data.message);
        setTransferList((prevData) =>
          prevData.filter((prev) => prev.time_stamp !== item.time_stamp)
        );
        socket.emit("updateTransfer", { room: "gogi" });
        setIsBusy(false);
        handleClose();
      },
    });
  };
  const columns = [
    {
      title: "Item",
      dataIndex: "item_name",
      render: (_, record) => <Text style={{ color: "#002766" }}>{record.item_name}</Text>,
    },

    {
      title: "Group",
      dataIndex: "selling_price",
      align: "right",
      width: 100,
      render: (_, record) => (
        <Text italic style={{ paddingRight: 10 }}>
          {record.group_name}
        </Text>
      ),
    },
  ];

  return (
    <Drawer
      headerStyle={{ padding: isEdit ? 10 : 0, margin: isEdit ? 10 : 0 }}
      title={
        isEdit ? (
          <Text>Edit item</Text>
        ) : (
          <>
            <Divider orientation="left" plain style={{ margin: 0, marginTop: 16, padding: 0 }}>
              Add Item
            </Divider>
            <Collapse accordion bordered={false} activeKey={iscollapse} ghost>
              <Collapse.Panel
                header={
                  <Input
                    placeholder="Search here..."
                    size="large"
                    allowClear
                    style={{ width: "100%" }}
                    onChange={handleSearchOnChange}
                    // onKeyUp={handleSearchOnChange}
                    // value={searchValue}
                    prefix={
                      <Space>
                        <SearchOutlined style={{ marginRight: 20 }} />
                      </Space>
                    }
                    onFocus={handleOnFocus}
                    onBlur={handleOnBlur}
                  />
                }
                key={1}
                showArrow={false}
              >
                <Table
                  rowKey="item_id"
                  dataSource={dataSource}
                  columns={columns}
                  size="small"
                  pagination={false}
                  scroll={{
                    y: 200,
                  }}
                  onRow={(record) => {
                    return {
                      onClick: (event) => {
                        handleOnSelect(event, record);
                      },
                    };
                  }}
                />
              </Collapse.Panel>
            </Collapse>
          </>
        )
      }
      width={whichOS !== "Linux" ? 420 : 320}
      placement="right"
      closable={false}
      visible={visibility}
      bodyStyle={{ padding: "4px 12px" }}
    >
      <Spin spinning={isBusy}>
        <Divider orientation="left" plain style={{ margin: 6 }}>
          Item Details
        </Divider>

        <Descriptions bordered size="small" style={{ marginBottom: 12 }} column={1}>
          <Descriptions.Item
            label={
              <b>
                <CheckOutlined style={{ color: "green" }} />{" "}
                {item ? item.item_name : <Text italic>None</Text>}
              </b>
            }
            labelStyle={{ width: 250 }}
            contentStyle={{
              fontSize: 16,
            }}
          >
            {item ? item.group_name : <Text italic>None</Text>}
          </Descriptions.Item>

          <Descriptions.Item
            label={<b>Transfer Qty</b>}
            labelStyle={{
              paddingLeft: 30,
              width: 50,
              borderRadius: 6,
              border: "2px solid #69c0ff",
              borderStyle: "dashed",
              borderRight: "none",
            }}
            contentStyle={{
              borderRadius: 6,
              border: "2px solid #69c0ff",
              borderStyle: "dashed",
              borderLeft: "none",
            }}
          >
            <InputNumber
              // min={1}
              // max={30}
              value={item ? item.transfer_quantity : 1}
              style={{
                fontSize: 18,
                padding: 2,
                minWidth: 130,
                textAlign: "center",
              }}
              onChange={handleOnQtyChange}
              disabled={!item}
            />
          </Descriptions.Item>
        </Descriptions>

        <Row gutter={10}>
          <Col flex={2}>
            <Button
              type="primary"
              block
              size="large"
              onClick={handleSave}
              icon={isEdit ? <SaveOutlined /> : <PlusOutlined />}
            >
              {isEdit ? "Update" : "Add"}
            </Button>
          </Col>

          <Col flex={2}>
            <Button
              block
              size="large"
              onClick={handleClose}
              icon={isEdit ? <CloseOutlined /> : <CheckOutlined />}
            >
              {isEdit ? "Cancel" : "Done"}
            </Button>
          </Col>
        </Row>
        <Button
          block
          size="large"
          danger
          disabled={isEdit ? false : true}
          onClick={handleDelete}
          icon={<DeleteOutlined />}
          style={{ marginTop: 10 }}
        >
          Remove
        </Button>
      </Spin>
    </Drawer>
  );
};

export default ItemSelection;
