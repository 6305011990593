import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isSiderCollapsed: false,
    isSiderInDrawerCollapsed: false,
    pageTheme: "default",
    colorTheme: "blue"
};

const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        toggleSiderCollapse(state) {
            state.isSiderCollapsed = !state.isSiderCollapsed;
        },
        toggleSiderInDrawerCollapse(state) {
            state.isSiderInDrawerCollapsed = !state.isSiderInDrawerCollapsed;
        },
        setPageTheme(state, action) {
            state.pageTheme = action.payload.pageTheme;
        },
        setColorTheme(state, action) {
            state.colorTheme = action.payload.colorTheme;
        }
    }
});

export const {
    toggleSiderCollapse,
    toggleSiderInDrawerCollapse,
    setPageTheme,
    setColorTheme
} = appSlice.actions;

export default appSlice.reducer;
