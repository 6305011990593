import React from "react";
import { formatNumber } from "helpers/numberFormatter";
import { Line } from "@ant-design/plots";
import moment from "moment";

const AdminGraph = ({
  data = [],
  height = 200,
  xField,
  yField,
  seriesField,
  isCurrency = true,
}) => {
  const config = {
    data,
    height,
    xField,
    yField,
    seriesField,
    xAxis: {
      label: {
        formatter: (v) => moment(v).format("MMM DD"),
      },
    },
    yAxis: {
      label: {
        formatter: (v) => formatNumber(v, isCurrency),
      },
    },
    // smooth: true,
    // @TODO 后续会换一种动画方式
    // animation: {
    //   appear: {
    //     animation: "path-in",
    //     duration: 5000,
    //   },
    // },
    point: {
      size: 2,
      shape: "circle",
      style: {
        fill: "#5B8FF9",
        stroke: "#5B8FF9",
        lineWidth: 2,
      },
    },
  };
  return (
    <>
      <Line {...config} />
    </>
  );
};

export default AdminGraph;
