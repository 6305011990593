import React from "react";
import { LoadingOutlined } from "@ant-design/icons";

const ContentLoader = () => {
    return (
        <div>
            <div
                style={{
                    marginTop: 30,
                    textAlign: "center",
                    fontSize: "3rem"
                }}
            >
                <LoadingOutlined />
            </div>
            <div style={{ textAlign: "center" }}>Loading ...</div>
        </div>
    );
};

export default ContentLoader;
