import React, { useState, useEffect, useContext } from "react";
import { SocketContext } from "../services/contexts/socket";
import { useNavigate, Outlet } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Layout, Spin } from "antd";

import Header from "./Header";
import OffSetDiv from "common/OffSetDiv";

import Footer from "./Footer";
import RetryMessage from "common/RetryMessage";
import whichOS from "helpers/whichOS";
import { setUserDetails } from "services/redux/slices/user";

import { useGetSystemInitData } from "services/hooks/api/systemInitDataApi";
import { useCheckUserSession } from "services/hooks/api/authApi";
// import { useCheckUserSession, useIPChecker } from "services/hooks/api/authApi";
const { Content } = Layout;

const Main = () => {
  const socket = useContext(SocketContext);
  const [isAppInit, setIsAppInit] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const checkUserSessionResponse = useCheckUserSession();
  // const ipCheckerResponse = useIPChecker({ enabled: true });
  const systemInitDataResponse = useGetSystemInitData({
    enabled: checkUserSessionResponse.data?.isUserAuthenticated || false,
  });

  // useEffect(() => {

  //   // if (ipCheckerResponse.isSuccess && !isAppInit && roleID && roleID !== 1) {
  //   if (ipCheckerResponse.isSuccess) {
  //     console.log("test");
  //     const { data: ipCheckerData } = ipCheckerResponse;
  //     if (ipCheckerData.error) {
  //       console.log(ipCheckerData.ip, ipCheckerData.message);
  //       navigate(`/ip-chekcer-error/${ipCheckerData.ip}/${ipCheckerData.message}`);
  //     }
  //   }
  //   //eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [ipCheckerResponse.isSuccess]);

  useEffect(() => {
    if (checkUserSessionResponse.isSuccess) {
      const { isUserAuthenticated } = checkUserSessionResponse.data;
      if (!isUserAuthenticated) {
        navigate("/login");
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkUserSessionResponse.isSuccess]);

  useEffect(() => {
    if (systemInitDataResponse.isSuccess) {
      const userDetails = systemInitDataResponse.data;
      dispatch(
        setUserDetails({
          userDetails,
        })
      );
      // ------------------ enable this on productions  Device Trap
      // if (!userDetails.allowed_devices.includes(whichOS)) {
      //   navigate("/403");
      // }

      socket.on("connect", () => {
        socket.emit("addUser", { userId: userDetails.user_id, room: "gogi" });
      });

      socket.emit("addUser", { userId: userDetails.user_id, room: "gogi" });
      setIsAppInit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [systemInitDataResponse.isSuccess]);

  if (checkUserSessionResponse.isError) {
    const { error, refetch } = checkUserSessionResponse;
    return (
      <Layout style={{ minHeight: "calc(100vh)" }}>
        <RetryMessage message={error.message} onRetry={() => refetch()} />
      </Layout>
    );
  }

  if (systemInitDataResponse.isError) {
    const { error, refetch } = systemInitDataResponse;
    return (
      <Layout style={{ minHeight: "calc(100vh)" }}>
        <RetryMessage message={error.message} onRetry={() => refetch()} />
      </Layout>
    );
  }

  if (isAppInit) {
    return (
      <Layout style={{ minHeight: "calc(100vh)" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "calc(100vh)",
          }}
        >
          <Spin spinning={true} tip="loading ..." />
        </div>
      </Layout>
    );
  }

  return (
    <Layout style={{ minHeight: "calc(100vh)" }}>
      {/* <Sider /> */}
      <Layout>
        <Header />
        <OffSetDiv />
        <Content
        // style={{
        //     marginTop: location && location.pathname === "/admin-page" ? 60 : role_id !== 3 ? 80 : 60,
        // }}
        >
          <Outlet />
          {/* {children} */}
        </Content>
        {whichOS === "Win" ? <Footer /> : null}
      </Layout>
    </Layout>
  );
};

export default Main;
