import React, { useState, useEffect } from "react";

import {
  Table,
  Card,
  Space,
  Input,
  Tooltip,
  Button,
  Row,
  Col,
  Typography,
  Form,
  Modal,
  Divider,
  message,
} from "antd";
import {
  LoadingOutlined,
  PlusOutlined,
  SearchOutlined,
  RedoOutlined,
  KeyOutlined,
  EditOutlined,
  UserAddOutlined,
} from "@ant-design/icons";

import { useGetUsers, useMutationUpdateUserPassword } from "services/hooks/api/userAPI";
import { generateString } from "helpers/randomStringGenerator";
import FormUser from "./components/FormUser";

import RetryMessage from "common/RetryMessage";
const defaultValue = {
  visible: false,
  isEdit: false,
  user: null,
};

const defaultValue2 = {
  visibility: false,
  user: null,
};

const { Text } = Typography;
const Users = () => {
  const [formChangePass] = Form.useForm(); // other details form
  const [searchValue, setSearchValue] = useState(null);
  const [users, setUsers] = useState([]);
  const [usersTemp, setUsersTemp] = useState([]);
  const [branchFilter, setBranchFilter] = useState([]);
  const [otherData, setOtherData] = useState({ role: [], branch: [] });
  const [formUserParams, setFormUserParams] = useState(defaultValue);
  const [changePassModal, setChangePassModal] = useState(defaultValue2);
  const { isLoading, data, refetch, error, isError } = useGetUsers({ searchValue: null });
  const mutationUpdateUserPassword = useMutationUpdateUserPassword();

  useEffect(() => {
    if (!isLoading && data) {
      setUsers(data.users);
      setUsersTemp(data.users);
      setOtherData(data.otherData);
      setBranchFilter(
        data.otherData.branch.map((b) => {
          return { text: b.branch_name, value: b.branch_name };
        })
      );
    }
  }, [isLoading, data]);

  const columns = [
    {
      title: "User",
      key: "branch_name",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record, index) => (
        <Text>
          <Text delete={record.status === "inactive" ? true : false}>{record.full_name} </Text>
          <br></br>
          <Text italic style={{ fontSize: 11, color: "#096dd9" }}>
            <b>Branch:</b> {record.branch_name} <br></br>
            <UserAddOutlined style={{ color: "#fa541c" }} /> {record.role_name}
          </Text>
        </Text>
      ),
      filters: branchFilter,
      defaultFilteredValue: [], //[],
      onFilter: (value, record) => record.branch_name.indexOf(value) === 0,
    },

    {
      title: "Actions",
      key: "actions",
      align: "right",
      // width: 80,
      render: (text, record) => (
        <Space>
          <Button shape="circle" icon={<EditOutlined />} onClick={() => handleRowclick(record)} />
          <Button
            shape="circle"
            icon={<KeyOutlined />}
            onClick={() => {
              setChangePassModal({
                visibility: true,
                user: record,
              });
              formChangePass.setFieldsValue({
                password: generateString(6, "", "123765098234716381236"),
              });
            }}
          />

          {/* <Button icon={<KeyOutlined />} /> */}
        </Space>
      ),
      filters: [
        { text: "Active", value: "active" },
        { text: "In-Active", value: "inactive" },
      ],
      defaultFilteredValue: ["active"], //[],
      onFilter: (value, record) => record.status.indexOf(value) === 0,
    },
  ];

  const handleSearchOnChange = (e) => {
    const value = e.target.value;
    console.log(value);
    setSearchValue(value);
    setUsersTemp(users.filter((obj) => obj.full_name.toLowerCase().includes(value.toLowerCase())));
  };

  const handleRowclick = (record) => {
    setFormUserParams({ visible: true, user: record, isEdit: true });
  };

  const handleNew = () => {
    setFormUserParams({ visible: true, user: null, isEdit: false });
  };

  if (isError) {
    return (
      <Card>
        <RetryMessage message={error.message} onRetry={() => refetch()} />
      </Card>
    );
  }

  const handleRefresh = () => {
    refetch();
    setSearchValue(null);
  };

  const handleChangePassOk = () => {
    formChangePass.validateFields().then(async (values) => {
      try {
        mutationUpdateUserPassword.mutate(
          { user_id: changePassModal.user.user_id, password: values.password },
          {
            onError: (error) => {
              message.error("Something went wrong :/");
              console.log(`Error:  ${error.message}`);
            },
            onSuccess: (data) => {
              handleChangePassCancel();
              message.success(data.message);
            },
          }
        );
      } catch (error) {
        message.error("Something went wrong.");
        console.log(error.message);
      }
    });
  };
  const handleChangePassCancel = () => {
    formChangePass.resetFields();
    setChangePassModal({
      visibility: false,
      user: null,
    });
  };

  const genPass = () => {
    formChangePass.setFieldsValue({
      password: generateString(6, "", "123765098234716381236"),
    });
  };

  if (data && !isError) {
    return (
      <>
        <Card bodyStyle={{ padding: "20px 14px" }}>
          <Row justify="space-between">
            <Col>
              {" "}
              <Input
                placeholder="Search user"
                size="large"
                allowClear
                style={{ paddingLeft: 20, width: 220 }}
                // onPressEnter={handlesOnPressEnter}
                onChange={handleSearchOnChange}
                value={searchValue}
                prefix={
                  <Space>
                    <SearchOutlined style={{ marginRight: 12 }} />
                  </Space>
                }
                suffix={isLoading && <LoadingOutlined />}
              />
            </Col>
            <Col>
              {" "}
              <Space style={{ marginTop: 5 }}>
                <Tooltip title="Add New Card">
                  <Button
                    type="primary"
                    shape="circle"
                    icon={<PlusOutlined />}
                    onClick={handleNew}
                  />
                </Tooltip>
                <Tooltip title="Refresh">
                  <Button
                    type="dashed"
                    shape="circle"
                    icon={<RedoOutlined />}
                    onClick={handleRefresh}
                  />
                </Tooltip>
              </Space>
            </Col>
          </Row>

          <Table
            loading={isLoading}
            rowKey="user_id"
            dataSource={usersTemp}
            columns={columns}
            style={{ marginTop: 10 }}
            size="small"
            // onRow={(record, rowIndex) => {
            //   return {
            //     onClick: (e) => {
            //       handleRowclick(record);
            //     }, // click row
            //   };
            // }}
            // onChange={handleTableOnChange}
          />

          <FormUser
            params={formUserParams}
            setParams={setFormUserParams}
            otherData={otherData}
            setUsers={setUsers}
            setUsersTemp={setUsersTemp}
          />
        </Card>
        <Modal
          title="Change Password"
          visible={changePassModal.visibility}
          onOk={handleChangePassOk}
          onCancel={handleChangePassCancel}
          closable={false}
        >
          <Form {...layout} form={formChangePass}>
            <Divider orientation="center" plain style={{ padding: 0, margin: 0 }}>
              {changePassModal.user && changePassModal.user.full_name}
            </Divider>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Password required",
                },
              ]}
            >
              <Input.Password
                maxLength={6}
                addonAfter={
                  <Button size="small" onClick={genPass}>
                    Generate{" "}
                  </Button>
                }
              />
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  }
};

export default Users;
const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16, padding: 10 },
};
