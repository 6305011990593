import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const usedTablesSlice = createSlice({
    name: "used-tables",
    initialState,
    reducers: {
        setUsedTables(_, action) {
            return action.payload;
        }
    }
});
export const { setUsedTables } = usedTablesSlice.actions;

export default usedTablesSlice.reducer;
