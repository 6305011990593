import React, { useState, useEffect } from "react";

import {
  Table,
  Card,
  Space,
  Input,
  Tooltip,
  Button,
  Row,
  Col,
  Typography,
  Tag,
  Select,
  Divider,
  Modal,
  message,
  Spin,
  Badge,
} from "antd";
import {
  LoadingOutlined,
  SearchOutlined,
  RedoOutlined,
  CheckOutlined,
  CloseOutlined,
  SnippetsOutlined,
  WarningOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import ItemSelection from "./components/ItemSelection";
import ItemEdit from "./components/ItemEdit";

import customStyles from "helpers/customStyles";
import {
  useGetBranchInventory,
  useMutationExecuteAction,
} from "services/hooks/api/branchInventoryAPI";
import ContentLoader from "common/ContentLoader";
import RetryMessage from "common/RetryMessage";
import { upperCase } from "helpers/textTransformer";
import { formatNumber } from "helpers/numberFormatter";

const { Text } = Typography;
const { Option } = Select;
const BranchInventory = () => {
  const [branchID, setBranchID] = useState(null);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [inventoryIDs, setInventoryIDs] = useState([]);
  const [inventory, setInventory] = useState([]);
  const [inventoryTemp, setInventoryTemp] = useState([]);
  const [branches, setBranches] = useState([]);
  const [searchValue, setSearchValue] = useState(null);
  const [visibility, setVisibility] = useState(false);
  const [items, setItems] = useState([]);
  const [itemEditParams, setItemEditParams] = useState({ visible: false, item: null });
  const [selectedAction, setSelectedAction] = useState(null);
  const [isBusy, setIsBusy] = useState(false);

  const [stocklevelFilter, setStockLevelFilter] = useState(null);

  const mutationExecuteAction = useMutationExecuteAction();

  const { isLoading, data, refetch, error, isError } = useGetBranchInventory({
    branch_id: branchID,
  });

  useEffect(() => {
    if (!isLoading && data) {
      const invIds = data.inventory.map((i) => i.item_id);
      setInventoryIDs([...inventoryIDs, ...invIds]);
      setItems(data.items.filter((i) => !invIds.includes(i.item_id)));
      setInventory(data.inventory);
      setInventoryTemp(data.inventory);
      setBranches(data.branch);
    }
  }, [isLoading, data]);
  const columns = [
    {
      title: "Item",
      key: "status",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record, index) => (
        <Text>
          {record.status === "active" ? (
            <CheckOutlined style={{ color: "green", fontSize: 9 }} />
          ) : (
            <CloseOutlined style={{ color: "red", fontSize: 9 }} />
          )}{" "}
          {record.item_name} <br></br>
          {record.unit_id && record.category_id === 2 && (
            <Tag style={{ fontSize: 10, padding: 0, paddingLeft: 4, paddingRight: 4 }}>
              {record.unit}
            </Tag>
          )}
          {record.group_id && record.category_id === 2 && (
            <Tag
              color="magenta"
              style={{ fontSize: 10, padding: 0, paddingLeft: 4, paddingRight: 4 }}
            >
              {record.group_name}
            </Tag>
          )}
        </Text>
      ),
      filters: [
        {
          text: "Active",
          value: "active",
        },
        {
          text: "Inactive",
          value: "inactive",
        },
      ],
      defaultFilteredValue: [],
      onFilter: (value, record) => record.status.indexOf(value) === 0,
    },
    {
      title: "Qty",
      key: "qty",
      width: 90,
      align: "center",
      render: (text, record, index) => formatNumber(record.qty, true, 3),
    },
    {
      title: "Stck Lvl",
      key: "stock_level",
      width: 90,
      align: "right",
      render: (_, record) => (
        <Badge
          count={record.min_stock_qty}
          showZero={true}
          color={
            record.stock_level === "good"
              ? "green"
              : record.stock_level === "re-stock"
              ? "orange"
              : record.stock_level === "depleted"
              ? "#f50"
              : ""
          }
        >
          <Tag
            color={
              record.stock_level === "good"
                ? "green"
                : record.stock_level === "re-stock"
                ? "orange"
                : record.stock_level === "depleted"
                ? "#f50"
                : ""
            }
          >
            {record.stock_level}
          </Tag>
        </Badge>
      ),
      filters: [
        {
          text: "Good",
          value: "good",
        },
        {
          text: "Re-Stock",
          value: "re-stock",
        },
        {
          text: "Depleted",
          value: "depleted",
        },
        {
          text: "Undefine",
          value: "undefine",
        },
      ],
      defaultFilteredValue: [],
      onFilter: (value, record) => record.stock_level.indexOf(value) === 0,
    },
  ];

  const handleBranchSelect = (value) => {
    const b = branches.filter((b) => b.branch_id === value);
    setSelectedBranch(b[0]);
    setBranchID(value);
  };

  const handleActionSelect = (value) => {
    setSelectedAction(value);
  };

  const handlePreExecuteAction = () => {
    Modal.confirm({
      width: 320,
      title: `Confirm ${upperCase(selectedAction)}`,
      icon: <WarningOutlined />,
      content: (
        <>
          {selectedAction === "reset" ? (
            <p>
              This will revert back inventory from yesterday's ending, all other process will be
              converted zero
            </p>
          ) : (
            <p>This will set all inventory to zero, including beginning and balance.</p>
          )}

          <Divider style={{ margin: 0, marginTop: 15, marginBottom: 10 }} />
          <span style={{ fontSize: 15, fontWeight: 600 }}>Are you sure?</span>
        </>
      ),
      okText: "Yes, I'm sure!",
      cancelText: "No",
      onOk: executeAction,
    });
  };

  const executeAction = () => {
    const queryKeys = {
      branch_id: selectedBranch.branch_id,
      inventory_cutoff: selectedBranch.inventory_cutoff,
      action_type: selectedAction,
    };

    setIsBusy(true);
    mutationExecuteAction.mutate(queryKeys, {
      onError: (error) => {
        message.error("Something went wrong :/");
        // An error happened!
        setIsBusy(false);
        console.log(`Error:  ${error.message}`);
      },
      onSuccess: (data) => {
        // Boom baby!
        // console.log(data);
        message.success(data.message);
        setIsBusy(false);
        refetch();
      },
    });
  };
  const handleOpenSearch = () => {
    setVisibility(true);
  };
  const handleSearchOnChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    setInventoryTemp(
      inventory.filter((obj) => obj.item_name.toLowerCase().includes(value.toLowerCase()))
    );
  };

  const handleRowclick = (item) => {
    setItemEditParams({ visible: true, item });
    // setFormItemParams({ visible: true, item, isEdit: true });
  };

  // const handleNew = () => {
  //   setFormItemParams({ visible: true, item: null, isEdit: false });
  // };

  const handleRefresh = () => {
    refetch();
  };

  const handlePrint = () => {
    if (!stocklevelFilter && inventoryTemp.length <= 0) {
      message.error("Nothing to print");
    } else if (!stocklevelFilter && inventoryTemp.length > 0) {
      console.log(inventoryTemp);
    } else if (stocklevelFilter && inventoryTemp.length > 0) {
      const toPrint = inventoryTemp.filter((i) => stocklevelFilter.includes(i.stock_level));
      if (toPrint.length <= 0) {
        message.error("Nothing to print");
      } else {
        console.log(toPrint);
      }
    }
    // console.log(inventoryTemp);
  };

  const handleTableChange = (_, filters) => {
    setStockLevelFilter(filters.stock_level);
  };

  if (isLoading) {
    return <ContentLoader />;
  }

  if (isError) {
    return (
      <Card>
        <RetryMessage message={error.message} onRetry={() => refetch()} />
      </Card>
    );
  }

  if (data && !isError) {
    return (
      <>
        <Spin spinning={isBusy}>
          <Card bodyStyle={{ padding: "20px 14px" }}>
            <div
              style={{
                ...customStyles.quantityContainer,
                marginTop: 0,
                minHeight: "auto",
              }}
            >
              <Space direction="vertical">
                <div
                  style={{
                    border: "1px dashed #d9d9d9",
                    padding: 6,
                  }}
                >
                  <Select
                    style={{
                      width: 300,
                      fontSize: 16,
                    }}
                    bordered={false}
                    optionLabelProp="label"
                    placeholder="Select Branch"
                    onSelect={handleBranchSelect}
                    value={branchID}
                  >
                    {branches.map((branch) => {
                      return (
                        <Option
                          value={branch.branch_id}
                          key={branch.branch_id}
                          label={<span>Branch {branch.branch_name}</span>}
                        >
                          <div
                            style={{
                              display: "flex",
                              fontSize: 18,
                              height: 35,
                              alignItems: "center",
                            }}
                          >
                            Branch - {branch.branch_name}
                          </div>
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </Space>
            </div>

            {selectedBranch && (
              <div
                style={{
                  ...customStyles.quantityContainer,
                  marginTop: 10,
                  minHeight: "auto",
                }}
              >
                <Space direction="vertical">
                  <div style={{ ...customStyles.spaceBetween, paddingLeft: 4, paddingRight: 4 }}>
                    <Text>Branch Inventroy Cut-Off</Text>
                    <Text>{selectedBranch ? selectedBranch.inventory_cutoff : " "}</Text>
                  </div>

                  <Space>
                    <div
                      style={{
                        border: "1px dashed #d9d9d9",
                        padding: 6,
                      }}
                    >
                      <Select
                        style={{
                          width: 190,
                          fontSize: 16,
                        }}
                        bordered={false}
                        optionLabelProp="label"
                        placeholder="Select action"
                        onSelect={handleActionSelect}
                        // value={branchID}
                      >
                        <Option value="reset" label="Reset Inventory">
                          <div
                            style={{
                              display: "flex",
                              fontSize: 18,
                              height: 35,
                              alignItems: "center",
                            }}
                          >
                            Initialize/Reset
                          </div>
                        </Option>
                        <Option value="zero" label="Zero Inventory">
                          <div
                            style={{
                              display: "flex",
                              fontSize: 18,
                              height: 35,
                              alignItems: "center",
                            }}
                          >
                            Set to Zero
                          </div>
                        </Option>
                      </Select>
                    </div>
                    <Button
                      icon={<WarningOutlined style={{ color: "#fa541c" }} />}
                      style={{ height: 45 }}
                      onClick={handlePreExecuteAction}
                      disabled={selectedAction ? false : true}
                    >
                      Execute
                    </Button>
                  </Space>
                </Space>
              </div>
            )}

            <Divider style={{ margin: 15 }} />
            <Row justify="space-between">
              <Col>
                <Input
                  placeholder="search item..."
                  size="large"
                  allowClear
                  style={{ paddingLeft: 20, width: 200 }}
                  // onPressEnter={handlesOnPressEnter}
                  onChange={handleSearchOnChange}
                  value={searchValue}
                  prefix={
                    <Space>
                      <SearchOutlined style={{ marginRight: 12 }} />
                    </Space>
                  }
                  suffix={isLoading && <LoadingOutlined />}
                />
              </Col>
              <Col>
                <Space style={{ marginTop: 4 }}>
                  <Tooltip title="Add New Card">
                    <Button
                      type="primary"
                      shape="circle"
                      icon={<SnippetsOutlined />}
                      onClick={handleOpenSearch}
                      disabled={!branchID ? true : false}
                    />
                  </Tooltip>
                  <Tooltip title="Refresh">
                    <Button
                      type="dashed"
                      shape="circle"
                      icon={<RedoOutlined />}
                      onClick={handleRefresh}
                    />
                  </Tooltip>

                  <Tooltip title="Print">
                    <Button
                      type="dashed"
                      shape="circle"
                      icon={<PrinterOutlined />}
                      onClick={handlePrint}
                    />
                  </Tooltip>
                </Space>
              </Col>
            </Row>

            <Table
              loading={isLoading}
              rowKey="rec"
              dataSource={inventoryTemp}
              columns={columns}
              style={{ marginTop: 10 }}
              size="small"
              onRow={(record, rowIndex) => {
                return {
                  onClick: (e) => {
                    handleRowclick(record);
                  }, // click row
                };
              }}
              onChange={handleTableChange}
            />
          </Card>
        </Spin>
        <ItemSelection
          items={items}
          setItems={setItems}
          visibility={visibility}
          setVisibility={setVisibility}
          branchID={branchID}
          setInventoryIDs={setInventoryIDs}
          setInventory={setInventory}
          setInventoryTemp={setInventoryTemp}
          selectedBranch={selectedBranch}
        />
        <ItemEdit
          params={itemEditParams}
          setParams={setItemEditParams}
          setInventory={setInventory}
          setInventoryTemp={setInventoryTemp}
          branchID={branchID}
          selectedBranch={selectedBranch}
        />
      </>
    );
  }
};

export default BranchInventory;
