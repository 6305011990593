import React from "react";
import socketio from "socket.io-client";
import config from "../../config.json";

const socket = socketio.connect(config.socket_url);
socket.on("connect", () => {
  console.log("Connected to server!");
});

socket.on("disconnect", () => {
  console.log("Disconnect from server!");
});
const SocketContext = React.createContext();

export { SocketContext, socket };
