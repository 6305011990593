import React, { useState, useEffect } from "react";

import { Card, Tabs, Space, Typography, Button, Row, Col, Select } from "antd";
import {
    PlusOutlined,
    MinusOutlined,
    WarningOutlined,
    SnippetsOutlined,
    PlusCircleOutlined,
    StarOutlined
} from "@ant-design/icons";
import customStyles from "helpers/customStyles";
import FormQuantity from "../InventoryEntry/components/FormQuantity";
const { TabPane } = Tabs;
const { Title, Text } = Typography;
const { Option } = Select;
const defaultValue = {
    item_name: "Gogi Pork",
    unit: "kg"
};
const InventoryEntry = () => {
    const [activeTab, setActiveTab] = useState("add");
    const [selectedItem, setSelectedItem] = useState(defaultValue);

    useEffect(() => {
        setSelectedItem(defaultValue);
    }, []);

    const handleTabOnChange = (k) => {
        setActiveTab(k);
    };

    const handleOnQuantitySave = (qty) => {
        console.log(activeTab);
        console.log(qty);
    };

    const handleOnClose = () => {
        console.log("back to main");
    };

    return (
        <Card bodyStyle={{ padding: 18 }} size="small">
            <div style={customStyles.spaceBetween}>
                <Title level={4}>Gogi Pork (kg)</Title>
                <Button type="secondary" icon={<StarOutlined />}>
                    Add to favorites
                </Button>
            </div>
            <Space style={{ marginBottom: 18 }} size={1}>
                <Text keyboard>
                    <b>Begin</b> (1234)
                </Text>
                <Text keyboard>
                    {" "}
                    <b>Add</b> (1234)
                </Text>
                <Text keyboard>
                    {" "}
                    <b>Out:</b> 1234
                </Text>
                <Text keyboard>
                    {" "}
                    <b>Spoil:</b> 1234
                </Text>
                <Text keyboard>
                    {" "}
                    <b>Ending:</b> 1234
                </Text>
            </Space>
            <Tabs
                activeKey={activeTab}
                onChange={handleTabOnChange}
                size="large"
                type="card"
                style={{ minHeight: 300 }}
            >
                <TabPane
                    tab={
                        <span>
                            <PlusOutlined />
                            Add
                        </span>
                    }
                    key="add"
                >
                    <div style={customStyles.quantityContainer}>
                        <FormQuantity
                            onSave={handleOnQuantitySave}
                            onClose={handleOnClose}
                            selectedItem={selectedItem}
                            process={activeTab}
                        />
                    </div>
                </TabPane>
                <TabPane
                    tab={
                        <span>
                            <PlusCircleOutlined style={{ color: "#eb2f96" }} />
                            Return
                        </span>
                    }
                    key="return"
                >
                    <div style={customStyles.quantityContainer}>
                        <FormQuantity
                            onSave={handleOnQuantitySave}
                            onClose={handleOnClose}
                            selectedItem={selectedItem}
                            process={activeTab}
                        />
                    </div>
                </TabPane>
                <TabPane
                    tab={
                        <span>
                            <MinusOutlined />
                            Out
                        </span>
                    }
                    key="out"
                >
                    <Row gutter={4}>
                        <Col span={12}>
                            <div style={customStyles.quantityContainer}>
                                <FormQuantity
                                    onSave={handleOnQuantitySave}
                                    onClose={handleOnClose}
                                    selectedItem={selectedItem}
                                    process={activeTab}
                                />
                            </div>
                        </Col>
                        <Col span={12}>
                            <div style={customStyles.quantityContainer}>
                                <Space direction="vertical">
                                    <Title level={5}>
                                        Select Dining Table/s
                                    </Title>
                                    <div
                                        style={{
                                            border: "1px solid #d9d9d9",
                                            padding: 12
                                        }}
                                    >
                                        <Select
                                            style={{
                                                minWidth: 250,
                                                fontSize: 20
                                            }}
                                            bordered={false}
                                            optionLabelProp="label"
                                        >
                                            <Option value="1" label="Table 1,2">
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        fontSize: 20,
                                                        height: 35,
                                                        alignItems: "center"
                                                    }}
                                                >
                                                    Table 1,2
                                                </div>
                                            </Option>
                                            <Option value="2" label="Table 3,4">
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        fontSize: 20,
                                                        height: 35,
                                                        alignItems: "center"
                                                    }}
                                                >
                                                    Table 3,4
                                                </div>
                                            </Option>

                                            <Option value="3" label="Table 5,6">
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        fontSize: 20,
                                                        height: 35,
                                                        alignItems: "center"
                                                    }}
                                                >
                                                    Table 5,6
                                                </div>
                                            </Option>
                                        </Select>
                                    </div>
                                </Space>
                            </div>
                        </Col>
                    </Row>
                </TabPane>

                <TabPane
                    tab={
                        <span>
                            <WarningOutlined style={{ color: "#ff4d4f" }} />
                            Spoil
                        </span>
                    }
                    key="spoil"
                >
                    <div style={customStyles.quantityContainer}>
                        <FormQuantity
                            onSave={handleOnQuantitySave}
                            onClose={handleOnClose}
                            selectedItem={selectedItem}
                            process={activeTab}
                        />
                    </div>
                </TabPane>
                <TabPane
                    tab={
                        <span>
                            <SnippetsOutlined />
                            Ending
                        </span>
                    }
                    key="ending"
                    disabled
                >
                    <div style={customStyles.quantityContainer}>
                        <FormQuantity
                            onSave={handleOnQuantitySave}
                            onClose={handleOnClose}
                            selectedItem={selectedItem}
                            process={activeTab}
                        />
                    </div>
                </TabPane>
            </Tabs>
        </Card>
    );
};

export default InventoryEntry;
