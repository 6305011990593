import React, { useState, useEffect, useContext } from "react";
import { SocketContext } from "services/contexts/socket";
import {
  Typography,
  Card,
  Table,
  DatePicker,
  Alert,
  Row,
  Col,
  Empty,
  Button,
  Modal,
  message,
  Space,
  Spin,
  Tooltip,
} from "antd";
import {
  PrinterOutlined,
  ExclamationCircleOutlined,
  ScissorOutlined,
  BarChartOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import moment from "moment";
import { useLogout } from "services/hooks/api/authApi";
import {
  useGetDailyInventory,
  useMutationUpdateInventoryCutOff,
} from "services/hooks/api/inventoryAPI";
import config from "config.json";

import InventoryLedger from "pages/private/PrepArea/components/InventoryLedger";
import ContentLoader from "common/ContentLoader";
import RetryMessage from "common/RetryMessage";
import { formatNumber } from "helpers/numberFormatter";

import Summary from "./components/Summary";

const { Text } = Typography;
const { confirm } = Modal;
const Inventory = () => {
  const { branch_id, user_id, username, branch_name, print_server_id, inventory_cutoff, role_id } =
    useSelector((state) => state.User);

  const [cutOffDate, setCutOffDate] = useState(moment(inventory_cutoff));
  const [isLogoutLoadingVisible, setIsLogoutLoadingVisible] = useState(false);
  const { isLoading, data, isError, error, refetch } = useGetDailyInventory({
    branch_id,
    inventory_cutoff: moment(cutOffDate).format("YYYY-MM-DD"),
  });
  const logoutResponse = useLogout({
    enabled: isLogoutLoadingVisible,
  });
  const [inventoryTemp, setInventoryTemp] = useState(null);
  const [pax, setPax] = useState(0);
  const socket = useContext(SocketContext);

  const mutationUpdateInventoryCutOff = useMutationUpdateInventoryCutOff();
  const [isBusy, setIsBusy] = useState(false);

  const [inventoryLedgerParams, setInventoryLedgerParams] = useState({
    visible: false,
    selectedItem: null,
    branch_id,
    branch_name,
    username,
    print_server_id,
    inventory_cutoff: moment(cutOffDate).format("YYYY-MM-DD"),
  });

  const [summaryVisibility, setSummaryVisibility] = useState(false);

  useEffect(() => {
    if (!isLoading && data) {
      setInventoryTemp(data.groupedItems);
      setPax(data.pax);
    }
  }, [isLoading, data]);

  useEffect(() => {
    if (logoutResponse.isSuccess) {
      if (config.platform === "web") {
        window.location = `${config.appBasePath}/`;
      } else {
        window.location.reload();
      }
    }
  }, [logoutResponse.isSuccess]);

  const columns = [
    {
      title: "",
      dataIndex: "group_name",
      render: (text, record) => (
        <span>
          Group: <b>{record.group_name}</b>
        </span>
      ),
    },
    {
      title: "",
      dataIndex: "count",
      align: "right",
      render: (text, record) => <span>Item/s: ({record.items.length})</span>,
    },
  ];

  const handleDateChange = (date) => {
    setCutOffDate(date);
  };

  const handlePrint = () => {
    const printData = {
      branch_id,
      inventory_cutoff,
      user_id,
      username,
      branch_name,
      print_server_id,
      data: inventoryTemp,
    };

    socket.emit("printInventory", { ...printData });
  };

  const handleCutOff = () => {
    confirm({
      title: "Warning",
      icon: <ExclamationCircleOutlined />,
      content: (
        <>
          <p>This will change the Current Inventory CutOff Date.</p>
          <p>This action is irreversible!</p>
          <p>You will be redirected to log out page after cutoff.</p>
          <p>Please make sure that all item quantity are correct before performing this cutoff.</p>
          <p>
            <b>Are your sure?</b>
          </p>
        </>
      ),
      okText: "Yes I'm Sure",
      cancelText: "Not now",
      onOk() {
        setIsBusy(true);

        mutationUpdateInventoryCutOff.mutate(
          { branch_id, inventory_cutoff },
          {
            onError: (error) => {
              message.error("Something went wrong :/");
              // An error happened!
              setIsBusy(false);
              console.log(`Error:  ${error.message}`);
            },
            onSuccess: (data) => {
              // Boom baby!

              message.success(data.message);
              setIsBusy(false);
              setIsLogoutLoadingVisible(true);

              // navigate("/cashier");
            },
          }
        );
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handlesShowInventoryLedger = (record) => {
    setInventoryLedgerParams({
      ...inventoryLedgerParams,
      visible: true,
      selectedItem: record,
      inventory_cutoff: moment(cutOffDate).format("YYYY-MM-DD"),
    });
  };

  const handleShowSummary = () => {
    setSummaryVisibility(true);
  };

  const childRowRender = (data) => {
    const colWidth = 20;
    const columns = [
      {
        title: "Item",
        width: 50,
        fixed: "left",
        dataIndex: "item_name",
        key: "item_name",
        ellipsis: true,
        // key: "item_name",
        render: (text, record) => (
          <Tooltip title={record.item_name + " (" + record.unit + ")"}>
            <Text ellipsis={true}>
              {record.item_name} ({record.unit})
            </Text>
          </Tooltip>
        ),
      },
      {
        title: "Beg",
        width: colWidth,
        dataIndex: "inventory_begin",
        key: "item_inventory_begin",
        align: "right",
      },
      {
        title: "Add",
        width: colWidth,
        dataIndex: "inventory_add",
        key: "inventory_add",
        align: "right",
      },
      {
        title: "Tot",
        width: colWidth,
        dataIndex: "inventory_day_total",
        key: "inventory_day_total",
        align: "right",
      },
      {
        title: "Ret",
        width: colWidth,
        dataIndex: "inventory_return",
        key: "inventory_return",
        align: "right",
      },
      {
        title: "Out",
        width: colWidth,
        dataIndex: "inventory_out",
        key: "inventory_out",
        align: "right",
      },
      {
        title: "Spo",
        width: colWidth,
        dataIndex: "inventory_spoil",
        key: "inventory_spoil",
        align: "right",
      },
      {
        title: "Trans",
        width: colWidth,
        dataIndex: "inventory_transfer",
        key: "inventory_transfer",
        align: "right",
      },
      {
        title: "Cons",
        width: colWidth + 4,
        dataIndex: "inventory_consumed",
        align: "right",
        render: (text, record) => <Text type="danger">({record.inventory_consumed})</Text>,
      },
      {
        title: "UPrice",
        width: role_id === 1 ? colWidth + 10 : 0,
        dataIndex: "amount",
        align: "right",
        render: (text, record) => <Text mark>{formatNumber(record.unit_price)}</Text>,
      },
      {
        title: "Amt",
        width: role_id === 1 ? colWidth + 10 : 0,
        dataIndex: "amount",
        align: "right",
        render: (text, record) => <Text mark>{formatNumber(record.amount)}</Text>,
      },
      {
        title: "UAmt",
        width: role_id === 1 ? colWidth + 10 : 0,
        dataIndex: "uamount",
        align: "right",

        render: (text, record) =>
          record.is_unli_basis ? <Text mark>{formatNumber(record.amount)}</Text> : "n/a",
      },
      {
        title: "Pax",
        width: role_id === 1 ? colWidth + 10 : 0,
        dataIndex: "amount",
        align: "right",

        render: (text, record) => (record.is_unli_basis ? <Text mark>{pax}</Text> : "n/a"),
      },
      {
        title: "AvAmt",
        width: role_id === 1 ? colWidth + 10 : 0,
        dataIndex: "amount",
        align: "right",

        render: (text, record) =>
          record.is_unli_basis ? (
            <Text mark>{pax ? formatNumber(record.amount / pax) : 0}</Text>
          ) : (
            "n/a"
          ),
      },
      {
        title: "AvCons",
        width: role_id === 1 ? colWidth + 10 : 0,
        dataIndex: "amount",
        align: "right",

        render: (text, record) =>
          record.is_unli_basis ? (
            <Text mark>{pax ? formatNumber(record.inventory_consumed / pax, true, 3) : 0}</Text>
          ) : (
            "n/a"
          ),
      },
      {
        title: "Bal",
        width: colWidth,
        dataIndex: "inventory_balance",
        key: "inventory_balance",
        fixed: "right",
        align: "right",
        render: (text, record) => (
          <Button
            type="link"
            style={{ textAlign: "right", padding: 0 }}
            onClick={() => handlesShowInventoryLedger(record)}
          >
            {record.inventory_balance}
          </Button>
        ),
      },
    ];

    const columnsUser = [
      {
        title: "Item",
        width: 50,
        fixed: "left",
        dataIndex: "item_name",
        key: "item_name",
        ellipsis: true,
        // key: "item_name",
        render: (text, record) => (
          <Tooltip title={record.item_name + " (" + record.unit + ")"}>
            <Text ellipsis={true}>
              {record.item_name} ({record.unit})
            </Text>
          </Tooltip>
        ),
      },
      {
        title: "Beg",
        width: colWidth,
        dataIndex: "inventory_begin",
        key: "item_inventory_begin",
        align: "right",
      },
      {
        title: "Add",
        width: colWidth,
        dataIndex: "inventory_add",
        key: "inventory_add",
        align: "right",
      },
      {
        title: "Tot",
        width: colWidth,
        dataIndex: "inventory_day_total",
        key: "inventory_day_total",
        align: "right",
      },
      {
        title: "Ret",
        width: colWidth,
        dataIndex: "inventory_return",
        key: "inventory_return",
        align: "right",
      },
      {
        title: "Out",
        width: colWidth,
        dataIndex: "inventory_out",
        key: "inventory_out",
        align: "right",
      },
      {
        title: "Spo",
        width: colWidth,
        dataIndex: "inventory_spoil",
        key: "inventory_spoil",
        align: "right",
      },
      {
        title: "Trans",
        width: colWidth,
        dataIndex: "inventory_transfer",
        key: "inventory_transfer",
        align: "right",
      },
      {
        title: "Cons",
        width: colWidth + 4,
        dataIndex: "inventory_consumed",
        align: "right",
        render: (text, record) => <Text type="danger">({record.inventory_consumed})</Text>,
      },

      {
        title: "Bal",
        width: colWidth,
        dataIndex: "inventory_balance",
        key: "inventory_balance",
        fixed: "right",
        align: "right",
        render: (text, record) => (
          <Button
            type="link"
            style={{ textAlign: "right", padding: 0 }}
            onClick={() => handlesShowInventoryLedger(record)}
          >
            {record.inventory_balance}
          </Button>
        ),
      },
    ];

    return (
      <div
        style={{
          position: "relative",
          width: "calc(100vw - 30px)",
          overflowX: "hidden",
          overflowY: "hidden",
          paddingBottom: 5,
          margin: 0,
          // border: "1px solid black",
        }}
      >
        <Table
          columns={role_id === 1 ? columns : columnsUser}
          rowKey="item_id"
          size="small"
          dataSource={data}
          pagination={false}
          scroll={{ x: role_id === 1 ? 1200 : 650 }}
          style={{ marginLeft: 0 }}
          summary={(pageData) => {
            let begin = 0;
            let add = 0;
            let tot = 0;
            let ret = 0;
            let out = 0;
            let spo = 0;
            let trans = 0;
            let cons = 0;
            let bal = 0;
            let amt = 0;
            let uamt = 0;
            let avamt = 0;
            let avcons = 0;

            pageData.forEach(
              ({
                inventory_begin,
                inventory_add,
                inventory_day_total,
                inventory_return,
                inventory_out,
                inventory_spoil,
                inventory_transfer,
                inventory_consumed,
                inventory_balance,
                amount,
                is_unli_basis,
              }) => {
                begin += inventory_begin;
                add += inventory_add;
                tot += inventory_day_total;
                ret += inventory_return;
                out += inventory_out;
                spo += inventory_spoil;
                trans += inventory_transfer;
                cons += inventory_consumed;
                bal += inventory_balance;
                amt += amount;
                uamt += is_unli_basis ? amount : 0;
                avamt += is_unli_basis ? amount / pax : 0;
                avcons += is_unli_basis ? inventory_consumed / pax : 0;
              }
            );

            return (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>
                    <b>Total</b>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1} align="right">
                    {/* {formatNumber(begin, true)} */}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2} align="right">
                    {/* {formatNumber(add, true)} */}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3} align="right">
                    {/* {formatNumber(tot, true)} */}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4} align="right">
                    {/* {formatNumber(ret, true)} */}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={5} align="right">
                    {/* {formatNumber(out, true)} */}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={6} align="right">
                    {/* {formatNumber(spo, true)} */}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={6} align="right">
                    {/* {formatNumber(trans, true)} */}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={7} align="right">
                    <Text type="danger">
                      <b>({formatNumber(cons, true)})</b>
                    </Text>
                  </Table.Summary.Cell>

                  {role_id === 1 && (
                    <>
                      <Table.Summary.Cell index={8} align="right">
                        {/* <Text mark>{formatNumber(amt)}</Text> */}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={8} align="right">
                        <Text mark>{formatNumber(amt)}</Text>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell index={8} align="right">
                        <Text mark>{formatNumber(uamt)}</Text>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell index={8} align="right">
                        <Text mark>{pax}</Text>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell index={8} align="right">
                        <Text mark>{formatNumber(avamt)}</Text>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell index={8} align="right">
                        <Text mark>{formatNumber(avcons, true, 3)}</Text>
                      </Table.Summary.Cell>
                    </>
                  )}

                  <Table.Summary.Cell index={9} align="right" fixed>
                    <b>{formatNumber(bal, true)}</b>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            );
          }}
        />
      </div>
    );
  };

  if (isLoading) {
    return <ContentLoader />;
  }

  if (isError) {
    return (
      <Card>
        <RetryMessage message={error.message} onRetry={() => refetch()} />
      </Card>
    );
  }

  return (
    <Card bodyStyle={{ padding: 0, margin: 0 }}>
      <Spin tip="Performing Inventory CutOff, please wait... " spinning={isBusy}>
        <Row
          gutter={16}
          style={{
            // borderStyle: "dashed",
            border:
              inventory_cutoff !== moment(cutOffDate).format("YYYY-MM-DD")
                ? "4px dashed #f5222d"
                : "4px dashed #1d39c4",

            padding: 6,
            margin: 20,
            backgroundColor: "white",
          }}
        >
          <Col
            span={12}
            style={{
              paddingTop: 6,
              paddingLeft: 15,
            }}
          >
            <b>Inventory CutOff:</b>
          </Col>
          <Col span={12}>
            <DatePicker
              value={cutOffDate}
              onChange={handleDateChange}
              style={{ width: "100%" }}
              disabledDate={(current) => {
                let customDate = moment(inventory_cutoff).add(1, "d").format("YYYY-MM-DD");
                return current >= moment(customDate, "YYYY-MM-DD");
              }}
            />
          </Col>
          <Col span={24} style={{ marginTop: 10, marginBottom: 10 }}>
            <Space direction="vertical" style={{ width: "100%" }}>
              <Row gutter={8}>
                <Col span={role_id === 1 ? 12 : 24}>
                  {" "}
                  <Button
                    block
                    size="large"
                    type="primary"
                    icon={<PrinterOutlined />}
                    onClick={handlePrint}
                    disabled={inventoryTemp ? false : true}
                    loading={isBusy}
                  >
                    Print
                  </Button>
                </Col>
                <Col span={role_id === 1 ? 12 : 0}>
                  {role_id === 1 && (
                    <Button
                      block
                      size="large"
                      icon={<BarChartOutlined />}
                      onClick={handleShowSummary}
                      disabled={inventoryTemp ? false : true}
                      loading={isBusy}
                    >
                      Transaction
                    </Button>
                  )}
                </Col>
              </Row>

              {inventory_cutoff == moment(cutOffDate).format("YYYY-MM-DD") && (
                <Button
                  block
                  size="large"
                  danger
                  icon={<ScissorOutlined />}
                  onClick={handleCutOff}
                  disabled={inventoryTemp ? false : true}
                  loading={isBusy}
                >
                  Cutt-Off
                </Button>
              )}
            </Space>
          </Col>
        </Row>

        {inventory_cutoff !== moment(cutOffDate).format("YYYY-MM-DD") && (
          <Alert
            message={<b>Warning</b>}
            description={
              <span>
                Date specified is not the actual <b>CutOff.</b>
              </span>
            }
            type="warning"
            showIcon
            style={{
              marginTop: 10,
              marginBottom: 10,
            }}
          />
        )}
        {inventoryTemp ? (
          <Table
            showHeader={false}
            dataSource={inventoryTemp}
            rowKey="group_name"
            columns={columns}
            size="small"
            pagination={false}
            expandable={{
              expandRowByClick: true,
              indentSize: 10,
              expandedRowRender: (record) => childRowRender(record.items),
              defaultExpandAllRows: true,
            }}
          />
        ) : (
          <Empty />
        )}
      </Spin>
      <InventoryLedger params={inventoryLedgerParams} setParams={setInventoryLedgerParams} />
      <Summary
        visibility={summaryVisibility}
        setVisibility={setSummaryVisibility}
        branch_id={branch_id}
        cutoff={moment(cutOffDate).format("YYYY-MM-DD")}
      />
    </Card>
  );
};

export default Inventory;
