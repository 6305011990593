import { combineReducers } from "redux";

import appReducer from "./slices/app";
import userReducer from "./slices/user";
import transactionReducer from "./slices/transaction";
import usedTablesReducer from "./slices/usedTables";
import transStatusReducer from "./slices/transStatus";

const rootReducer = combineReducers({
  App: appReducer,
  User: userReducer,
  Transaction: transactionReducer,
  UsedTables: usedTablesReducer,
  TransStatus: transStatusReducer,
});

export default rootReducer;
