import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Divider,
  Table,
  Button,
  Typography,
  Form,
  Input,
  InputNumber,
  Row,
  Col,
  message,
  Spin,
} from "antd";

import { useSelector } from "react-redux";

import { CloseOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import {
  useGetExpense,
  useMutationInsertExpense,
  useMutationDeleteExpense,
} from "services/hooks/api/expenseAPI";
import { formatNumber } from "helpers/numberFormatter";
import { titleCase } from "helpers/textTransformer";
import { generateString } from "helpers/randomStringGenerator";
import ContentLoader from "common/ContentLoader";
import RetryMessage from "common/RetryMessage";
const { Text } = Typography;
const Expense = () => {
  const navigate = useNavigate();
  const { branch_id, cutoff, user_id } = useSelector((state) => state.User);
  const [form] = Form.useForm();
  const [expense, setExpense] = useState([]);
  const [isBusy, setIsBusy] = useState(false);

  const {
    isLoading,
    data: expenseData,
    isError,
    error,
    refetch,
  } = useGetExpense({ branch_id, cutoff });
  const mutationInsertExpense = useMutationInsertExpense();
  const mutationDeleteExpense = useMutationDeleteExpense();
  useEffect(() => {
    if (!isLoading && expenseData) {
      setExpense(expenseData);
    }
  }, [isLoading, expenseData]);

  const columns = [
    {
      title: "#",
      width: 35,
      render: (text, record, index) => <Text keyboard>{index + 1}.</Text>,
    },
    {
      title: "Details",
      dataIndex: "expense_details",
      key: "expense_details",
    },
    {
      title: "Amout",
      dataIndex: "amount",
      align: "right",
      render: (text, record, index) => <Text>&#8369;{formatNumber(record.amount)}</Text>,
    },
    {
      title: "",
      width: 30,
      dataIndex: "action",
      render: (text, record) => (
        <DeleteOutlined style={{ color: "red" }} onClick={() => handlesDelete(record)} />
      ),
    },
  ];

  const handlesBlur = (e) => {
    const { id, value } = e.target;
    form.setFieldsValue({
      [id]: titleCase(value),
    });
  };

  const handlesSave = async () => {
    setIsBusy(true);

    form
      .validateFields()
      .then(async (values) => {
        // console.log(values);
        const toInsert = {
          ...values,
          expense_id: generateString(8, "E", "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"),
          cutoff,
          branch_id,
          user_id,
        };

        mutationInsertExpense.mutate(toInsert, {
          onError: (error) => {
            message.error("Something went wrong :/");
            // An error happened!
            setIsBusy(false);
            console.log(`Error:  ${error.message}`);
          },
          onSuccess: (data) => {
            // Boom baby!
            setExpense((prevData) => [...prevData, toInsert]);
            // console.log(data);
            message.success(`Save successful!`);
            setIsBusy(false);
          },
        });
        form.resetFields();
        setIsBusy(false);
      })
      .catch((errorInfo) => {
        message.warning("Unable to save, look's like you missed something.");
        setIsBusy(false);
      });
  };

  const handlesDelete = async (record) => {
    setIsBusy(true);
    mutationDeleteExpense.mutate(record, {
      onError: (error) => {
        message.error("Something went wrong :/");
        // An error happened!
        setIsBusy(false);
        console.log(`Error:  ${error.message}`);
      },
      onSuccess: (data) => {
        // Boom baby!
        setExpense(expense.filter((item) => item.expense_id !== record.expense_id));
        // console.log(data);
        message.success(`Delete successful!`);
        setIsBusy(false);
      },
    });
  };

  if (isLoading) {
    return <ContentLoader />;
  }

  if (isError) {
    return (
      <Card>
        <RetryMessage message={error.message} onRetry={() => refetch()} />
      </Card>
    );
  }

  if (expenseData && !isError) {
    return (
      <Spin spinning={isBusy}>
        <Card bodyStyle={{ padding: "12px 14px" }} size="small" title="Expense Form">
          {/* <Line {...config} /> */}
          <Divider style={{ margin: 6 }} plain>
            Add Expense
          </Divider>
          <Form form={form} layout="vertical" autoComplete="off">
            <Form.Item
              name="expense_details"
              label="Details"
              style={{ marginBottom: 8 }}
              rules={[
                {
                  required: true,
                  message: "Description is required!",
                },
              ]}
            >
              <Input onBlur={handlesBlur} />
            </Form.Item>

            <Row gutter={12}>
              <Col span={12}>
                <Form.Item
                  name="amount"
                  label="Amount"
                  style={{ marginBottom: 8 }}
                  rules={[
                    {
                      required: true,
                      message: "Amount is required!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (Number(getFieldValue("amount")) === 0) {
                          return Promise.reject("Amount cannot be zero!");
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col span={12} style={{ paddingTop: 30 }}>
                <Button type="primary" icon={<PlusOutlined />} block onClick={handlesSave}>
                  Add Expense
                </Button>
              </Col>
            </Row>
          </Form>
          <Divider style={{ margin: 6 }} plain>
            Exepense List
          </Divider>

          <Table
            dataSource={expense}
            rowKey="expense_id"
            columns={columns}
            size="small"
            pagination={false}
            summary={(pageData) => {
              let a = 0;
              pageData.forEach(({ amount }) => {
                a += amount;
              });
              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0} />
                    <Table.Summary.Cell index={1}>
                      <b>Total</b>
                    </Table.Summary.Cell>

                    <Table.Summary.Cell index={2} align="right">
                      <Text>&#8369;{formatNumber(a)}</Text>
                    </Table.Summary.Cell>

                    <Table.Summary.Cell index={3} />
                  </Table.Summary.Row>
                </>
              );
            }}
          />

          <Button
            icon={<CloseOutlined />}
            size="large"
            block
            style={{ marginTop: 20 }}
            onClick={() => navigate("/cashier")}
          >
            Close
          </Button>
        </Card>
      </Spin>
    );
  }
};

export default Expense;
