import React, { useState, useEffect, useContext } from "react";

import { SocketContext } from "services/contexts/socket";

import { useSelector } from "react-redux";
import {
  Table,
  Card,
  Space,
  Input,
  Tooltip,
  Button,
  Row,
  Col,
  Typography,
  Tag,
  Select,
  Divider,
  Spin,
  message,
  Modal,
} from "antd";
import {
  LoadingOutlined,
  SearchOutlined,
  RedoOutlined,
  SnippetsOutlined,
  QuestionCircleOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { formatNumber } from "helpers/numberFormatter";
import customStyles from "helpers/customStyles";

import { useGetDelivery, useMutationDeleteDelivery } from "services/hooks/api/deliveryAPI";

import RetryMessage from "common/RetryMessage";
import ItemSelection from "./components/ItemSelection";
const { Text, Title } = Typography;
const { Option } = Select;
const InventoryTransfer = () => {
  const socket = useContext(SocketContext);
  const { user_id, branch_name, branch_id, inventory_cutoff, role_id } = useSelector(
    (state) => state.User
  );
  const mutationDeleteDelivery = useMutationDeleteDelivery();

  const [selectedBranch, setSelectedBranch] = useState(null);

  const [queryKeys, setQueryKeys] = useState({
    branch_id: role_id === 1 ? null : branch_id,
    inventory_cutoff: role_id === 1 ? null : inventory_cutoff,
    branch_name: role_id === 1 ? null : branch_name,
  });

  const [branches, setBranches] = useState([]);
  const [deliveryList, setDeliveryList] = useState([]);
  const [deliveryListTemp, setDeliveryListTemp] = useState([]);
  const [supplierList, setSupplierList] = useState([]);
  const [visibility, setVisibility] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [suppplierFilter, setSupplierFilter] = useState(null);
  const [supplierFilterData, setSupplierFilterData] = useState([]);
  const [items, setItems] = useState([]);
  const [isBusy, setIsBusy] = useState(false);
  const { isLoading, data, refetch, error, isError } = useGetDelivery(
    {
      branch_id: queryKeys.branch_id,
      inventory_cutoff: queryKeys.inventory_cutoff,
      branch_name: queryKeys.branch_name,
    },
    {
      enabled: true,
    }
  );

  useEffect(() => {
    if (!isLoading && data) {
      // console.log(data.items);
      setSupplierList(data.supplier);
      setSupplierFilterData(
        data.supplier.map((s) => {
          return { text: s.supplier_name, value: s.supplier_id };
        })
      );
      setItems(data.items);
      setDeliveryList(data.delivery);
      setDeliveryListTemp(data.delivery);
      setBranches(data.branch);
    }
  }, [isLoading, data]);

  const columns = [
    {
      title: "#",
      key: "row_count",
      width: 30,
      fixed: "left",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Item",
      key: "item_name",
      width: 120,
      ellipsis: {
        showTitle: false,
      },
      fixed: "left",
      render: (text, record, index) => record.item_name,
    },
    {
      title: "Supplier",
      key: "supplier_id",
      width: 100,
      ellipsis: {
        showTitle: false,
      },
      render: (text, record, index) => record.supplier_name,
      filters: supplierFilterData,
      defaultFilteredValue: [],
      onFilter: (value, record) => record.supplier_id.indexOf(value) === 0,
    },
    {
      title: "Qty",
      key: "qty",
      width: 70,
      align: "right",

      render: (text, record, index) => formatNumber(record.qty, true, 3),
    },
    {
      title: "Price",
      key: "price",
      width: 70,
      align: "right",
      render: (text, record, index) => formatNumber(record.unit_price),
    },
    {
      title: "Total",
      key: "total",
      width: 70,
      align: "right",
      render: (text, record, index) => formatNumber(record.total),
    },
  ];

  const handleBranchSelect = (value) => {
    const b = branches.filter((b) => b.branch_id === value);
    setSelectedBranch(b[0]);
    setQueryKeys({
      ...queryKeys,
      branch_id: b[0].branch_id,
      inventory_cutoff: b[0].inventory_cutoff,
      branch_name: b[0].branch_name,
    });
  };

  const handleTableChange = (_, filters) => {
    setSupplierFilter(filters.supplier_id);
  };

  const handlePrint = () => {
    if (!suppplierFilter && deliveryListTemp.length <= 0) {
      message.error("Nothing to print");
    } else if (!suppplierFilter && deliveryListTemp.length > 0) {
      console.log(deliveryListTemp);
    } else if (suppplierFilter && deliveryListTemp.length > 0) {
      const toPrint = deliveryListTemp.filter((i) => suppplierFilter.includes(i.supplier_id));
      if (toPrint.length <= 0) {
        message.error("Nothing to print");
      } else {
        console.log(toPrint);
      }
    }
  };

  const handleOpenSearch = () => {
    setVisibility(true);
  };

  const handleSearchOnChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    setDeliveryListTemp(
      deliveryList.filter((obj) => obj.item_name.toLowerCase().includes(value.toLowerCase()))
    );
  };

  const handleRefresh = () => {
    refetch();
  };

  const handleRowclick = (record) => {
    Modal.confirm({
      width: 350,
      title: "Remove this item.",
      icon: <QuestionCircleOutlined style={{ color: "#2f54eb" }} />,
      content: (
        <>
          <p style={{ textAlign: "left", fontSize: 20, padding: 0, margin: 0 }}>
            Supplier: <b style={{ color: "red" }}>{record.supplier_name} </b>
          </p>
          <p style={{ textAlign: "left", fontSize: 20, padding: 0, margin: 0 }}>
            Item: <b style={{ color: "red" }}>{record.item_name}</b>
          </p>
          <p style={{ textAlign: "left", fontSize: 20, padding: 0, margin: 0 }}>
            Unit Price: <b style={{ color: "red" }}>{formatNumber(record.unit_price)} </b>
          </p>
          <p style={{ textAlign: "left", fontSize: 20, padding: 0, margin: 0 }}>
            Qty: <b style={{ color: "red" }}>{formatNumber(record.qty, true, 3)} </b>
          </p>
          <p style={{ textAlign: "left", fontSize: 20, padding: 0, margin: 0 }}>
            Total: <b style={{ color: "red" }}>{formatNumber(record.total)} </b>
          </p>
          <Divider style={{ margin: 0, marginTop: 15, marginBottom: 10 }} />
          <span style={{ fontSize: 15, fontWeight: 600 }}>This action is irreversible.</span>
        </>
      ),
      okText: "Yes, I'm sure!",
      cancelText: "No",
      onOk: () => {
        console.log("ok");
        mutationDeleteDelivery.mutate(record, {
          onError: (error) => {
            message.error("Something went wrong :/");
            // An error happened!
            console.log(`Error:  ${error.message}`);
            setIsBusy(false);
          },
          onSuccess: (data) => {
            if (data.error) {
              message.error(data.message);
            } else {
              message.success(data.message);
              setDeliveryList(deliveryList.filter((i) => i.time_stamp !== record.time_stamp));
              setDeliveryListTemp(
                deliveryListTemp.filter((j) => j.time_stamp !== record.time_stamp)
              );
              setIsBusy(false);
              socket.emit("updateTransferAdmin", { room: "gogi" });
            }
          },
        });
      },
    });
  };

  if (isError) {
    return (
      <Card>
        <RetryMessage message={error.message} onRetry={() => refetch()} />
      </Card>
    );
  }
  return (
    <Spin spinning={isLoading || isBusy}>
      <Card bodyStyle={{ padding: "0px 14px", marginTop: 10 }}>
        {role_id === 1 && (
          <div
            style={{
              ...customStyles.quantityContainer,
              marginTop: 0,
              minHeight: "auto",
              marginBottom: 10,
            }}
          >
            <Space direction="vertical">
              <div
                style={{
                  border: "1px dashed #d9d9d9",
                  padding: 6,
                }}
              >
                <Select
                  style={{
                    width: 300,
                    fontSize: 16,
                  }}
                  bordered={false}
                  optionLabelProp="label"
                  placeholder="Select Branch"
                  onSelect={handleBranchSelect}
                  value={selectedBranch ? selectedBranch.branch_id : null}
                >
                  {branches.map((branch) => {
                    return (
                      <Option
                        value={branch.branch_id}
                        key={branch.branch_id}
                        label={<span>Branch {branch.branch_name}</span>}
                      >
                        <div
                          style={{
                            display: "flex",
                            fontSize: 18,
                            height: 35,
                            alignItems: "center",
                          }}
                        >
                          Branch - {branch.branch_name}
                        </div>
                      </Option>
                    );
                  })}
                </Select>
              </div>
            </Space>
          </div>
        )}

        <Tag style={{ width: "100%", marginBottom: 16, fontSize: 14 }}>
          <Row justify="space-between" style={{ marginTop: 10, marginBottom: 10 }}>
            <Col>
              <Text>
                <b>Branch:</b> {queryKeys.branch_name || "-"}
              </Text>
            </Col>
            <Col>
              <Text>
                <b>Inv. CutOff:</b>{" "}
                {queryKeys.inventory_cutoff
                  ? moment(queryKeys.inventory_cutoff).format("MM/DD/YYYY")
                  : "-"}
              </Text>
            </Col>
          </Row>
        </Tag>
        <Row justify="space-between">
          <Col>
            <Input
              placeholder="Search item..."
              size="large"
              allowClear
              style={{ paddingLeft: 20, width: 200 }}
              // onPressEnter={handlesOnPressEnter}
              onChange={handleSearchOnChange}
              value={searchValue}
              prefix={
                <Space>
                  <SearchOutlined style={{ marginRight: 12 }} />
                </Space>
              }
              suffix={isLoading && <LoadingOutlined />}
            />
          </Col>
          <Col>
            <Space style={{ marginTop: 4 }}>
              <Tooltip title="Add New Card">
                <Button
                  type="primary"
                  shape="circle"
                  icon={<SnippetsOutlined />}
                  onClick={handleOpenSearch}
                  // disabled={!branchID ? true : false}
                />
              </Tooltip>
              <Tooltip title="Refresh">
                <Button
                  type="dashed"
                  shape="circle"
                  icon={<RedoOutlined />}
                  onClick={handleRefresh}
                />
              </Tooltip>

              <Tooltip title="Print">
                <Button
                  type="dashed"
                  shape="circle"
                  icon={<PrinterOutlined />}
                  onClick={handlePrint}
                />
              </Tooltip>
            </Space>
          </Col>
        </Row>

        <Table
          loading={isLoading}
          rowKey="time_stamp"
          dataSource={deliveryListTemp}
          columns={columns}
          style={{ marginTop: 20 }}
          size="small"
          scroll={{ x: 300 }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (e) => {
                handleRowclick(record);
              }, // click row
            };
          }}
          summary={(pageData) => {
            let t = 0;
            pageData.forEach(({ total }) => {
              t += total;
            });
            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={3}>
                    <b>Total</b>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1} align="right" colSpan={3}>
                    &#8369;{formatNumber(t)}
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
          pagination={false}
          onChange={handleTableChange}
          // onChange={handleTableOnChange}
        />

        <ItemSelection
          items={items}
          setItems={setItems}
          visibility={visibility}
          setVisibility={setVisibility}
          queryKeys={queryKeys}
          user_id={user_id}
          deliveryList={deliveryList}
          setDeliveryList={setDeliveryList}
          deliveryListTemp={deliveryListTemp}
          setDeliveryListTemp={setDeliveryListTemp}
          supplierList={supplierList}
          setSupplierList={setSupplierList}
          socket={socket}
        />
      </Card>
    </Spin>
  );
};

export default InventoryTransfer;
