import React, { useEffect, useState, useContext } from "react";
import { SocketContext } from "services/contexts/socket";

import { Card, Col, Row, Tag, Table, Space, Typography, Button, Modal, Divider, Spin } from "antd";

import { formatNumber } from "helpers/numberFormatter";
import { EditOutlined, WarningOutlined, BlockOutlined } from "@ant-design/icons";
import { titleCase } from "helpers/textTransformer";
import { useSelector } from "react-redux";
import whichOS from "helpers/whichOS";

import {
  //   useGetFav,
  // useGetItemsByGroup,
  useGetPrepDailyInventory,
  useGetTransactionTables,
  useGetPrepPendingInventoryTransfer,
  // useMutationUpdateInventory,
} from "services/hooks/api/prepAreaAPI";

import InventoryTransferPrepArea from "./components/InventoryTransferPrepArea";
import InventoryEntryBalanceSalable from "./components/InventoryEntryBalanceSalable";
// import LedgerView from "./components/LedgerView";
import InventoryEntryBalance from "./components/InventoryEntryBalance";

import OSKInline from "common/OSKInline";
const colWidth = 40;
const { Text } = Typography;
const BalanceInventory = () => {
  const socket = useContext(SocketContext);
  const {
    branch_id,
    user_id,
    username,
    inventory_cutoff,
    branch_name,
    print_server_id,
    branch_type,
  } = useSelector((state) => state.User);
  const [inventory, setInventory] = useState([]);
  // const [branches, setBranches] = useState([]);
  const [inventoryTemp, setInventoryTemp] = useState([]);
  const [inventoryFav, setInventoryFav] = useState([]);
  const [pendingTransfer, setPendingTransfer] = useState(0);

  const [searchedText, setSearchText] = useState("");
  // const { isLoading, data: inventoryData } = useGetItemsByGroup({
  //   branch_id,
  // });

  const {
    isLoading: pendingTransferIsLoading,
    data: pendingTransferData,
    refetch: pendingTransferRefetch,
  } = useGetPrepPendingInventoryTransfer({
    branch_id,
    inventory_cutoff,
  });

  const {
    isLoading: tablesIsLoading,
    data: transactionTables,
    refetch,
  } = useGetTransactionTables({ branch_id, inventory_cutoff });

  // const [ledgerParams, setledgerParams] = useState({
  //   visible: false,
  //   inventory_cutoff: null,
  //   branch_id: null,
  // });

  const [inventoryEntryParams, setInventoryEntryParams] = useState({
    visible: false,
    inventory_cutoff,
    branch_id,
    user_id,
    username,
    branch_name,
    print_server_id,
    dineInTables: [],
    selectedItem: null,
  });

  const [inventoryEntryBalanceParams, setInventoryEntryBalanceParams] = useState({
    visible: false,
    inventory_cutoff,
    branch_id,
    user_id,
    username,
    branch_name,
    print_server_id,
    dineInTables: [],
    selectedItem: null,
  });

  const [inventoryTransferVisibiliy, setInventoryTransferVisibiliy] = useState(false);

  const {
    isLoading: dailyInventoryIsLoading,
    data: dailyInventoryData,
    refetch: dailyInventoryRefetch,
  } = useGetPrepDailyInventory({ branch_id, inventory_cutoff });

  // const mutationUpdateInventory = useMutationUpdateInventory();

  useEffect(() => {
    socket.on("updateTransfer", () => {
      pendingTransferRefetch();
    });
    socket.on("updateTransferAdmin", () => {
      dailyInventoryRefetch();
    });
    socket.on("updateTable", () => {
      refetch();
    });
  }, []);

  // useEffect(() => {
  //   if (!isLoading && inventoryData) {
  //     setBranches(inventoryData.branches);
  //   }
  // }, [inventoryData, isLoading]);

  useEffect(() => {
    if (!dailyInventoryIsLoading && dailyInventoryData) {
      setInventory(dailyInventoryData.inventory);
      setInventoryTemp(dailyInventoryData.inventory);
      setInventoryFav(
        dailyInventoryData.inventory.filter((favItem) => favItem.is_table_item === 1)
      );
    }
  }, [dailyInventoryIsLoading, dailyInventoryData]);

  useEffect(() => {
    if (!tablesIsLoading && transactionTables) {
      setInventoryEntryParams((prevState) => ({
        ...prevState,
        dineInTables: transactionTables,
      }));
    }
  }, [transactionTables, tablesIsLoading]);

  useEffect(() => {
    if (!pendingTransferIsLoading && pendingTransferData) {
      setPendingTransfer(pendingTransferData.total_pending);
    }
  }, [pendingTransferIsLoading, pendingTransferData]);

  const handleOnKeyBoardPress = (v) => {
    const value = v ? v.toLowerCase() : "";
    setSearchText(titleCase(value));
    setInventoryTemp(inventory.filter((obj) => obj.item_name.toLowerCase().includes(value)));
  };

  const handleOnSelect = (record) => {
    if (pendingTransfer > 0) {
      Modal.info({
        title: "There are pending incoming stock transfer!",
        content: (
          <div>
            <p>All pending transfer must be accepted before processing inventory.</p>
          </div>
        ),
        onOk() {},
      });
    } else {
      setInventoryEntryParams((prevState) => ({
        ...prevState,
        visible: true,
        selectedItem: record,
      }));
    }
  };
  const handleOnEdit = (record) => {
    if (pendingTransfer > 0) {
      Modal.info({
        title: "There are pending incoming stock transfer!",
        content: (
          <div>
            <p>All pending transfer must be accepted before processing inventory.</p>
          </div>
        ),
        onOk() {},
      });
    } else {
      setInventoryEntryBalanceParams((prevState) => ({
        ...prevState,
        visible: true,
        selectedItem: record,
      }));
    }
  };

  const handleSelectTag = (record) => {
    handleOnSelect(record);
  };

  const handeInventoryTrasnferView = () => {
    setInventoryTransferVisibiliy(true);
  };

  // const handleFav = (value, item, branch_id) => {
  //   const updateData = {
  //     item_id: item.item_id,
  //     branch_id: branch_id,
  //     data: {
  //       is_fav: value,
  //     },
  //   };

  //   mutationUpdateInventory.mutate(updateData, {
  //     onError: (error) => {
  //       message.error("Something went wrong :/");
  //       // An error happened!
  //       console.log(`Error:  ${error.message}`);
  //     },
  //     onSuccess: (data) => {
  //       if (data.error) {
  //         message.error(data.message);
  //       } else {
  //         message.success("Fav list updated!");

  //         if (inventoryEntryParams.selectedItem) {
  //           setInventoryEntryParams((prevState) => ({
  //             ...prevState,
  //             selectedItem: { ...prevState.selectedItem, is_fav: value },
  //           }));
  //         }
  //         setInventory((prevInvState) =>
  //           prevInvState.map((invItem) => {
  //             if (invItem.item_id === item.item_id) {
  //               return { ...item, is_fav: value };
  //             } else {
  //               return invItem;
  //             }
  //           })
  //         );
  //         setInventoryTemp((prevInvTempState) =>
  //           prevInvTempState.map((invItem) => {
  //             if (invItem.item_id === item.item_id) {
  //               return { ...item, is_fav: value };
  //             } else {
  //               return invItem;
  //             }
  //           })
  //         );

  //         if (value) {
  //           setInventoryFav((prevInvFavState) => [...prevInvFavState, { ...item, is_fav: value }]);
  //         } else {
  //           setInventoryFav((prevInvFavState) =>
  //             prevInvFavState.filter((favItem) => favItem.item_id !== item.item_id)
  //           );
  //         }
  //       }
  //     },
  //   });
  // };

  const columnDailyInventory = [
    {
      title: "Item",
      width: 120,
      fixed: "left",
      dataIndex: "item_name",
      key: "item_name",
      ellipsis: true,
      // key: "item_name",
      render: (text, record) => (
        <Space size={2}>
          <Button
            type="link"
            icon={<EditOutlined />}
            size="small"
            disabled={record.is_table_item ? true : false}
            onClick={() => handleOnEdit(record)}
          />{" "}
          <Text ellipsis={true}>
            {record.item_name} ({record.unit})
          </Text>
          <Tag style={{ fontSize: 10 }}>{record.group_name}</Tag>
        </Space>
      ),
    },
    {
      title: "Beg",
      width: colWidth,
      dataIndex: "inventory_begin",
      key: "inventory_begin",
      align: "right",
      render: (text, record) => formatNumber(record.inventory_begin, true, 3),
    },
    {
      title: "Add",
      width: colWidth,
      dataIndex: "inventory_add",
      key: "inventory_add",
      align: "right",
      render: (text, record) => formatNumber(record.inventory_add, true, 3),
    },
    {
      title: "Tot",
      width: colWidth,
      dataIndex: "inventory_day_total",
      key: "inventory_day_total",
      align: "right",
      render: (text, record) => formatNumber(record.inventory_day_total, true, 3),
    },
    {
      title: "Ret",
      width: colWidth,
      dataIndex: "inventory_return",
      key: "inventory_return",
      align: "right",
      render: (text, record) => formatNumber(record.inventory_return, true, 3),
    },
    {
      title: "Out",
      width: colWidth,
      dataIndex: "inventory_out",
      key: "inventory_out",
      align: "right",
      render: (text, record) => formatNumber(record.inventory_out, true, 3),
    },
    {
      title: "Spo",
      width: colWidth,
      dataIndex: "inventory_spoil",
      key: "inventory_spoil",
      align: "right",
      render: (text, record) => formatNumber(record.inventory_spoil, true, 3),
    },
    {
      title: "Trans",
      width: colWidth,
      dataIndex: "inventory_transfer",
      key: "inventory_transfer",
      align: "right",
      render: (text, record) => formatNumber(record.inventory_transfer, true, 3),
    },
    {
      title: "Cons",
      width: colWidth,
      dataIndex: "inventory_consumed",
      align: "right",
      render: (text, record) => (
        <Text type="danger">{formatNumber(record.inventory_consumed, true, 3)}</Text>
      ),
    },
    {
      title: "Bal",
      width: colWidth,
      dataIndex: "inventory_balance",
      key: "inventory_balance",
      fixed: "right",
      align: "right",
      render: (text, record) => formatNumber(record.inventory_balance, true, 3),
    },
  ];

  return (
    <Card
      bodyStyle={{
        padding: 12,
        position: "fixed",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        marginTop: 60,
        marginBottom: whichOS === "Win" ? 60 : 0,
        background: "white",
        // border: "1px solid orange",
      }}
      size="small"
    >
      <Spin spinning={dailyInventoryIsLoading}>
        <Row
          style={{
            height: "100%",
            //   border: "1px solid black",
          }}
          gutter={16}
        >
          <Col
            span={6}
            style={{
              height: "100%",
              paddingLeft: 20,
            }}
          >
            <Space size={3} direction="vertical" style={{ width: "100%" }}>
              {/* <Badge count={pendingTransfer} showZero size="small" offset={[-6, 10]}> */}
              <Button
                type="primary"
                block
                icon={<BlockOutlined />}
                onClick={handeInventoryTrasnferView}
                size="large"
              >
                Incoming ({pendingTransfer})
              </Button>
            </Space>
            <Divider>Requested Item/s</Divider>
            <div
              style={{
                overflowY: "scroll",
                height: 450,
                borderBottom: "0.3pt solid #f0f0f0",
                marginBottom: 10,
              }}
            >
              {inventoryFav.length > 0 ? (
                <Card bodyStyle={{ margin: 0, padding: 0 }}>
                  {inventoryFav.map((favitem, i) => {
                    return (
                      <Card.Grid key={i} style={gridStyle} onClick={() => handleSelectTag(favitem)}>
                        {favitem.item_name}
                      </Card.Grid>
                    );
                  })}
                </Card>
              ) : (
                <>
                  <WarningOutlined style={{ color: "#fa541c", marginRight: 2 }} />{" "}
                  <Text>Requested Item List is empty</Text>
                </>
              )}
            </div>
          </Col>
          <Col span={18} style={{ height: "100%" }}>
            <div style={{ textAlign: "right" }}>
              <span style={{ fontSize: 14 }}>
                {inventoryTemp.length}- Item/s{" "}
                <Tag color="volcano" style={{ fontSize: 14, paddin: 4 }}>
                  {" "}
                  Search - {searchedText || "none"}
                </Tag>
              </span>
            </div>

            <div style={{ height: 260, marginBottom: 20 }}>
              <Table
                columns={columnDailyInventory}
                rowKey="item_id"
                dataSource={inventoryTemp}
                pagination={false}
                style={{ minHeight: 200 }}
                scroll={{
                  y: 220,
                  x: 1000,
                }}

                // scroll={{ x: 650 }}
                // style={{ marginLeft: 0 }}
              />
            </div>
            <OSKInline oskName="oskPrepArea" onKeyBoardPress={handleOnKeyBoardPress} />
          </Col>
        </Row>
      </Spin>
      <InventoryEntryBalanceSalable
        params={inventoryEntryParams}
        setParams={setInventoryEntryParams}
        refetchTables={refetch}
        branchType={branch_type}
        setInventory={setInventory}
        setInventoryTemp={setInventoryTemp}
      />

      <InventoryEntryBalance
        params={inventoryEntryBalanceParams}
        setParams={setInventoryEntryBalanceParams}
        refetchPending={pendingTransferRefetch}
        refetchdailyInventory={dailyInventoryRefetch}
        setInventory={setInventory}
        setInventoryTemp={setInventoryTemp}
      />
      <InventoryTransferPrepArea
        inventoryTransferVisibiliy={inventoryTransferVisibiliy}
        setInventoryTransferVisibiliy={setInventoryTransferVisibiliy}
        refetchPending={pendingTransferRefetch}
        refetchMasterData={dailyInventoryRefetch}
      />
    </Card>
  );
};

export default BalanceInventory;
const gridStyle = {
  width: "100%",
  padding: 10,
  textAlign: "center",
  postition: "relative",
};
