import { useQuery, useMutation } from "react-query";
import http from "services/httpService";

async function getInventoryTransfer({ queryKey }) {
  const { branch_id, inventory_cutoff, from_branch_id } = queryKey[1];
  const { data } = await http.get(
    `/inventory-transfer/${branch_id}/${inventory_cutoff}/${from_branch_id}`
  );
  return data;
}
function useGetInventoryTransfer(data) {
  return useQuery(["inventory-transfer", data], getInventoryTransfer);
}

// // ------------Mutation

async function storeInventoryTranferItem(data) {
  const { data: storedInventoryTransferData } = await http.post(`/inventory-transfer/store`, data);
  return storedInventoryTransferData;
}
function useMutationStoreInventoryTranferItem() {
  return useMutation(storeInventoryTranferItem);
}

async function updateInventoryTranferItem(data) {
  const { data: updateInventoryTransferData } = await http.put(
    `/inventory-transfer/update/${data.time_stamp}`,
    data
  );
  return updateInventoryTransferData;
}
function useMutationUpdateInventoryTranferItem() {
  return useMutation(updateInventoryTranferItem);
}

async function deleteInventoryTranferItem(data) {
  const { data: deleteInventoryTransferData } = await http.delete(
    `/inventory-transfer/delete/${data.time_stamp}`
  );
  return deleteInventoryTransferData;
}
function useMutationDeleteInventoryTranferItem() {
  return useMutation(deleteInventoryTranferItem);
}

export {
  useGetInventoryTransfer,
  useMutationStoreInventoryTranferItem,
  useMutationUpdateInventoryTranferItem,
  useMutationDeleteInventoryTranferItem,
};
