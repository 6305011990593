//eslint-disable-next-line react-hooks/exhaustive-deps
import axios from "axios";
import config from "config.json";

const axiosInstance = axios.create({
  baseURL: config.apiUrl,
  withCredentials: true,
});

axiosInstance.interceptors.response.use(null, (error) => {
  if (error.response && error.response.status === 401) {
    return (window.location = `${config.appBasePath}/login`);
  }

  return Promise.reject(error);
});

export default {
  get: axiosInstance.get,
  post: axiosInstance.post,
  put: axiosInstance.put,
  delete: axiosInstance.delete,
  CancelToken: axiosInstance.CancelToken,
  all: axiosInstance.all,
  spread: axiosInstance.spread,
};
