import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Drawer,
  Input,
  InputNumber,
  Button,
  Divider,
  Form,
  message,
  Checkbox,
  Typography,
  Select,
  Space,
} from "antd";
// import { ExpandOutlined } from "@ant-design/icons";
import { upperCase, titleCase } from "helpers/textTransformer";
import { generateString } from "helpers/randomStringGenerator";
import { useMutationStoreUser, useMutationUpdateUser } from "services/hooks/api/userAPI";

const { Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;

const FormUser = ({ params, setParams, otherData, setUsers, setUsersTemp }) => {
  const { user_id } = useSelector((state) => state.User);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isItem, setIsItem] = useState(false);
  const [form] = Form.useForm(); // other details form
  const [others, setOthers] = useState({ role_name: "", branch_name: "" });
  const mutationStoreUser = useMutationStoreUser();
  const mutationUpdateUser = useMutationUpdateUser();

  useEffect(() => {
    if (params.isEdit && params.user) {
      form.setFieldsValue({
        ...params.user,
        status: params.user.status === "active" ? true : false,
        branch_id: params.user.branch_id,
        role_id: params.user.role_id,
      });
      setOthers({
        role_name: params.user.role_name,
        branch_name: params.user.branch_name,
      });
    } else {
      form.setFieldsValue({
        branch_id: null,
        role_id: null,
        status: true,
        password: generateString(6, "", "123765098234716381236"),
      });
    }
  }, [params, form]);

  const handleClose = () => {
    form.resetFields();
    // setIsEdit(false);
    setIsSubmitting(false);
    setParams({ visible: false, user: null, isEdit: false });
  };

  const handleSave = (e, mode) => {
    if (!isSubmitting) {
      setIsSubmitting(true);
      form
        .validateFields()
        .then(async (values) => {
          if (params.isEdit) {
            const reducedValues = Object.keys(values).reduce((object, key) => {
              if (!["user_id"].includes(key)) {
                object[key] = values[key];
              }
              return object;
            }, {});

            const updateData = {
              ...reducedValues,
              status: values.status ? "active" : "inactive",
            };
            mutationUpdateUser.mutate(
              { user_id: values.user_id, data: updateData },
              {
                onError: (error) => {
                  message.error("Something went wrong :/");
                  // An error happened!
                  setIsSubmitting(false);
                  console.log(`Error:  ${error.message}`);
                },
                onSuccess: (data) => {
                  message.success(data.message);
                  setIsSubmitting(false);
                  setUsersTemp((prevState) =>
                    prevState.map((u) => {
                      if (u.user_id === values.user_id) {
                        return {
                          ...u,
                          ...values,
                          ...updateData,
                          ...others,
                          full_name: values.lastname + ", " + values.firstname,
                        };
                      } else {
                        return u;
                      }
                    })
                  );
                  setUsers((prevState) =>
                    prevState.map((u) => {
                      if (u.user_id === values.user_id) {
                        return {
                          ...u,
                          ...values,
                          ...updateData,
                          ...others,
                          full_name: values.lastname + ", " + values.firstname,
                        };
                      } else {
                        return u;
                      }
                    })
                  );
                  setIsSubmitting(false);
                  handleClose();
                },
              }
            );
          } else {
            const userID = generateString(8, "U");
            const storeData = {
              ...values,
              user_id: userID,
              status: values.status ? "active" : "inactive",
            };

            mutationStoreUser.mutate(
              { data: storeData },
              {
                onError: (error) => {
                  message.error("Something went wrong :/");
                  // An error happened!
                  setIsSubmitting(false);
                  console.log(`Error:  ${error.message}`);
                },
                onSuccess: (data) => {
                  if (data.error) {
                    message.error(data.message);
                  } else {
                    message.success(data.message);
                    setIsSubmitting(false);
                    setUsers((prevState) => [
                      ...prevState,
                      {
                        ...storeData,
                        ...others,
                        full_name: values.lastname + ", " + values.firstname,
                      },
                    ]);
                    setUsersTemp((prevState) => [
                      ...prevState,
                      {
                        ...storeData,
                        ...others,
                        full_name: values.lastname + ", " + values.firstname,
                      },
                    ]);

                    if (mode === "savenew") {
                      form.resetFields();
                      // setIsEdit(false);
                      setIsSubmitting(false);
                      setParams({ visible: true, user: null, isEdit: false });
                    } else {
                      handleClose();
                    }
                  }
                },
              }
            );
          }
        })
        .catch((errorInfo) => {
          console.log(errorInfo);
          message.warning("Unable to save, look's like you missed something.");
          setIsSubmitting(false);
        });
    }
  };

  const handleFormValueChange = (v) => {
    const key = Object.keys(v)[0];
    const value = v[key];

    if (key === "role_id") {
      const rl = otherData.role.filter((r) => r.role_id === value);
      setOthers({ ...others, role_name: rl[0].role_name });
    }

    if (key === "branch_id") {
      const br = otherData.branch.filter((b) => b.branch_id === value);
      setOthers({ ...others, branch_name: br[0].branch_name });
    }

    // console.log(form.getFieldValue(key));
  };

  const ButtonGroup = ({ isLoading }) => {
    return (
      <Space size={1}>
        <Button onClick={handleClose} disabled={isLoading}>
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={(e) => handleSave(e, "save")}
          loading={isLoading}
          style={{ marginLeft: 10 }}
        >
          {params.isEdit ? "Update" : "Save"}
        </Button>

        <Button
          type="primary"
          onClick={(e) => handleSave(e, "savenew")}
          loading={isLoading}
          style={{ marginLeft: 10 }}
          disabled={params.isEdit}
        >
          Save & New
        </Button>
      </Space>
    );
  };

  const handlesBlur = (e, mode) => {
    const { id, value } = e.target;
    if (mode === "upper") {
      form.setFieldsValue({ [id]: upperCase(value) });
    } else {
      form.setFieldsValue({ [id]: titleCase(value) });
    }
  };

  const genPass = () => {
    form.setFieldsValue({
      password: generateString(6, "", "123765098234716381236"),
    });
  };

  return (
    <Drawer
      title={params.isEdit ? "Edit User" : "Add User"}
      placement="right"
      closable={false}
      visible={params.visible}
      destroyOnClose={true}
      width={350}
      forceRender
    >
      <Form
        form={form}
        // initialValues={{
        //   remember: true,
        // }}
        autoComplete="off"
        onValuesChange={handleFormValueChange}
      >
        {params.isEdit ? (
          <Form.Item
            label="User ID"
            name="user_id"
            rules={[
              {
                required: true,
                message: "This is required!",
              },
            ]}
            style={formItemLayout}
          >
            <Input disabled />
          </Form.Item>
        ) : (
          <Form.Item
            label="User ID"
            rules={[
              {
                required: true,
                message: "This is required!",
              },
            ]}
            style={formItemLayout}
          >
            <Text code>Auto Generate</Text>
          </Form.Item>
        )}

        <Form.Item
          label="Lastname"
          name="lastname"
          rules={[
            {
              required: true,
              message: "This is required!",
            },
          ]}
          style={formItemLayout}
        >
          <Input onBlur={(e) => handlesBlur(e, "title")} maxLength={25} />
        </Form.Item>

        <Form.Item
          label="Firstname"
          name="firstname"
          rules={[
            {
              required: true,
              message: "This is required!",
            },
          ]}
          style={formItemLayout}
        >
          <Input onBlur={(e) => handlesBlur(e, "title")} maxLength={25} />
        </Form.Item>

        <Form.Item label="Middlename" name="middlename" style={formItemLayout}>
          <Input onBlur={(e) => handlesBlur(e, "title")} maxLength={25} />
        </Form.Item>

        <Form.Item
          label="Role"
          name="role_id"
          rules={[
            {
              required: true,
              message: "This is required!",
            },
          ]}
          style={formItemLayout}
        >
          <Select>
            {otherData.role.map((role) => {
              return (
                <Option value={role.role_id} key={role.role_id}>
                  {role.role_name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          label="Branch"
          name="branch_id"
          rules={[
            {
              required: true,
              message: "This is required!",
            },
          ]}
          style={formItemLayout}
        >
          <Select>
            {otherData.branch.map((branch) => {
              return (
                <Option value={branch.branch_id} key={branch.branch_id}>
                  {branch.branch_name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <Divider plain>Log-In Credentials</Divider>

        <Form.Item
          label="User name"
          name="username"
          rules={[
            {
              required: true,
              message: "This is required!",
            },
          ]}
          style={formItemLayout}
        >
          <Input maxLength={10} disabled={params.isEdit ? true : false} />
        </Form.Item>

        <Form.Item
          name="status"
          valuePropName="checked"
          style={{ marginTop: 12, marginBottom: 12 }}
        >
          <Checkbox>Check if Active</Checkbox>
        </Form.Item>

        {!params.isEdit && (
          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: "This is required!",
              },
            ]}
          >
            <Input.Password
              maxLength={6}
              addonAfter={
                <Button size="small" onClick={genPass}>
                  Generate{" "}
                </Button>
              }
            />
          </Form.Item>
        )}
      </Form>

      <Divider />
      <ButtonGroup isLoading={isSubmitting} />
    </Drawer>
  );
};

export default FormUser;

const formItemLayout = {
  padding: 0,
  margin: 2,
};
