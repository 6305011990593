import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Table,
  Avatar,
  Tag,
  Space,
  Button,
  Typography,
  Divider,
  Collapse,
  Row,
  Col,
  DatePicker,
  Alert,
  message,
  Empty,
} from "antd";

import { useSelector, useDispatch } from "react-redux";
import { setTransactionDetails } from "services/redux/slices/transaction";
import { setTransStatus } from "services/redux/slices/transStatus";
// import { setUsedTables } from "services/redux/slices/usedTables";
import customStyles from "helpers/customStyles";
import moment from "moment";
import { formatNumber } from "helpers/numberFormatter";
import { useGetTransactions } from "services/hooks/api/transactionAPI";
import {
  RightOutlined,
  UsergroupAddOutlined,
  ClockCircleOutlined,
  RightCircleOutlined,
  LeftCircleOutlined,
  PlusOutlined,
  LineChartOutlined,
} from "@ant-design/icons";
import { hoursFormatter } from "helpers/hoursFormatter";
import Statistics from "common/Statistics";
import ContentLoader from "common/ContentLoader";
import RetryMessage from "common/RetryMessage";

const { Text, Title } = Typography;
const { Panel } = Collapse;
const Cashier = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { branch_id, cutoff, dine_in_mins, role_id } = useSelector((state) => state.User);
  const transStatus = useSelector((state) => state.TransStatus);
  const [cutOffDate, setCutOffDate] = useState(moment(cutoff));
  const [preventEdit, setPreventEdit] = useState(false);

  const {
    isLoading,
    data: transactionData,
    isError,
    error,
    refetch,
  } = useGetTransactions({ branch_id, cutoff: moment(cutOffDate).format("YYYY-MM-DD") });

  useEffect(() => {
    if (cutoff !== moment(cutOffDate).format("YYYY-MM-DD")) {
      setPreventEdit(true);
    } else if (cutoff === moment(cutOffDate).format("YYYY-MM-DD")) {
      setPreventEdit(false);
    }
  }, [cutOffDate]);

  // console.log(transStatus);
  const columns = [
    {
      title: "#",
      dataIndex: "name",
      key: "name",
      width: 35,
      align: "center",
      render: (text, record, index) =>
        record.status === "started" ? (
          <Avatar size={24} style={customStyles.avatarStarted}>
            {index + 1}
          </Avatar>
        ) : record.status === "deleted" ? (
          <Avatar size={24} style={customStyles.avatarDeleted}>
            {index + 1}
          </Avatar>
        ) : (
          <Avatar size={24}>{index + 1}</Avatar>
        ),
    },

    {
      title: "Table",
      dataIndex: "tables",
      key: "tables",
      width: 80,
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => (
        <span
          style={{
            textDecoration: record.status === "deleted" ? "line-through" : "none",
          }}
        >
          {record.tables.replace("[", "").replace("]", "")}
        </span>
      ),
    },
    {
      title: "Details",
      dataIndex: "details",
      key: "status",
      render: (text, record) => (
        <>
          <Space size={1}>
            <span
              style={{
                textDecoration: record.status === "deleted" ? "line-through" : "none",
                marginRight: 4,
              }}
            >
              &#8369;{formatNumber(record.total)}
            </span>
            <Tag
              color={
                record.status === "started"
                  ? "#096dd9"
                  : record.status === "deleted"
                  ? "#fa541c"
                  : ""
              }
              style={{
                fontSize: 9,
                padding: "0 4px",
              }}
            >
              {record.status}: {record.status === "done" ? hoursFormatter(record.duration, 1) : ""}
            </Tag>

            {record.status === "done" && (
              <Tag
                color={
                  record.status === "started"
                    ? "#096dd9"
                    : record.status === "deleted"
                    ? "#fa541c"
                    : ""
                }
                style={{
                  fontSize: 9,
                  padding: "0 4px",
                }}
              >
                Pax: {record.pax}
              </Tag>
            )}
          </Space>

          {record.status !== "done" && record.status !== "deleted" && (
            <>
              <br />
              <Text
                style={{
                  fontSize: 11,
                  borderTop: "1px solid #f0f0f0",
                }}
              >
                <UsergroupAddOutlined style={{ color: "#40a9ff" }} />
                {record.pax} <RightCircleOutlined style={{ color: "#40a9ff" }} /> S-
                {moment(record.start).format("h:mma")}{" "}
                <ClockCircleOutlined
                  style={{
                    color: "#f5222d",
                  }}
                />{" "}
                EE-
                {moment(record.start).add(dine_in_mins, "minutes").format("h:mma")}
                {/* {record.end && record.end !== "Invalid date" ? (
                  <>
                    {" "}
                    <LeftCircleOutlined
                      style={{
                        color: "#40a9ff",
                      }}
                    />{" "}
                    E-
                    {record.start}{" "}
                  </>
                ) : (
                  <>
                    
                  </>
                )} */}
              </Text>
            </>
          )}
        </>
      ),
      filters: [
        {
          text: "Started",
          value: "started",
        },
        {
          text: "Done",
          value: "done",
        },
        {
          text: "Deleted",
          value: "deleted",
        },
      ],
      defaultFilteredValue: transStatus, //[], //["pending"],
      onFilter: (value, record) => record.status.indexOf(value) === 0,
    },
    {
      title: "",
      dataIndex: "actions",
      width: 20,
      render: (text, record, index) =>
        record.status !== "done" && (
          <RightOutlined
            style={{
              fontSize: 12,
              color: "#8c8c8c",
            }}
          />
        ),
    },
  ];

  const handleRowClick = (e, r, i) => {
    dispatch(
      setTransactionDetails({
        ...r,
        row_index: i + 1,
      })
    );
    // dispatch(setUsedTables(transactionData.usedTables));
    navigate("/transaction");
  };

  const handleAddNew = (e, r, i) => {
    dispatch(setTransactionDetails(null));
    // dispatch(setUsedTables(transactionData.usedTables));
    navigate("/transaction");
  };

  const onChange = (_, filters) => {
    dispatch(setTransStatus(filters.status));
  };

  const handleDateChange = (date, dateString) => {
    setCutOffDate(date);
  };

  if (isLoading) {
    return <ContentLoader />;
  }

  if (isError) {
    return (
      <Card>
        <RetryMessage message={error.message} onRetry={() => refetch()} />
      </Card>
    );
  }

  if (transactionData && !isError) {
    const { transactions, summary, transGroup, stat } = transactionData;

    return (
      <>
        <Card bodyStyle={{ padding: "12px 14px" }} size="small">
          {role_id === 1 || role_id === 5 ? (
            <>
              {cutoff !== moment(cutOffDate).format("YYYY-MM-DD") && (
                <Alert
                  message={<b>Warning</b>}
                  description={
                    <span>
                      Date specified is not the actual <b>CutOff.</b>
                    </span>
                  }
                  type="warning"
                  showIcon
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                />
              )}
              <Row
                gutter={16}
                style={{
                  // borderStyle: "dashed",
                  border:
                    cutoff !== moment(cutOffDate).format("YYYY-MM-DD")
                      ? "4px dashed #f5222d"
                      : "4px dashed #1d39c4",

                  padding: 6,
                  margin: 2,
                  marginBottom: 8,
                }}
              >
                <Col
                  span={12}
                  style={{
                    paddingTop: 6,
                    paddingLeft: 30,
                  }}
                >
                  <b>CutOff Date</b>
                </Col>
                <Col span={12}>
                  <DatePicker
                    value={cutOffDate}
                    onChange={handleDateChange}
                    style={{ width: "100%" }}
                    disabledDate={(current) => {
                      let customDate = moment(cutoff).add(1, "d").format("YYYY-MM-DD");
                      return current >= moment(customDate, "YYYY-MM-DD");
                    }}
                  />
                </Col>
              </Row>
            </>
          ) : null}

          <Collapse>
            <Panel
              header={
                <Title level={5} style={{ padding: 0, margin: 0 }}>
                  <LineChartOutlined /> Statistics
                </Title>
              }
              key="1"
              showArrow={false}
            >
              <Statistics cutoff={cutoff} stat={stat[0]} group={transGroup} circleWidth={70} />
            </Panel>
          </Collapse>

          <div
            style={{
              // minHeight: 60,
              // backgroundColor: "#002766",
              // color: "#8c8c8c",
              padding: 4,
              marginTop: 6,
              marginBottom: 6,
              fontSize: 12,
              borderRadius: 6,
              overflow: "none",
              overflowY: "auto",
              textAlign: "center",
              border: "1px dashed #096dd9",
            }}
          >
            <Space
              size="small"
              split={<Divider type="vertical" />}
              // style={{ marginTop: 6, fontSize: 12 }}
            >
              <span>
                <b>Count</b> <br></br>
                {summary.total_transaction || 0}
              </span>
              <span>
                <b>Pax</b> <br></br>
                {summary.total_pax || 0}
              </span>
              <span>
                <b>Total</b> <br></br>&#8369;
                {formatNumber(summary.total_sales)}
              </span>
              <span>
                <b>Tables</b> <br></br> {summary.total_tables || 0}
              </span>
              {!preventEdit && (
                <Button
                  type="primary"
                  ghost
                  // size="small"
                  shape="circle"
                  icon={<PlusOutlined />}
                  onClick={handleAddNew}
                />
              )}
            </Space>
          </div>

          <Table
            dataSource={transactions}
            rowKey="transaction_id"
            columns={columns}
            size="small"
            pagination={false}
            expandable={{
              columnWidth: 24,
              expandedRowRender: (record) => (
                <>
                  {record.status === "done" && (
                    <div
                      style={{
                        textAlign: "right",
                        fontSize: 12,
                        marginBottom: 6,
                      }}
                    >
                      <Space align="center">
                        <span>
                          <UsergroupAddOutlined
                            style={{
                              color: "#40a9ff",
                            }}
                          />{" "}
                          Pax-{record.pax}
                        </span>

                        <span>
                          <RightCircleOutlined
                            style={{
                              color: "#40a9ff",
                            }}
                          />{" "}
                          S-
                          {moment(record.start).format("h:mma")}
                        </span>
                        <span>
                          <LeftCircleOutlined
                            style={{
                              color: "#40a9ff",
                            }}
                          />{" "}
                          E-
                          {moment(record.end).format("h:mma")}
                        </span>
                        <span>
                          <ClockCircleOutlined
                            style={{
                              color: "#f5222d",
                            }}
                          />{" "}
                          ST-
                          {hoursFormatter(record.duration, 1)}
                        </span>
                      </Space>
                    </div>
                  )}

                  {/* {record.end &&
                  record.end !==
                    "Invalid date" ? (
                    <>
                      {" "}
                     
                    </>
                  ) : (
                    <>
                      <ClockCircleOutlined
                        style={{
                          color: "#f5222d",
                        }}
                      />{" "}
                      EE-
                      {moment(record.start)
                        .add(
                          dine_in_mins,
                          "minutes"
                        )
                        .format("h:mma")}
                    </>
                  )} */}
                  <table
                    style={{
                      paddingLeft: 12,
                      fontSize: 12,
                      marginBottom: 10,
                    }}
                  >
                    <tbody>
                      <tr
                        style={{
                          borderBottom: "1px solid #bfbfbf",
                          backgroundColor: "#d9d9d9",
                        }}
                      >
                        <th>#</th>
                        <th>Item</th>
                        <th>Qty</th>
                        <th>Price</th>
                        <th>Total</th>
                      </tr>
                      {record.transaction_item.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.item_name}</td>
                            <td>{item.qty}</td>
                            <td>{item.price}</td>
                            <td>{item.total_price}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <Text style={{ paddingLeft: 18 }}>Requested Item</Text>
                  <table
                    style={{
                      paddingLeft: 12,
                      fontSize: 12,
                    }}
                  >
                    <tbody>
                      <tr
                        style={{
                          borderBottom: "1px solid #bfbfbf",
                          backgroundColor: "#bae7ff",
                        }}
                      >
                        <th>#</th>
                        <th>Item</th>
                        <th>Qty</th>
                      </tr>
                      {record.requested_item.map((r, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{r.item_name}</td>
                            <td>{r.qty * -1}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>

                  {record.requested_item.length <= 0 && (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      imageStyle={{ height: 25 }}
                      style={{ padding: 0, margin: 0, marginTop: 5 }}
                    />
                  )}
                </>
              ),
              // rowExpandable: (record) => record.name !== 'Not Expandable',
            }}
            onRow={(record, rowIndex) => {
              return {
                onClick: (e) => {
                  if (!preventEdit) {
                    handleRowClick(e, record, rowIndex);
                  } else {
                    message.info("Edit not available...");
                  }
                }, // click row
              };
            }}
            onChange={onChange}
          />
        </Card>
      </>
    );
  }
};

export default Cashier;
