import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { formatNumber } from "helpers/numberFormatter";
import {
  Card,
  Row,
  Col,
  DatePicker,
  Alert,
  Divider,
  Descriptions,
  Collapse,
  Table,
  Button,
  message,
  Modal,
  Spin,
  Typography,
} from "antd";
import {
  DotChartOutlined,
  UnlockOutlined,
  LockOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

import { useGetSalesSummary, useMutationLockSummary } from "services/hooks/api/salesSummaryAPI";

import ContentLoader from "common/ContentLoader";
import RetryMessage from "common/RetryMessage";
import UnliList from "./components/UnliList";

const { Panel } = Collapse;

const { Text } = Typography;
const SalesSummary = () => {
  const { branch_id, cutoff } = useSelector((state) => state.User);
  const [cutOffDate, setCutOffDate] = useState(moment(cutoff));
  const [unliListVisibility, setUnliListVisibility] = useState(false);
  const [tempItemsResult, setItemsResult] = useState([]);
  const [isBusy, setIsBusy] = useState(false);
  const mutationLockSummary = useMutationLockSummary();
  const { isLoading, data, error, isError, refetch } = useGetSalesSummary({
    branch_id,
    cutoff: moment(cutOffDate).format("YYYY-MM-DD"),
  });

  useEffect(() => {
    if (!isLoading && data) {
      setItemsResult(data.itemsResult);
    }
  }, [isLoading, data]);

  const handleDateChange = (date) => {
    setCutOffDate(date);
  };

  const groupColumn = [
    {
      title: "Group",
      dataIndex: "group_name",
      ellipsis: true,
      width: 80,
      fixed: "left",
    },
    {
      title: "Consumed",
      dataIndex: "consumed",
      align: "right",
      render: (text, record) => <span>{formatNumber(record.consumed, true, 3)} </span>,
    },
    {
      title: "Price",
      dataIndex: "total",
      align: "right",
      render: (text, record) => <span>&#8369;{formatNumber(record.total, true, 2)} </span>,
    },
    {
      title: "Pax",
      dataIndex: "pax",
      align: "right",
    },
    {
      title: "Ave. Qty",
      dataIndex: "average_consumed",
      align: "right",
      render: (text, record) => <span>{formatNumber(record.average_consumed, true, 3)} </span>,
    },
    {
      title: "Ave Price",
      dataIndex: "average_price",
      align: "right",
      render: (text, record) => <span>&#8369;{formatNumber(record.average_price, true, 2)} </span>,
    },
  ];

  const groupExtra = () => (
    <DotChartOutlined
      onClick={(event) => {
        // If you don't want click extra trigger collapse, you can prevent this:
        event.stopPropagation();
        setUnliListVisibility(true);
      }}
      style={{ fontSize: 18 }}
    />
  );
  const itemColumn = [
    {
      title: "Item",
      dataIndex: "item_name",
      ellipsis: true,
      width: 120,
      fixed: "left",
    },
    {
      title: "Unit",
      dataIndex: "unit",
      ellipsis: true,
      width: 60,
    },
    {
      title: "Cons",
      dataIndex: "qty_consumed",
      width: 70,
      align: "right",
      render: (text, record) => <span>{formatNumber(record.qty_consumed, true, 3)} </span>,
    },
    {
      title: "UPrice",
      dataIndex: "total",
      align: "right",
      render: (text, record) => <span>&#8369;{formatNumber(record.unit_price, true, 3)} </span>,
    },

    {
      title: "Total",
      dataIndex: "average_consumed",
      align: "right",
      render: (text, record) => <span>&#8369;{formatNumber(record.total, true, 3)} </span>,
    },
  ];

  const handleLockSales = () => {
    Modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: "This action is irreversible, are your sure?",
      okText: "Ok",
      cancelText: "Cancel",
      onOk() {
        const finalStoreData = Object.keys(data).reduce((object, key) => {
          if (!["locked"].includes(key)) {
            object[key] = data[key];
          }
          return object;
        }, {});
        mutationLockSummary.mutate(
          {
            summary: {
              branch_id,
              cutoff: moment(cutOffDate).format("YYYY-MM-DD"),
              data: finalStoreData,
            },
          },
          {
            onError: (error) => {
              message.error("Something went wrong :/");
              // An error happened!
              setIsBusy(false);
              console.log(`Error:  ${error.message}`);
            },
            onSuccess: (data) => {
              message.success("Sales locked!");
              setIsBusy(false);
              refetch();
            },
          }
        );
      },
    });
  };
  if (isLoading) {
    return <ContentLoader />;
  }

  if (isError) {
    return (
      <Card>
        <RetryMessage message={error.message} onRetry={() => refetch()} />
      </Card>
    );
  }
  if (data && !isError) {
    const { groupResult, otherResult, salableResult, summary, locked } = data;

    return (
      <>
        {" "}
        <Spin tip="Locking Sales" spinning={isBusy}>
          <Card>
            <Row
              gutter={16}
              style={{
                // borderStyle: "dashed",
                border:
                  cutoff !== moment(cutOffDate).format("YYYY-MM-DD")
                    ? "4px dashed #f5222d"
                    : "4px dashed #1d39c4",

                padding: 6,
              }}
            >
              <Col span={12}>
                <b>CutOff Date</b>
                <DatePicker
                  value={cutOffDate}
                  onChange={handleDateChange}
                  style={{ width: "100%" }}
                  disabledDate={(current) => {
                    let customDate = moment(cutoff).add(1, "d").format("YYYY-MM-DD");
                    return current >= moment(customDate, "YYYY-MM-DD");
                  }}
                  allowClear={false}
                />
              </Col>
              <Col span={12}>
                <Button
                  type="primary"
                  ghost
                  icon={locked ? <LockOutlined /> : <UnlockOutlined />}
                  block
                  size="large"
                  disabled={locked}
                  onClick={() => handleLockSales()}
                  style={{ height: "100%" }}
                >
                  Lock
                </Button>
              </Col>
            </Row>

            {cutoff !== moment(cutOffDate).format("YYYY-MM-DD") && (
              <Alert
                message={<b>Warning</b>}
                description={
                  <span>
                    Date specified is not the actual <b>CutOff.</b>
                  </span>
                }
                type="warning"
                showIcon
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                }}
              />
            )}

            <Divider style={{ margin: 6 }}>Sales Summary</Divider>
            <Descriptions size="small" bordered column={1}>
              <Descriptions.Item
                label="Pax  Count"
                style={{
                  ...style.descriptionText,
                  // backgroundColor: "#2f54eb",
                }}
                contentStyle={{
                  textAlign: "right",
                }}
              >
                {summary.pax}
              </Descriptions.Item>

              <Descriptions.Item
                label="Gross Sales"
                style={{
                  ...style.descriptionText,
                  backgroundColor: "#2f54eb",
                  color: "white",
                }}
                contentStyle={{
                  textAlign: "right",
                }}
              >
                &#8369;
                {formatNumber(summary.gross)}
              </Descriptions.Item>

              <Descriptions.Item
                label="Expense"
                style={style.descriptionText}
                contentStyle={{
                  textAlign: "right",
                }}
              >
                &#8369;
                {formatNumber(summary.expense)}
              </Descriptions.Item>
              <Descriptions.Item
                label="Unli Item Cost"
                style={style.descriptionText}
                contentStyle={{
                  textAlign: "right",
                }}
              >
                &#8369;
                {formatNumber(summary.unli_cost)}
              </Descriptions.Item>
              <Descriptions.Item
                label="Salable Item Cost"
                style={style.descriptionText}
                contentStyle={{
                  textAlign: "right",
                }}
              >
                &#8369;
                {formatNumber(summary.salable_cost)}
              </Descriptions.Item>
              <Descriptions.Item
                label="Other Item Cost"
                style={style.descriptionText}
                contentStyle={{
                  textAlign: "right",
                }}
              >
                &#8369;
                {formatNumber(summary.other_cost)}
              </Descriptions.Item>

              <Descriptions.Item
                label="Day Sales Revenue"
                style={{
                  ...style.descriptionText,
                  backgroundColor: "#10239e",
                  color: "white",
                  fontSize: 17,
                }}
                contentStyle={{
                  textAlign: "right",
                }}
              >
                &#8369;
                {formatNumber(summary.today_sales_revenue)}
              </Descriptions.Item>
            </Descriptions>

            <Collapse
            // defaultActiveKey={["1"]}
            // onChange={onChange}
            >
              <Panel
                header={`Unli Cost Recap (${groupResult.length})`}
                key="1"
                extra={groupExtra()}
              >
                <Table
                  dataSource={groupResult}
                  columns={groupColumn}
                  rowKey="group_name"
                  size="small"
                  pagination={false}
                  scroll={{ x: 600 }}
                />
              </Panel>

              <Panel header={`Salable Cost Recap (${salableResult.length})`} key="2">
                <Table
                  dataSource={salableResult}
                  columns={itemColumn}
                  rowKey="item_name"
                  size="small"
                  pagination={false}
                  scroll={{ x: 450 }}
                />
              </Panel>
              <Panel header={`Other Cost Recap (${otherResult.length})`} key="3">
                <Table
                  dataSource={otherResult}
                  columns={itemColumn}
                  rowKey="item_name"
                  size="small"
                  pagination={false}
                  scroll={{ x: 450 }}
                />
              </Panel>
            </Collapse>
          </Card>
        </Spin>
        <UnliList
          visibility={unliListVisibility}
          setVisibility={setUnliListVisibility}
          data={tempItemsResult}
        />
      </>
    );
  }
};

export default SalesSummary;
const style = {
  descriptionText: {
    // fontSize: 13,
    padding: 4,
    paddingLeft: 10,
    paddingRight: 10,

    // textAlign: "center"
  },
};
