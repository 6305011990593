import React from "react";
import { Result, Button, Typography, Avatar } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { ReloadOutlined } from "@ant-design/icons";

import logo from "assets/images/logo.png";

const { Paragraph, Title } = Typography;
const IPCheckerError = () => {
  const { ip, message } = useParams();
  const navigate = useNavigate();
  return (
    <>
      <Result
        status="error"
        title={
          <>
            <Title level={5}>{message}</Title>
            <Paragraph copyable style={{ marginBottom: 0 }}>
              {ip}
            </Paragraph>
          </>
        }
        subTitle={
          <>
            To unlock copy and send the above IP Address to your Manager <br></br>
            <Avatar size={90} src={logo} shape="square" style={{ marginTop: 20 }} />
          </>
        }
        extra={
          <Button onClick={() => navigate("/login")} size="large" icon={<ReloadOutlined />}>
            Reload
          </Button>
        }
        style={{ height: "calc(100vh)" }}
      />
    </>
  );
};

export default IPCheckerError;
