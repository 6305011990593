import React, { useState, useEffect } from "react";

import {
  Typography,
  Divider,
  Space,
  Button,
  Drawer,
  Collapse,
  Table,
  Input,
  Descriptions,
  InputNumber,
  Row,
  Col,
  Card,
  message,
} from "antd";

import {
  SearchOutlined,
  CheckOutlined,
  PlusOutlined,
  SaveOutlined,
  DeleteOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { useGetSaleableItems } from "services/hooks/api/itemsAPI";
// import customStyles from "helpers/customStyles";
import ContentLoader from "common/ContentLoader";
import RetryMessage from "common/RetryMessage";
import { formatNumber } from "helpers/numberFormatter";

const { Text } = Typography;
const FormItem = ({ formItemParams, setFormItemParams, onSave }) => {
  const [dataSource, setDataSource] = useState([]);
  const [iscollapse, setIsCollapse] = useState([0]);
  // const [searchValue, setSearchValue] = useState("");
  const [item, setItem] = useState(null);
  const { isLoading, data: itemData, isError, error, refetch } = useGetSaleableItems();

  useEffect(() => {
    if (!isLoading) {
      setDataSource(itemData);
      // console.log(isNewTransaction);
    }
  }, [itemData, isLoading]);

  useEffect(() => {
    if (formItemParams.data) {
      setItem(formItemParams.data);
    }
  }, [formItemParams]);

  const handleClose = () => {
    setItem(null);
    setFormItemParams({
      data: null,
      isEdit: false,
      visibility: false,
    });
  };

  const handleSearchOnChange = (e) => {
    e.preventDefault();
    const value = e.target.value;
    // setSearchValue(value);
    setDataSource(
      itemData.filter((obj) => obj.item_name.toLowerCase().includes(value.toLowerCase()))
    );
  };

  const handleOnFocus = () => {
    setIsCollapse([1]);
  };
  const handleOnBlur = () => {
    setIsCollapse([0]);
    // setSearchValue("");
  };

  const handleOnSelect = (e, r) => {
    const d = new Date();
    const timeStamp = d.getTime();
    const sellingPrice = Number(r.selling_price) || 0;
    setItem({
      ...r,
      price: sellingPrice,
      qty: 1,
      total_price: sellingPrice,
      is_pax_counter: r.is_pax_counter,
      time_stamp: formItemParams.isEdit ? formItemParams.data.time_stamp : timeStamp,
    });
  };

  const handleOnQtyChange = (value) => {
    // const value = e.target.value;
    const newQty = Number(value) || 1;
    const newTotal = newQty * Number(item.price);
    setItem({ ...item, qty: newQty, total_price: newTotal });
  };

  const handleSave = () => {
    if (!item) {
      message.error("No selected item.");
      return false;
    }
    if (onSave) {
      onSave(
        formItemParams.isEdit ? "edit" : "new",
        item,
        formItemParams.isEdit ? formItemParams.data : null
      );
    }
    if (formItemParams.isEdit) {
      handleClose();
    } else {
      setItem(null);
    }
  };

  const handleDelete = () => {
    if (!item) {
      message.error("No selected item.");
      return false;
    }
    if (onSave) {
      onSave("delete", item);
    }
    handleClose();
  };
  const columns = [
    {
      title: "Item",
      dataIndex: "item_name",
      render: (_, record) => <Text style={{ color: "#002766" }}>{record.item_name}</Text>,
    },

    {
      title: "Price",
      dataIndex: "selling_price",
      align: "right",
      width: 100,
      render: (_, record) => <Text> &#8369;{formatNumber(record.selling_price)}</Text>,
    },
  ];

  if (isLoading) {
    return <ContentLoader />;
  }

  if (isError) {
    return (
      <Card>
        <RetryMessage message={error.message} onRetry={() => refetch()} />
      </Card>
    );
  }

  if (itemData && !isError) {
    return (
      <Drawer
        headerStyle={{ padding: 0, margin: 0 }}
        title={
          formItemParams.isEdit ? (
            <Text>Edit item</Text>
          ) : (
            <>
              <Divider orientation="left" plain style={{ margin: 0, marginTop: 16, padding: 0 }}>
                Add Item
              </Divider>
              <Collapse accordion bordered={false} activeKey={iscollapse} ghost>
                <Collapse.Panel
                  header={
                    <Input
                      placeholder="Search here..."
                      size="large"
                      allowClear
                      style={{ width: "100%" }}
                      onChange={handleSearchOnChange}
                      // onKeyUp={handleSearchOnChange}
                      // value={searchValue}
                      prefix={
                        <Space>
                          <SearchOutlined style={{ marginRight: 20 }} />
                        </Space>
                      }
                      onFocus={handleOnFocus}
                      onBlur={handleOnBlur}
                    />
                  }
                  key={1}
                  showArrow={false}
                >
                  <Table
                    rowKey="item_id"
                    dataSource={dataSource}
                    columns={columns}
                    size="small"
                    pagination={false}
                    scroll={{
                      y: 200,
                    }}
                    onRow={(record) => {
                      return {
                        onClick: (event) => {
                          handleOnSelect(event, record);
                        },
                      };
                    }}
                  />
                </Collapse.Panel>
              </Collapse>
            </>
          )
        }
        width={350}
        placement="right"
        closable={false}
        visible={formItemParams.visibility}
        bodyStyle={{ padding: "4px 12px" }}
      >
        <Divider orientation="left" plain style={{ margin: 6 }}>
          Selected Item Details
        </Divider>

        <Descriptions bordered size="small" style={{ marginBottom: 12 }}>
          <Descriptions.Item
            label={
              <b>
                <CheckOutlined style={{ color: "green" }} />{" "}
                {item ? item.item_name : <Text italic>None</Text>}
              </b>
            }
            labelStyle={{ width: 250 }}
            contentStyle={{
              fontSize: 16,
            }}
          >
            <b>&#8369; {item ? formatNumber(item.price) : ""}</b>
          </Descriptions.Item>
          <Descriptions.Item
            label={<b>Input Qty</b>}
            labelStyle={{
              paddingLeft: 30,
              width: 50,
              borderRadius: 6,
              border: "2px solid #69c0ff",
              borderStyle: "dashed",
              borderRight: "none",
            }}
            contentStyle={{
              borderRadius: 6,
              border: "2px solid #69c0ff",
              borderStyle: "dashed",
              borderLeft: "none",
            }}
          >
            <InputNumber
              min={1}
              max={999}
              value={item ? item.qty : 1}
              style={{
                fontSize: 18,
                padding: 2,
                minWidth: 130,
                textAlign: "center",
              }}
              onChange={handleOnQtyChange}
              disabled={!item}
            />
          </Descriptions.Item>

          <Descriptions.Item
            label={<b>Total</b>}
            labelStyle={{ width: 250 }}
            contentStyle={{
              fontSize: 16,
            }}
          >
            <b>&#8369; {item ? formatNumber(item.total_price) : 0}</b>
          </Descriptions.Item>
        </Descriptions>

        <Row gutter={4}>
          <Col flex={2}>
            <Button
              type="primary"
              block
              size="large"
              onClick={() => handleSave()}
              icon={formItemParams.isEdit ? <SaveOutlined /> : <PlusOutlined />}
            >
              {formItemParams.isEdit ? "Update" : "Add"}
            </Button>
          </Col>

          <Col flex={2}>
            <Button
              block
              size="large"
              onClick={handleClose}
              icon={formItemParams.isEdit ? <CloseOutlined /> : <CheckOutlined />}
            >
              {formItemParams.isEdit ? "Cancel" : "Done"}
            </Button>
          </Col>
          <Col flex={2}>
            <Button
              block
              size="large"
              danger
              disabled={formItemParams.isEdit ? false : true}
              onClick={handleDelete}
              icon={<DeleteOutlined />}
            >
              Delete
            </Button>
          </Col>
        </Row>
      </Drawer>
    );
  }
};

export default FormItem;
