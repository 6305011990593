import React, { useState } from "react";

import { Space, Typography, InputNumber, Button } from "antd";
import { SaveOutlined, CloseOutlined } from "@ant-design/icons";
import { titleCase } from "helpers/textTransformer";
import OSK from "common/OSK";
const maxNumberAllowed = 9999999;
const { Title } = Typography;
const FormQuantity = ({
    onSave,
    onClose,
    selectedItem,
    process,
    isOSKEnabled = true
}) => {
    const [numberValue, setNumberValue] = useState(0);
    const [oskVisibility, setOSKVisibility] = useState(false);
    const [oskParams, setOSKParams] = useState(false);

    const handlesOnChange = (e) => {
        // e.preventDefault;
        const val = e.target.value;
        setNumberValue(val);
    };

    const showOSK = (e) => {
        if (isOSKEnabled) {
            const val = e.target.value;
            setOSKParams({
                field: null,
                label: "Must be dynamic",
                fieldValue: val,
                textFormat: null,
                isNumberOnly: true,
                maxNumberAllowed
            });
            setOSKVisibility(true);
        }
    };

    const handleOnSave = () => {
        if (onSave) {
            setNumberValue(0);
            onSave(numberValue);
        }
    };

    const handleOnClose = () => {
        setNumberValue(0);
        onClose();
    };

    return (
        <div>
            <Space direction="vertical">
                <Title level={5}>{`${titleCase(process)} Quantity for ${
                    selectedItem.item_name
                } (${selectedItem.unit})`}</Title>
                <Space>
                    <InputNumber
                        size="large"
                        min={maxNumberAllowed * -1}
                        max={maxNumberAllowed}
                        defaultValue={3}
                        style={{
                            fontSize: 30,
                            padding: 6,
                            minWidth: 150,
                            textAlign: "center"
                        }}
                        onChange={(e) => handlesOnChange(e)}
                        value={numberValue}
                        onFocus={showOSK}
                    />

                    <Button
                        icon={<SaveOutlined style={{ fontSize: 18 }} />}
                        type="primary"
                        ghost
                        style={{
                            fontSize: 30,
                            height: 60,
                            width: 60
                        }}
                        onClick={handleOnSave}
                    />

                    <Button
                        icon={<CloseOutlined style={{ fontSize: 18 }} />}
                        style={{
                            fontSize: 30,
                            height: 60,
                            width: 60
                        }}
                        onClick={handleOnClose}
                    />
                </Space>
            </Space>
            <OSK
                visibility={oskVisibility}
                setVisibility={setOSKVisibility}
                form={setNumberValue}
                params={oskParams}
                isInputOnly
            />
        </div>
    );
};
export default FormQuantity;
