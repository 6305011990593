import { useQuery, useMutation } from "react-query";
import http from "services/httpService";

async function getDelivery({ queryKey }) {
  const { branch_id, inventory_cutoff } = queryKey[1];
  const { data } = await http.get(`/delivery/${branch_id}/${inventory_cutoff}`);
  return data;
}
function useGetDelivery(data) {
  return useQuery(["delivery", data], getDelivery);
}

// // ------------Mutation

async function storeDelivery(data) {
  const { data: storedDeliveryData } = await http.post(`/delivery/store`, data);
  return storedDeliveryData;
}
function useMutationStoreDelivery() {
  return useMutation(storeDelivery);
}

async function updateDelivery(data) {
  const { data: updateDeliveryData } = await http.put(`/delivery/update/${data.time_stamp}`, data);
  return updateDeliveryData;
}
function useMutationUpdateDelivery() {
  return useMutation(updateDelivery);
}

async function deleteDelivery(data) {
  const { data: deleteInventoryTransferData } = await http.delete(
    `/delivery/delete/${data.time_stamp}`
  );
  return deleteInventoryTransferData;
}
function useMutationDeleteDelivery() {
  return useMutation(deleteDelivery);
}

async function storeSupplier(data) {
  const { data: storedSupplierData } = await http.post(`/delivery/store-supplier`, data);
  return storedSupplierData;
}
function useMutationStoreSupplier() {
  return useMutation(storeSupplier);
}

export {
  useGetDelivery,
  useMutationStoreDelivery,
  useMutationUpdateDelivery,
  useMutationDeleteDelivery,
  useMutationStoreSupplier,
};
